import React from 'react';
import Button from "../../components/UI/Button/Button";
import {useTranslation} from "../i18n/i18n";

const LinkEsimInstallManual = () => {
  const {t} = useTranslation();
  return (
    <Button
      noMargins
      alignLeft
      variation="tert"
      icon="ExternalLink"
      fullWidth={false}
      href={t('esim_installing_manual_link_url')}
      target="_blank"
    >
      {t('esim_installing_manual_link_text')}
    </Button>
  );
};

export default LinkEsimInstallManual;
