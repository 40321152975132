import {useEffect, useReducer, useState} from 'react';
import axios from 'axios';
import {selectToken} from '../features/auth/authSlice';
import {selectCurrency} from "../features/userSettings/userSettingsSlice";
import {useSelector} from "react-redux";
import {useTranslation} from "../features/i18n/i18n";
import queryString from "query-string";
import {errorHandler} from "../features/errorHandling/errorHandler";

const dataFetchReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_START':
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        data: action.payload,
      };
    case 'FETCH_FAIL':
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload.message,
      };
    case 'FETCH_CANCELED':
      return {
        ...state,
        isLoading: false,
      };
    default:
      throw new Error();
  }
};

function useFetchData(initialConfig, cbs = {}) {
  const {i18n} = useTranslation();
  const authToken = useSelector(selectToken);
  const currency = useSelector(selectCurrency);
  const defaultConfig = {
    method: 'get',
    pollInterval: null,
    endpoint: '', // endpoint is only used if thre's no url
    url: null, // full url to fetch from
    withCredentials: false,
    useAuthToken: null,
    rerenderOnAuthTokenChange: true,
    useLanguage: true,
    useCurrency: true,
    enabled: false,
    keepPollingOnError: true, // whether we should keep polling after encountering a fetch error
    queryObject: {},
  }

  const [config, setConfig] = useState(Object.assign({}, defaultConfig, initialConfig));
  const [callbacks, setCallbacks] = useState(cbs);
  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: false,
    data: null,
    systemMessage: null,
  });

  // this function can be used to allow users to retry a failed fetch
  const tryAgain = () => {
    setConfig(prevState => ({...prevState, enabled: true}));
  }

  let timeoutId = null;
  const fetchConf = {
    method: config.method,
    url: config.url || config.endpoint,
    withCredentials: config.withCredentials,
    headers: {},
  }

  if (config.useAuthToken && authToken) {
    fetchConf.headers['Authorization'] = `Bearer ${authToken}`;
  }

  if (config.useCurrency && currency) {
    config.queryObject.currency = currency;
  }

  if (config.useLanguage) {
    fetchConf.headers['Accept-Language'] = i18n.language;
    config.queryObject.lang = i18n.language;
  }


  const fetchData = (params) => {
    const {effectState} = params;
    const query = queryString.stringify(config.queryObject);
    let axiosConf = {...fetchConf};
    if (query) {
      if (axiosConf.url.indexOf('?') !== -1) {
        axiosConf.url = axiosConf.url + '&' + query;
      } else {
        axiosConf.url = axiosConf.url + '/?' + query;
      }
    }
    if (config.formData) {
      axiosConf.data = config.formData;
    } else if (config.data) {
      axiosConf.data = config.data;
    }

    effectState.cancel = axios.CancelToken.source();
    axiosConf.cancelToken = effectState.cancel.token;

    callbacks.start && callbacks.start();
    dispatch({type: 'FETCH_START'});


    if (callbacks.modifyConfBeforeFetch) {
      axiosConf = callbacks.modifyConfBeforeFetch(axiosConf);
    }

    axios(axiosConf)
      .then((result) => {
        callbacks.success && callbacks.success({
          data: result.data,
        });
        dispatch({type: 'FETCH_SUCCESS', payload: result.data});
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          // do not try to update state when unmounted
          if (!effectState.unMounting) {
            dispatch({type: 'FETCH_CANCELED'});
          }
          return;
        }
        const {message} = errorHandler.handleApiError(error);

        if (!config.keepPollingOnError) {
          effectState.shouldPoll = false;
        }

        callbacks.fail && callbacks.fail(error, message);
        dispatch({
          type: 'FETCH_FAIL',
          payload: {
            message,
          }
        });
      })
      .then(() => {
        if (timeoutId) {
          window.clearTimeout(timeoutId);
          timeoutId = null;
        }
        if (config.pollInterval && effectState.shouldPoll && config.enabled) {
          timeoutId = window.setTimeout(() => {
            if (effectState.shouldPoll) {
              fetchData(params);
            }
          }, config.pollInterval);
        }
      });
  }

  const useEffectDependencies = [config, callbacks, i18n.language, currency];
  if (config.rerenderOnAuthTokenChange && config.useAuthToken) {
    useEffectDependencies.push(authToken);
  }

  useEffect(() => {
    /*
     we need to wrap didCancel in an object so that we can pass it around as a reference
     so, when useEffects cleanup runs and changes the didCancel in the effectState object to false,
     then the fetchData function will also know of the change because objects are passed as a reference
     I.e. both the fetchData and useEffects unmount cleanup function use and modify the same effectState object
     and therefore share state with each other.
     */
    const effectState = {
      cancel: null,
      shouldPoll: true,
      unMounting: false,
    }


    if (config.enabled) {
      fetchData({effectState});
    }

    return () => {
      if (effectState.cancel) {
        effectState.cancel.cancel();
      }
      effectState.unMounting = true;
      effectState.shouldPoll = false;
      callbacks.cancel && callbacks.cancel();
    };
    // https://stackoverflow.com/questions/55840294/how-to-fix-missing-dependency-warning-when-using-useeffect-react-hook
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, useEffectDependencies);


  return {
    state,
    config,
    setConfig,
    setCallbacks,
    tryAgain,
  };
}

export default useFetchData;
