import React from 'react';
import styled from 'styled-components/macro';
import Icon from "../../components/Icon/Icon";
import {resetButtonStyles} from "../../styles/mixins";
import {useSelector} from "react-redux";
import {selectItemsCount} from "./cartSlice";
import {useModalControls} from "../modal/ModalsController";

const CartButton = () => {
  const modalControls = useModalControls();
  const count = useSelector(selectItemsCount);
  let button;

  if (count) {
    button = (
      <Button
        onClick={() => modalControls.cart.show()}
      >
        <IconBox>
          <Icon name="Cart"/>
        </IconBox>
        <TextBox>{count}</TextBox>
      </Button>
    )
  }
  return (
    <Wrapper>
      {button}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: fixed;
  top: 0.625rem;
  right: 0.625rem;
  z-index: ${props => props.theme.zIndex.cartButton};
`

const Button = styled.button`
  ${resetButtonStyles};
  display: flex;
  justify-content: center;
  background: ${props => props.theme.cartButton.bg};
  color: ${props => props.theme.cartButton.color};
  padding: 0.75rem 0.875rem;
  font-size: 1.1875rem;
  font-weight: ${props => props.theme.fontWeight.bold};
  min-width: 5.25rem;
  border-radius: 3.75rem;
  cursor: pointer;
`

const IconBox = styled.div`
  pointer-events: none;
`

const TextBox = styled.div`
  padding-left: 0.3125rem;
`

export default CartButton;
