import React from 'react';
import styled from 'styled-components/macro';
import MainTitle from '../../components/MainTitle/MainTitle';
import {useTranslation} from '../i18n/i18n';
import GridContainer from "../../components/GridContainer/GridContainer";

const EsimQr = props => {
  const {modalData} = props;
  const {t} = useTranslation();

  return (
    <Wrapper>
      <GridContainer>
        <MainTitle>{t('title_esim_profile_qr')}</MainTitle>
      </GridContainer>

      <CenterContent>
        <GridContainer>
          <ImageWrap>
            <Image src={modalData.qrImageUrl}/>
          </ImageWrap>
        </GridContainer>
      </CenterContent>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
`

const Image = styled.img`
  display: block;
  width: 100%;
  height: auto;
`

const ImageWrap = styled.div`
  width: 100%;
  max-width: 14.375rem;
  margin: 0 auto;
`

const CenterContent = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
`

export default EsimQr;
