import config from 'react-global-configuration';

export const productTypes = config.get().productTypes;
export const productIds = config.get().productIds;

export const getProductTypeById = id => {
  const product = productIds.find(item => item.id === id);
  if (product) {
    return product.type;
  }
};

export const isVirtual = id => {
  const type = getProductTypeById(id);
  const virtualProducts = [productTypes.newEsim, productTypes.rechargeESim, productTypes.rechargeTravelsim];
  return virtualProducts.includes(type);
}
