import React from "react";
import styled from 'styled-components/macro';
import {useTranslation} from "react-i18next";
import MutedText from "../../components/MutedText/MutedText";
import Button from "../../components/UI/Button/Button";
import Meter from "../../components/UI/Meter/Meter";
import useRoutes from "../routing/useRoutes";
import DataSection from "./DataSection";
import config from "react-global-configuration";
import EsimQrButton from "../esimQr/EsimQrButton";

const StatsBlock = props => {
  const {t} = useTranslation();
  const {card, type} = props;
  const data_packages = card.data_packages || [];
  const routes = useRoutes();

  const dataSections = (data_packages.map((dataPackage, index) => {
    return (
      <DataSection key={index} {...{card, dataPackage}}/>
    )
  }))

  if (type === 'loadingMock') {
    return (
      <Stats>

        <StatsSection>
          <StatsRow alignItems="flex-end">
            <StatsRowItem type="first"/>
            <StatsRowItem>
              <MutedText>{t('card_stats.plan')}:</MutedText>
            </StatsRowItem>
          </StatsRow>

          <StatsRow alignItems="flex-start">
            <StatsRowItem type="first">
              <MutedText>{t('card_stats.data_remaining', {amount: ' '})}</MutedText>
            </StatsRowItem>
            <StatsRowItem>
              <StyledMeter value={0}/>
            </StatsRowItem>
          </StatsRow>
        </StatsSection>

        <StatsSection>
          <StatValue type="small">{card.credit} {card.currency}</StatValue>
          <MutedText>{t('card_stats.calls_remaining')}</MutedText>
        </StatsSection>
      </Stats>
    )
  }
  return (
    <Stats>

      {dataSections}

      <StatsSection>
        <StatValue type="small">{card.credit} {card.currency}</StatValue>
        <MutedText>{t('card_stats.calls_remaining')}</MutedText>
      </StatsSection>

      <ButtonHolder>
        <Button
          href="#"
          variation="sec"
          currency={card.currency}
          to={routes.routesByKey.RECHARGE.generateLinkTo({number: card.number})}
        >
          {t('card_stats.recharge_button')}
        </Button>

      </ButtonHolder>
      {card.type === config.get().simTypes.esim && (
        <InfoWrapper>
          <EsimQrButton qrImageUrl={card?.qr_code}/>
        </InfoWrapper>
      )}
    </Stats>
  )
}

const Stats = styled.div`
  margin-top: 0.875rem;
`

const StatsSection = styled.div`
  margin-top: 1.25rem;

  &:first-child {
    margin-top: 0;
  }
`

const StatsRow = styled.div`
  display: flex;
  align-items: ${props => props.alignItems};
  opacity: ${props => props.status === 'inactive' ? 0.5 : 1};
`

const StatsRowItem = styled.div`
  width: 9.0625rem;
  text-align: right;
  flex-shrink: 0;
    
  @media (min-width: 360px) {
    width: 10.6875rem;
    ${props => props.type === 'first' && `
      width: 100%;
    `};
  }
  
  ${props => props.type === 'first' && `
    width: 100%;
    padding-right: 0.625rem;
    text-align: left;
    flex-shrink: 1;
  `};
`

const StatValue = styled.div`
  font-weight: ${props => props.theme.fontWeight.bold};
  font-size: ${props => props.type === 'small' ? '1.1875rem' : '1.5625rem'};
  margin-bottom: ${props => props.type === 'small' ? '0.125rem ' : '0'};
  line-height: 1.1;
`

const StyledMeter = styled(Meter)`
  margin-top: 0.3125rem;
`

const ButtonHolder = styled.div`
  text-align: center;
`

const InfoWrapper = styled.div`
  margin-top: 5px;
`


export default StatsBlock;
