import React, {useContext} from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import GridContainer from '../GridContainer/GridContainer';
import Icon from '../Icon/Icon';
import {NavLink} from 'react-router-dom'
import RoutesContext from "../../features/routing/RoutesContext";

const NavBar = () => {
  const { t } = useTranslation();
  const routes = useContext(RoutesContext);

  return (
    <Wrapper>
      <NavContainer>
        <StyledNavLink
          exact
          to={routes.linkTo.MY_CARDS}
        >
          <LinkIcon name="TravelsimLogoLine" stroke="currentColor"/>
          <LinkText>{t("nav_bar.my_cards")}</LinkText>
        </StyledNavLink>

        <StyledNavLink
          exact
          to={routes.linkTo.ACCOUNT}
        >
          <LinkIcon name="Account"/>
          <LinkText>{t("nav_bar.account")}</LinkText>
        </StyledNavLink>
      </NavContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: ${props => props.theme.navBar.bg};
  font-size: ${props => props.theme.fontSize.textBigger};
  color: ${props => props.theme.navBar.color}
`

const NavContainer = styled(GridContainer)`
  display: flex;
  justify-content: space-between;
`

const StyledNavLink = styled(NavLink)`
  color: currentColor;
  display: block;
  position: relative;
  padding: 13px 0 13px 32px;
  font-weight: ${props => props.theme.fontWeight.semiBold};
  font-size: 1em;
  margin: 0 6px;
  border-top: 2px solid transparent;
  
  &.active {
    color: ${props => props.theme.navBar.activeColor};
    border-top-color: currentColor;
  }
`

const LinkIcon = styled(Icon)`
  display: block;
  position: absolute;
  left: 0;
  top: 12px;
  color: currentColor;
`

const LinkText = styled.div`

`

export default NavBar;
