import React, {useState, useEffect} from 'react';
import styled from 'styled-components/macro';
import PropTypes from "prop-types";
import OptionButton from "../UI/OptionButton/OptionButton";

const newMultiSelectValue = (option, value, selectedValues, isSelected) => {
  if (isSelected) {
    return selectedValues.filter(element => element.value !== value);
  } else {
    return [...selectedValues, option];
  }
}

const newSingleSelectValue = (option, selectedValues, isSelected) => {
  if (isSelected) {
    return [];
  } else {
    return [option];
  }
}

const OptionPicker = props => {
  const {options = [], onChange, value, isMulti, behaviour, paddingLeft} = props;
  const initialValue = Array.isArray(value) ? value : [];
  const [selectedValues, setSelectedValues] = useState(initialValue)

  useEffect(() => {
    setSelectedValues(props.value || []);
  }, [props.value])

  const optionButtons = options.map((option, index) => {
    const {name, value, label, subLabel} = option;
    const currentOptionIndex = selectedValues.findIndex(element => element.value === value);
    const isSelected = currentOptionIndex !== -1;

    return (
      <ButtonCol key={name + index}>
        <OptionButton
          {...{name, value}}
          id={name + index}
          checked={isSelected}
          subLabel={subLabel}
          wrap
          onChange={() => {
            if (isSelected && behaviour === 'radio') {
              return;
            }
            let newSelected;
            if (isMulti) {
              newSelected = newMultiSelectValue(option, value, selectedValues, isSelected);
            } else {
              newSelected = newSingleSelectValue(option, selectedValues, isSelected);
            }
            setSelectedValues(newSelected);
            if (onChange) {
              onChange(newSelected);
            }
          }}
        >
          {label}
        </OptionButton>
      </ButtonCol>
    )
  })
  return (
    <Wrapper>
      <ButtonsRow paddingLeft={paddingLeft}>
        {optionButtons}
      </ButtonsRow>
    </Wrapper>
  );
};

OptionPicker.propTypes = {
  behaviour: PropTypes.oneOf([
    'radio',
  ]),
  paddingLeft: PropTypes.oneOf([
    'normal',
    'none'
  ]),
};

const Wrapper = styled.div`
  // add negative margin so that more buttons can fit on one row
  margin-right: -16px;
`

const ButtonsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.3125rem;
  padding-left: 1.875rem;
  ${props => props.paddingLeft === 'normal' && `
    padding-left: 1.875rem;
  `}
  ${props => props.paddingLeft === 'none' && `
    padding-left: 0;
  `}
`

const ButtonCol = styled.div`
  padding: 0.3125rem;
  min-width: 112px;
`


export default OptionPicker;
