import React, {useState} from 'react';
import Collapse from "../Collapse/Collapse";

const Accordion = props => {
  const {children, startPosition = null} = props;
  const [openPosition, setOpenPosition] = useState(startPosition);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const handleTriggerClick = (position) => {
    if (isTransitioning) {
      return;
    }
    if (openPosition === position) {
      setOpenPosition(null);
    } else {
      setOpenPosition(position);
    }
  }

  const nodes = children.map((node, index) => {
    const thisPosition = index;
    return (
      <Collapse
        key={index}
        accordionPosition={thisPosition}
        header={node.props.header}
        open={openPosition === thisPosition}
        onOpening={() => setIsTransitioning(true)}
        onClosing={() => setIsTransitioning(true)}
        onOpen={() => setIsTransitioning(false)}
        onClose={() => setIsTransitioning(false)}
        {...{handleTriggerClick}}
      >
        {node}
      </Collapse>
    )
  });
  return (
    <div>
      {nodes}
    </div>
  );
};

export default Accordion;
