import React from 'react';
import styled from "styled-components/macro";

const MediaObject = props => {
  const {image, title, url} = props;

  return (
    <Wrapper>
      <div>
        <ImgWrap shouldShowPlaceholder={!image}>
          <Link href={url} target="_blank">
            <Img
              src={image}
              alt=""
            />
          </Link>
        </ImgWrap>
      </div>
      <TextCol>
        <Link href={url} target="_blank">
          <Title>{title}</Title>
        </Link>
      </TextCol>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const TextCol = styled.div`
  padding-left: 12px;
`

const ImgWrap = styled.div`
  position: relative;
  width: 66px;
  height: 66px;
  border-radius: ${props => props.theme.borderRadiusLg};
  overflow: hidden;
  background-color: ${props => props.shouldShowPlaceholder ? props.theme.color.neutral300 : 'transparent'};
`

const Img = styled.img`
  width: 100%;
  height: auto;
`

const Title = styled.div`
  font-weight: ${props => props.theme.fontWeight.semiBold};
  font-size: ${props => props.theme.fontSize.textBigger};
`

const Link = styled.a`
  color: inherit;
  display: block;
`

export default MediaObject;
