import {lazy} from "react";

//import TemplateLogIn from "./TemplateLogIn";
import TemplateLangCurrency from "./TemplateLangCurrency";
import TemplateMyCards from "./TemplateMyCards";
//import TemplateWelcome from "./TemplateWelcome";
//import TemplateGetHelp from "./TemplateGetHelp";
//import TemplateRatesAndCoverage from "./TemplateRatesAndCoverage";
//import TemplateSimConfigurator from "./TemplateSimConfigurator";
//import TemplateCheckout from "./TemplateCheckout";
//import TemplateCheckoutSuccess from "./TemplateCheckoutSuccess";
//import TemplateAboutEsim from "./TemplateAboutEsim";
//import TemplateAbout from "./TemplateAbout";
//import TemplateRecharge from "./TemplateRecharge";
//import TemplateAccount from "./TemplateAccount";
//import TemplateResetPassword from "./TemplateResetPassword";
//import TemplateForgotPassword from "./TemplateForgotPassword";
//import TemplateCreateAccount from "./TemplateCreateAccount";
//import TemplateContentBlocks from "./TemplateContentBlocks";
//import TemplateInstallEsim from "./TemplateInstallEsim";

const TemplateLogIn = lazy(() => import('./TemplateLogIn'));
const TemplateWelcome = lazy(() => import('./TemplateWelcome'));
const TemplateGetHelp = lazy(() => import('./TemplateGetHelp'));
const TemplateRatesAndCoverage = lazy(() => import('./TemplateRatesAndCoverage'));
const TemplateSimConfigurator = lazy(() => import('./TemplateSimConfigurator'));
const TemplateCheckout = lazy(() => import('./TemplateCheckout'));
const TemplateCheckoutSuccess = lazy(() => import('./TemplateCheckoutSuccess'));
const TemplateAboutEsim = lazy(() => import('./TemplateAboutEsim'));
const TemplateRecharge = lazy(() => import('./TemplateRecharge'));
const TemplateAccount = lazy(() => import('./TemplateAccount'));
const TemplateResetPassword = lazy(() => import('./TemplateResetPassword'));
const TemplateForgotPassword = lazy(() => import('./TemplateForgotPassword'));
const TemplateCreateAccount = lazy(() => import('./TemplateCreateAccount'));
const TemplateContentBlocks = lazy(() => import('./TemplateContentBlocks'));
const TemplateInstallEsim = lazy(() => import('./TemplateInstallEsim'));
const TemplateAbout = lazy(() => import('./TemplateAbout'));
/*
const TemplateMyCards = lazy(() => import('./TemplateMyCards'));
 */

const TemplateCallLog = lazy(() => import('./TemplateCallLog'));
const TemplatePurchaseHistory = lazy(() => import('./TemplatePurchaseHistory'));


export default {
  TemplateAccount: {
    Template: TemplateAccount,
  },
  TemplateAbout: {
    Template: TemplateAbout,
  },
  TemplateAboutEsim: {
    Template: TemplateAboutEsim,
  },
  TemplateCallLog: {
    Template: TemplateCallLog,
  },
  TemplateCheckout: {
    Template: TemplateCheckout,
  },
  TemplateCheckoutSuccess: {
    Template: TemplateCheckoutSuccess,
  },
  TemplateContentBlocks: {
    Template: TemplateContentBlocks,
  },
  TemplateCreateAccount: {
    Template: TemplateCreateAccount,
  },
  TemplateForgotPassword: {
    Template: TemplateForgotPassword,
  },
  TemplateGetHelp: {
    Template: TemplateGetHelp,
  },
  TemplateInstallEsim: {
    Template: TemplateInstallEsim,
  },
  TemplateLangCurrency: {
    Template: TemplateLangCurrency,
  },
  TemplateLogIn: {
    Template: TemplateLogIn,
  },
  TemplateMyCards: {
    Template: TemplateMyCards,
  },
  TemplatePurchaseHistory: {
    Template: TemplatePurchaseHistory,
  },
  TemplateRatesAndCoverage: {
    Template: TemplateRatesAndCoverage,
  },
  TemplateRecharge: {
    Template: TemplateRecharge,
  },
  TemplateResetPassword: {
    Template: TemplateResetPassword,
  },
  TemplateSimConfigurator: {
    Template: TemplateSimConfigurator,
  },
  TemplateWelcome: {
    Template: TemplateWelcome,
  },
}

