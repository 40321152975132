import useFetchData from "../../hooks/useFetchData";

// this component fetches my cards into our redux store
export default (settings = {}) => {
  return useFetchData({
    endpoint: settings.endpoint,
    url: settings.url,
    enabled: true,
    queryObject: {
      content_id: settings.contentId,
    }
  });
};
