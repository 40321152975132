export const validateCartItem = (item) => {
  const missingItemKeys = (object => {
    const requiredItemKeys = ['name', 'price', 'product_id'];
    let missingKeys;
    missingKeys = requiredItemKeys.reduce((acc, key) => {
      if (!object.hasOwnProperty(key)) {
        acc.push(key);
      }
      return acc;
    }, []);

    if (missingKeys && missingKeys.length) {
      return missingKeys.join(', ');
    }
  })

  let validationErrors = [];
  const {product_id, data_package, airtime, currency} = item;

  if (!product_id) {
    validationErrors.push('product_id is required');
  }

  if (!currency) {
    validationErrors.push('currency is required');
  }

  if (data_package) {
    if (!data_package.name) {
      validationErrors.push('data_package is missing the following property: "name"');
    }
    if (!data_package.item) {
      validationErrors.push('data_package is missing the following property: "item"');
    } else {
      let missingKeys = missingItemKeys(data_package.item);
      if (missingKeys) {
        validationErrors.push('data_package.item is missing the following properties: ' + missingKeys);
      }
    }
  }

  if (airtime) {
    let missingKeys = missingItemKeys(airtime);
    if (missingKeys) {
      validationErrors.push('airtime is missing the following properties: ' + missingKeys);
    }
  }

  if (validationErrors.length) {
    return {
      ...item,
      validationErrors,
    }
  } else {
    return item;
  }
}

export const assertValidCartItems = (items) => {
  let validationErrors;
  validationErrors = items
    .map(item => validateCartItem(item))
    .reduce((acc, item) => {
      if (item.validationErrors) {
        acc.push(item.validationErrors);
      }
      return acc;
    }, []);
  if (validationErrors && validationErrors.length) {
    console.error('assertValidCartItems: cart item validation failed with the following errors: ');
    validationErrors.forEach((item, index) => {
      console.error('- cart item ' + index + ':');
      item.forEach(errorMessage => {
        console.error('-- ' + errorMessage);
      })
    })
    console.log('Tried to add:');
    console.log(items);
    return false;
  } else {
    return true;
  }
}
