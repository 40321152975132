export default (numberString, settings = {}) => {
  const {countryCode} = settings;
  let formatted = numberString;
  if (!formatted) {
    return formatted;
  }

  formatted = String(formatted)
    .trim()
    .replace(/\+/g, '')
    .replace(/ +?/g, '');

  if (countryCode && formatted.indexOf(countryCode) !== 0) {
    formatted = countryCode + formatted;
  }

  formatted = formatted.split('').reduce((acc, char, index) => {
    let i = index;
    if (i === 1) {
      i = 2;
    }

    if (i === 0 || i % 3) {
      return acc + char;
    } else {
      return acc + ' ' + char;
    }
  }, '');


  if (countryCode) {
    formatted = '+' + formatted;
  }

  return formatted;
}
