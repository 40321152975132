import React, {useState} from 'react';
import ChoiceButton from "../UI/ChoiceButton/ChoiceButton";
import {useSelector} from 'react-redux';
import {selectLanguages} from '../../features/appConfig/appConfigSlice';
import {useTranslation} from '../../features/i18n/i18n';

const LangSelector = () => {
  const languages = useSelector(selectLanguages);
  const {i18n} = useTranslation();
  const [language, setLang] = useState(i18n.language);

  const langButtons = languages.map(lang => {
    const {code, name} = lang;
    return (
      <ChoiceButton
        key={code}
        name="language"
        value={code}
        checked={code === language}
        id={'lang_' + code}
        onChange={e => {
          setLang(e.target.value);
          i18n.changeLanguage(e.target.value);
        }}
      >
        {name}
      </ChoiceButton>
    )
  })


  return (
    <form>
      {langButtons}
    </form>
  );
};


export default LangSelector;
