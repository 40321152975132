import {createSlice} from '@reduxjs/toolkit';
import config from 'react-global-configuration';
import {clearCart} from "../cart/cartSlice";
import {resetConfig as resetSimConfig} from "../simConfigurator/simConfiguratorSlice";

export const userSettingsSlice = createSlice({
  name: 'userSettings',
  initialState: {
    currency: config.get('currencies').find(currency => currency.default).code,
  },
  reducers: {
    updateCurrency: (state, action) => {
      state.currency = action.payload.code;
    },
  },
});

export const {
  updateCurrency,
} = userSettingsSlice.actions;

export const setCurrency = (params) => dispatch => {
  dispatch(updateCurrency(params));
  dispatch(clearCart());
  dispatch(resetSimConfig());
};

export const selectCurrency = state => state.userSettings.currency;

export default userSettingsSlice.reducer;
