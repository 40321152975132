import React from 'react';

const OrderTitleContent = props => {
  const {order} = props;
  return (
    <>
      {order.items.map(orderItem => orderItem.name).join(', ')} - {order.total} {order.currency}
    </>
  );
};

export default OrderTitleContent;
