import useFetchData from "../../hooks/useFetchData";
import config from "react-global-configuration";
import {useDispatch} from "react-redux";
import {
  fetchStart,
  fetchSuccess,
  fetchFail,
  fetchCancel,
} from "./myCardsSlice";


// this component fetches my cards into our redux store
export default (settings = {}) => {
  const {pollInterval} = settings;
  const dispatch = useDispatch();
  return useFetchData({
    endpoint: config.get('apiEndpoints.userSimCards'),
    pollInterval: pollInterval,
    keepPollingOnError: false,
    useAuthToken: true,
    enabled: true,
  }, {
    start: () => {
      dispatch(fetchStart());
    },
    success: result => {
      dispatch(fetchSuccess(result));
    },
    fail: (error, message) => {
      dispatch(fetchFail({error: message}));
    },
    cancel: () => {
      dispatch(fetchCancel())
    }
  });
};
