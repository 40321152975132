import React, {useEffect, useState} from 'react';
import OlarkPlugin from 'react-olark-plugin';
import {useTranslation} from "react-i18next";
import {createGlobalStyle} from "styled-components/macro";
import {selectIsLoggedIn} from "../auth/authSlice";
import {useSelector} from "react-redux";

const Olark = () => {
  const {i18n} = useTranslation();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const [renderOlark, setRenderOlark] = useState(false);
  let olarkSystemConf = {
    hb_chatbox_size: 'md',
    is_single_page_application: true,
  };
  if (i18n.language === 'de') {
    olarkSystemConf.localization = 'de-DE';
    olarkSystemConf.group = 'db505ef3fd5823ee0f187b0fd5d8edd7'; /* Routes to TravelSim Support [de] */
  }

  // only load Olark after the user has interacted with the app
  useEffect(() => {
    const clickHandler = () => {
      setRenderOlark(true);
    }
    document.body.addEventListener('click', clickHandler, false);
    return () => document.body.removeEventListener('click', clickHandler, false);
  }, [renderOlark]);


  let content;
  if (renderOlark) {
    content = (
      <>
        <OlarkStyle {...{isLoggedIn}}/>
        <OlarkPlugin
          siteId={'5043-855-10-2993'}
          systemConfig={olarkSystemConf}
        />
      </>
    )
  }

  return (
    <>
      {content}
    </>
  );
};

const OlarkStyle = createGlobalStyle`
  .olark-launch-button-wrapper.olark-launch-button-wrapper,
  .olark-launch-button-wrapper.olark-launch-button-wrapper:focus-within {
    bottom: ${props => props.isLoggedIn ? '63px' : '15px'} !important;
  }
  
  #olark-container#olark-container {
    bottom: ${props => props.isLoggedIn ? '141px' : '94px'} !important;
  }

`

export default Olark;
