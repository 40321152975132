import useFetchData from "../../hooks/useFetchData";
import config from "react-global-configuration";
import {useDispatch} from "react-redux";
import {fetchCancel, fetchFail, fetchStart, fetchSuccess} from "./apiManifestSlice";
import {useEffect} from "react";

export default () => {
  const dispatch = useDispatch();

  // we should only poll when the user is actually interacting with the page
  // otherwise we're wasting data
  const {setConfig: setFetchConfig} = useFetchData({
    endpoint: config.get().apiEndpoints.apiManifest,
    pollInterval: config.get().apiManifestPollInterval,
    enabled: false,
  }, {
    start: () => {
      dispatch(fetchStart());
    },
    success: result => {
      dispatch(fetchSuccess(result));
    },
    fail: (error, message) => {
      dispatch(fetchFail({error: message}))
    },
    cancel: () => {
      dispatch(fetchCancel())
    }
  });

  useEffect(() => {
    let timeoutId = null;
    let enabled = false;
    const clearCurrentTimeout = () => {
      if (timeoutId) {
        window.clearTimeout(timeoutId);
        timeoutId = null;
      }
    }
    const clickHandler = () => {
      // we don't want to change the config unnecessarily
      // because each config change triggers the useffect and cancels the prev request
      // we also have to keep track of the enabled var manually inside this useeffect
      // because we're only running this useffect once and can't get the updated config
      // from the fetchConfig
      if (!enabled) {
        setFetchConfig(prevConfig => ({
          ...prevConfig,
          enabled: true,
        }));
        enabled = true;
      }

      clearCurrentTimeout();
      timeoutId = window.setTimeout(() => {
        setFetchConfig(prevConfig => ({
          ...prevConfig,
          enabled: false,
        }));
        enabled = false;
      }, config.get().apiManifestInteractionTimeout);
    }
    document.body.addEventListener('click', clickHandler, false);

    return () => {
      document.body.removeEventListener('click', clickHandler, false);
      clearCurrentTimeout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
};
