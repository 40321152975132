export const messageTypes = {
  info: 'info',
  danger: 'danger',
  warning: 'warning',
  success: 'success',
  error: 'error',
}

const messagesConf = {
  sm_password_reset_sent: {
    type: messageTypes.success,
    id: 'sm_password_reset_sent',
  },
  sm_password_reset_successful: {
    type: messageTypes.success,
    id: 'sm_password_reset_successful',
  },
  sm_default_error: {
    type: messageTypes.error,
    id: 'sm_default_error',
  },
  sm_network_error: {
    type: messageTypes.info,
    id: 'sm_network_error',
    icon: {
      name: 'CloudOffline',
    }
  },
  sm_not_found: {
    type: messageTypes.info,
    id: 'sm_not_found',
  },
  sm_settings_saved_success: {
    type: messageTypes.success,
    id: 'sm_settings_saved_success',
  },
  sm_please_choose_sim_type: {
    type: messageTypes.info,
    id: 'sm_please_choose_sim_type',
  },
  sm_please_choose_either_data_or_credit: {
    type: messageTypes.info,
    id: 'sm_please_choose_either_data_or_credit',
  },
  sm_please_choose_destination_country: {
    type: messageTypes.info,
    id: 'sm_please_choose_destination_country',
  },
  sm_could_not_load_the_last_recharge_info: {
    type: messageTypes.warning,
    id: 'sm_could_not_load_the_last_recharge_info',
  },
  sm_could_not_load_countries: {
    type: messageTypes.error,
    id: 'sm_could_not_load_countries',
  },
  sm_no_orders_found: {
    type: messageTypes.info,
    id: 'sm_no_orders_found',
  },
  sm_no_cards_found: {
    type: messageTypes.info,
    id: 'sm_no_cards_found',
  },
  sm_no_calls_found: {
    type: messageTypes.info,
    id: 'sm_no_calls_found',
  },
  sm_data_activation_success: {
    type: messageTypes.success,
    id: 'sm_data_activation_success',
  },
  sm_account_creation_success: {
    type: messageTypes.success,
    id: 'sm_account_creation_success',
  },
  sm_topup_cart_currency_mismatch: {
    type: messageTypes.info,
    id: 'sm_topup_cart_currency_mismatch',
  },
  sm_new_sim_cart_currency_mismatch: {
    type: messageTypes.info,
    id: 'sm_new_sim_cart_currency_mismatch',
  },
  account_exists_error: {
    type: messageTypes.error,
    id: 'account_exists_error',
  }
}

let byKey = {};
Object.keys(messagesConf).forEach(key => {
  byKey[key] = messagesConf[key].id;
});

export const messageIdsByKey = {
  ...byKey,
}

export default {
  ...messagesConf,
}


