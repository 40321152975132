import React from 'react';
import styled from 'styled-components/macro';
import Icon from '../../Icon/Icon';
import {Link} from 'react-router-dom'
import PropTypes from "prop-types";
import Spinner from "../Spinner/Spinner";

const Button = props => {
  const {type, href, icon, variation, size, to, noMargins, alignLeft, fullWidth, currency,...restProps} = props;

  let full;
  if (fullWidth === undefined) {
    full = true;
  }

  let inner = props.children;
  let iconContent;
  if (icon || currency) {
    iconContent = <Icon name={icon} currency={currency}/>;
    if (icon === 'Spinner') {
      iconContent = (
        <StyledSpinner/>
      )
    }
    inner = (
      <IconWrapper>
        <IconWrapperIcon>
          {iconContent}
        </IconWrapperIcon>
        <IconWrapperText>{props.children}</IconWrapperText>
      </IconWrapper>
    );
  }
  let as;
  if (to) {
    as = Link;
  } else if (href) {
    as = 'a';
  }
  return (
    <Wrapper
      {...{variation, type, size}}
      /*
        $ sign for
        transient props https://github.com/styled-components/styled-components/pull/2093
        to avoid https://reactjs.org/warnings/unknown-prop.html
       */
      $fullWidth={full}
      $noMargins={noMargins}
      $alignLeft={alignLeft}
      {...restProps}
      as={as}
      href={href}
      to={to || undefined}
    >
      {inner}
    </Wrapper>
  );
};

Button.defaultProps = {
  variation: 'prim',
};

Button.propTypes = {
  variation: PropTypes.oneOf(['prim', 'sec', 'tert', 'ghost']),
};

Button.propTypes = {
  size: PropTypes.oneOf(['sm']),
};

const Wrapper = styled.button`
  line-height: 1.2;
  font-size: ${props => props.theme.button.fontSize};
  font-weight: ${props => props.theme.button.fontWeight};
  display: inline-block;
  text-align: ${props => props.$alignLeft ? 'left' : 'center'};
  vertical-align: top;
  width: ${props => props.$fullWidth && '100%'};
  min-width: 6.875rem;
  max-width: 100%;
  padding: 1rem 0.875rem;
  border-radius: 3.125rem;
  margin-top: ${props => props.$noMargins ? '0' : '1.875rem'};
  margin-bottom: ${props => props.$noMargins ? '0' : '1.875rem'};
  transition: opacity ${props => props.theme.transitionBaseDuration},
    background-color ${props => props.theme.transitionBaseDuration},
    border-color ${props => props.theme.transitionBaseDuration};
  opacity: ${props => props.disabled ? '0.5' : '1'};
  -webkit-appearance: none;
  outline: none;
  border: 0;
  &:hover,
  &:focus {
    text-decoration: none;
  }
  
  &:active {
    transform: translateY(2px);  
  }
  
  &:not([disabled]) {
    cursor: pointer;
  }
  
  ${props => props.size === 'sm' && `
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  `}
  
  
  ${props => props.variation === 'prim' && `
    background: ${props.theme.button.primBg};
    color: ${props.theme.button.primColor};
    box-shadow: ${props.theme.button.primShadow};
    &:not([disabled]):hover,
    &:not([disabled]):focus {
      background: ${props.theme.button.primFocusBg};
    }
  `}
   ${props => props.variation === 'sec' && `
    background: ${props.theme.button.secBg};
    color: ${props.theme.button.secColor};
    border: 1px solid ${props.theme.button.secBorderColor};
    box-shadow: ${props.theme.button.secShadow};
    padding-top: calc(1rem - 1px);
    padding-bottom: calc(1rem - 1px);
    &:not([disabled]):hover,
    &:not([disabled]):focus {
      border-color: ${props.theme.button.secFocusBorderColor};
    }
  `}
   
   ${props => props.size === 'sm' && props.variation === 'sec' && `
      padding-top: calc(0.625rem - 1px);
      padding-bottom: calc(0.625rem - 1px);
   `}
   
   ${props => props.variation === 'tert' && `
      color: ${props.theme.button.tertColor};
      font-size: ${props.theme.button.tertFontSize};
      background: transparent;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0;
      padding-right: 0;
      &:not([disabled]):hover,
      &:not([disabled]):focus {
        color: ${props.theme.button.tertColorFocus};
      };
       &:active {
        transform: translateY(0);  
      };
  `}
   
   ${props => props.variation === 'ghost' && `
      color: inherit;
      background: transparent;
      border: 1px solid currentColor;
      box-shadow: none;
      padding-top: calc(1rem - 1px);
      padding-bottom: calc(1rem - 1px);
      font-weight: ${props.theme.fontWeight.normal};
      &:not([disabled]):hover,
      &:not([disabled]):focus {
        border-color: ${props.theme.button.secFocusBorderColor};
      }
      ${props.size === 'sm' && `
        padding-top: calc(0.625rem - 1px);
        padding-bottom: calc(0.625rem - 1px);
      `}
  `}
`

const IconWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
  padding-left: 24px;
  position: relative;
  
  .stroke {
    stroke: currentColor;
  }
`

const IconWrapperText = styled.div`
  padding-left: 0.3125rem;
`

const IconWrapperIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -12px;
`

const StyledSpinner = styled(Spinner)`
  width: ${props => props.theme.iconSize};
`



export default Button;
