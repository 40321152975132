import React from 'react';
import Button from "../../components/UI/Button/Button";
import useRoutes from "../routing/useRoutes";
import {useHistory} from "react-router-dom";
import {useTranslation} from "../i18n/i18n";
import {selectIsLoggedIn} from "../auth/authSlice";
import {useSelector} from "react-redux";

const LinkToLogin = () => {
  const routes = useRoutes()
  const history = useHistory();
  const {t} = useTranslation();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  let content;

  if (!isLoggedIn) {
    content = (
      <Button
        variation="tert"
        icon="ArrowRightCircle"
        onClick={() => {
          history.push(routes.linkTo.LOG_IN);
        }}

        noMargins="true"
      >
        {t('button.to_login_page')}
      </Button>
    );
  }
  return (
    <>
      {content}
    </>
  );
};

export default LinkToLogin;
