import React from 'react';
import ModalBase from 'react-overlays/Modal'
import styled from 'styled-components/macro';
import {Transition} from 'react-transition-group';
import {resetButtonStyles} from '../../styles/mixins';
import Icon from "../../components/Icon/Icon";

const DIALOG_ANIMATION_DURATION = 300;

const PopupTransition = ({children, ...props}) => {
  // https://github.com/reactjs/react-transition-group/blob/1fd4a65ac45edd2aea3dec18eeb8b9c07c7eb93f/CHANGELOG.md#features
  const nodeRef = React.useRef(null)
  return (
    <Transition {...props} nodeRef={nodeRef} timeout={DIALOG_ANIMATION_DURATION}>
      {(status, innerProps) => (
        React.cloneElement(children, {
          ...innerProps,
          status,
          ref: nodeRef,
        })
      )}
    </Transition>
  )
};


const Popup = props => {
  const {children, title, onCloseClick, onHide, show} = props;

  const renderBackdrop = (props) => <Backdrop {...props} />;
  const renderDialog = (props) => (
    <PopupDialog {...props}>
      <Header>
        <Title>
          {title}
        </Title>
      </Header>
      <CloseButton onClick={onCloseClick}>
        <Icon name="Close"/>
      </CloseButton>
      <div>
        {(
          /*
            inject the inpopup prop to all children so they know that they are inside a popup
            useful for when the component should behave differently when inside a popup
           */
          React.cloneElement(children, {
            inpopup: 'true',
          })
        )}
      </div>
    </PopupDialog>
  )
  return (
    <ModalBase
      show={show}
      onHide={onHide}
      transition={PopupTransition}
      backdropTransition={PopupTransition}
      renderBackdrop={renderBackdrop}
      renderDialog={renderDialog}
      aria-labelledby="modal-label"
    />
  );
};

const Backdrop = styled.div`
  position: fixed;
  z-index: ${props => props.theme.zIndex.modalBackdrop};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  transition: ${DIALOG_ANIMATION_DURATION}ms;
  opacity: ${({status}) => (status === 'entering' || status === 'entered' ? 0.5 : 0)}; 
`;

const PopupDialog = styled.div`
  position: fixed;
  padding: 1.5rem 2.125rem 1.375rem 34px;
  top: 3.75rem;
  left: 50%;
  max-width: 26.25rem;
  width: calc(100% - 1.875rem);
  max-height: calc(100vh - 6rem);
  
  
  z-index: ${props => props.theme.zIndex.popup};
  border-radius: ${props => props.theme.popup.borderRadius};
  background-color: ${props => props.theme.popup.bgColor};
  transition: ${DIALOG_ANIMATION_DURATION}ms;
  opacity: ${({status}) => (status === 'entering' || status === 'entered' ? 1 : 0)}; 
  transform: ${({status}) => (status === 'entering' || status === 'entered' ? 'translate(-50%, 0)' : 'translate(-50%, 100px)')};
  
  &:focus {
    outline: none;
  }
`;

const CloseButton = styled.button`
  ${resetButtonStyles};
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.625rem;
  color: ${props => props.theme.color.neutral400};
  cursor: pointer;
`

const Header = styled.div`
  padding-right: 1rem;
  min-height: 2.125rem;
  padding-bottom: 1.25rem;
`

const Title = styled.h3`
  margin: 0;
`

export default Popup;
