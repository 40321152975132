import {createSlice} from '@reduxjs/toolkit';
import {createSelector} from 'reselect'
import config from 'react-global-configuration';

export const pwaReloadSlice = createSlice({
  name: 'pwaReload',
  initialState: {
    dismissedAt: 0,
    snoozeTime: config.get().pwaUpdateSnoozeTime,
    showRequestedAt: 0,
  },
  reducers: {
    dismiss: state => {
      state.dismissedAt = Date.now();
    },
    accept: state => {
      state.dismissedAt = Date.now();
      state.showRequestedAt = 0;
    },
    maybeShow: (state, action) => {
      state.showRequestedAt = action.payload.timestamp;
    }
  },
});

export const {dismiss, accept, maybeShow} = pwaReloadSlice.actions;
export const selectShowRequestedAt = state => state.pwaReload.showRequestedAt;
export const selectSnoozeTime = state => state.pwaReload.snoozeTime;
export const selectDismissedAt = state => state.pwaReload.dismissedAt;


export const selectShouldShow = createSelector(
  [selectShowRequestedAt, selectDismissedAt, selectSnoozeTime],
  (requestedAt, dismissedAt, snoozeTime) => {
    let shouldShow = false;
    if (requestedAt > dismissedAt + snoozeTime) {
      shouldShow = true;
    }
    return shouldShow;
  }
);

export default pwaReloadSlice.reducer;
