import React from 'react';
import styled from 'styled-components/macro';
import Icon from "../../Icon/Icon";

const DescriptionItem = props => {
  const {term, description, icon, url, extra} = props;

  let desc = description;
  if (url) {
    desc = <a rel="noopener noreferrer" target="_blank" href={url}>{description}</a>
  }

  return (
    <Wrapper>
      {icon && (
        <IconWrap>
          <Icon name={icon}/>
        </IconWrap>
      )}
      <Term>{term}</Term>
      <Description>{desc}</Description>
      {extra && (
        <Extra>{extra}</Extra>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-left: 3rem;
  position: relative;
  color: ${props => props.theme.color.support};
  & + & {
    margin-top: 1.125rem;
  }
`

const IconWrap = styled.div`
  position: absolute;
  left: 0.5rem;
  top: 0.6875rem;
  color: currentColor;
`

const Term = styled.div`
  color: ${props => props.theme.textColorMuted};
`

const Description = styled.div`
  color: currentColor;
  font-size: ${props => props.theme.fontSize.textBigger};
  font-weight: ${props => props.theme.fontWeight.semiBold};
  
  a {
    color: currentColor;
    font-size: inherit;
    font-weight: inherit;
  }
`

const Extra = styled.div`
  color: currentColor;
  font-weight: ${props => props.theme.fontWeight.semiBold};
`

export default DescriptionItem;
