import React from 'react';
import styled from "styled-components/macro";
import {
  selectStatus as selectCountriesStatus,
  STATUS as COUNTRIES_STATUS
} from "../../../features/countries/countriesSlice";
import {
  selectStatus as selectRegionsStatus,
  STATUS as REGIONS_STATUS,
  selectCurrentRegion,
  selectRegions,
  setCurrentRegionName,
} from "../../../features/regions/regionsSlice";
import {useDispatch, useSelector} from "react-redux";
import LoadingOverlay from "../../LoadingOverlay/LoadingOverlay";
import CountryList from "../CountryList/CountryList";
import OptionPicker from "../../OptionPicker/OptionPicker";
import GridContainer from "../../GridContainer/GridContainer";

const CountriesByRegion = props => {
  const countriesStatus = useSelector(selectCountriesStatus);
  const regionsStatus = useSelector(selectRegionsStatus);
  const currentRegion = useSelector(selectCurrentRegion);
  const regions = useSelector(selectRegions);
  const dispatch = useDispatch();
  const {onClick} = props;

  let countryList;
  if (!regions.length && (countriesStatus === COUNTRIES_STATUS.pending || regionsStatus === REGIONS_STATUS.pending)) {
    countryList = <LoadingOverlay absolute/>
  } else if (currentRegion) {
    countryList = (<CountryList onClick={onClick} countries={currentRegion.countries}/>)
  }

  let regionOptions;
  if (regions && Array.isArray(regions)) {
    regionOptions = regions.map(region => (
      {
        name: 'region',
        label: region.name,
        value: region.name,
      }
    ));
  }

  const handleRegionChange = (values) => {
    dispatch(setCurrentRegionName({regionName: values[0].value}));
  }

  return (
    <Wrapper>
      <GridContainer>
        <OptionPicker
          options={regionOptions}
          behaviour="radio"
          value={[{value: currentRegion?.name}]}
          onChange={handleRegionChange}
          paddingLeft="none"
        />
      </GridContainer>
      <CountriesSection>
        <GridContainer>
          {countryList}
        </GridContainer>
      </CountriesSection>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  min-height: 12.5rem;
`

const CountriesSection = styled.div`
  padding-top: 1.25rem;
`

export default CountriesByRegion;
