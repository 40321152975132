import React from 'react';
import {useTranslation} from "../i18n/i18n";
import useFormatPrice from "../../hooks/useFormatPrice";

const RatesTable = props => {
  const {className, callIn, callOut, sms, data} = props;
  const {t} = useTranslation();
  const formattedPrice = useFormatPrice();
  return (
    <table {...{className}}>
      <tbody>
      <tr>
        <td>{t('rates.make_a_call')}</td>
        <td>{formattedPrice(callOut, t('rates.per_minute_asterisk'))}</td>
      </tr>
      <tr>
        <td>{t('rates.receive_a_call')}</td>
        <td>{formattedPrice(callIn, t('rates.per_minute'))}</td>
      </tr>
      <tr>
        <td>{t('rates.send_sms')}</td>
        <td>{formattedPrice(sms, t('rates.per_text'))}</td>
      </tr>
      <tr>
        <td>{t('rates.receive_sms')}</td>
        <td>{sms ? t('price_0') : formattedPrice(null)}</td>
      </tr>
      <tr>
        <td>{t('rates.data_per_mb')}</td>
        <td>{formattedPrice(data, t('rates.per_MB'))}</td>
      </tr>
      </tbody>
    </table>
  );
};

export default RatesTable;
