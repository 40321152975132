import {messageTypes} from "../systemMessages/systemMessagesConf";
import * as Sentry from '@sentry/browser';
import {store} from "../../app/store";
import {setTokenExpiry, logout} from "../auth/authSlice";
import formatErrorCode from "./formatErrorCode";
import getUnixTimestamp from "../../util/getUnixTimestamp";

class ErrorHandler {
  constructor(settings) {
    // error codes for api errors that should be logged to Sentry
    this.logExpectedApiErrorWhitelist = [];
    this.formatErrorCode = settings.formatErrorCode;
  }

  isExpectedApiError = (error) => {
    return !!error.response?.data;
  }

  shouldLogApiError = (errorCode) => {
    return this.logExpectedApiErrorWhitelist.indexOf(errorCode) !== -1;
  }

  errorSideEffects = (errorCode) => {
    if (errorCode === 'jwt_auth_invalid_token') {
      // this causes useTokenRefresher to refresh
      store.dispatch(setTokenExpiry({expiry: getUnixTimestamp()}));
    }

    // log out the user if refresh token has expired or is missing
    if (errorCode === 'jwt_auth_user_not_found' || errorCode === 'jwt_auth_refresh_token_not_found') {
      store.dispatch(logout());
    }
  }

  handleApiError = (error) => {
    const {status} = error;
    let code, text, details;
    if (this.isExpectedApiError(error)) {
      let {data} = error.response;
      code = data.code;
      text = data.user_message || data.message;
      details = data.user_messages;

      if (this.shouldLogApiError(code)) {
        Sentry.withScope((scope) => {
          scope.setTag('type', 'travelsim-api-error');
          scope.setTag('apiErrorCode', data.code);
          scope.setLevel(Sentry.Severity.Warning);
          Sentry.captureMessage(data.message);
        });
      }

      this.errorSideEffects(code);

    } else {
      code = this.formatErrorCode(error.message, status);
      Sentry.captureException(error);
    }

    return {
      message: {
        status,
        code,
        text,
        details,
        type: messageTypes.error,
      }
    }
  }
}

export const errorHandler = new ErrorHandler({
  formatErrorCode,
});

