import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from "prop-types";

const FancyTitle = props => {
  const {children, className, variation, htmlContent} = props;

  let renderContent;
  if (htmlContent) {
    renderContent = (
      <Wrapper {...{className, variation}} dangerouslySetInnerHTML={{__html: htmlContent}}/>
    );
  } else {
    renderContent = (
      <Wrapper {...{className, variation}}>
        {children}
      </Wrapper>
    );
  }
  return (
    <>
      {renderContent}
    </>
  );
};

const Wrapper = styled.div`
  font-size: 2.4375rem;
  font-weight: ${props => props.theme.fontWeight.bold};
  line-height: 1.1;
  background: ${props => props.theme.gradient.brandText};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  vertical-align: top;
  ${props => props.variation === 'sm' && `
    font-size: 1.625rem;
    line-height: 1.3;
  `}
`

FancyTitle.propTypes = {
  variation: PropTypes.oneOf([
    'sm',
  ]),
};

export default FancyTitle;
