import React from 'react';
import Button from "../../components/UI/Button/Button";
import {useTranslation} from "../i18n/i18n";
import useRoutes from "../routing/useRoutes";

const CheckoutButton = props => {
  const {t} = useTranslation();
  const {hideModal} = props;
  const routes = useRoutes();
  return (
    <Button
      onClick={() => {
        if (hideModal) {
          hideModal();
        }
      }}
      to={routes.linkTo.CHECKOUT}
    >{t('button.checkout')}</Button>
  );
};

export default CheckoutButton;
