import {useDispatch, useSelector} from "react-redux";
import {selectShouldReloadI18nResources, setI18nUpdated} from "../apiManifest/apiManifestSlice";
import {useEffect} from "react";
import config from "react-global-configuration";
import {useTranslation} from "../i18n/i18n";

export default () => {
  const shouldUpdate = useSelector(selectShouldReloadI18nResources);
  const dispatch = useDispatch();
  const {i18n} = useTranslation();
  useEffect(() => {
    if (shouldUpdate) {
      if (!window.localStorage) {
        return;
      }
      const storageKeys = config.get().languages.map(lang => {
        return config.get().i18nLocalStorageBackendPrefix + lang.code + '-common';
      });
      storageKeys.forEach(key => {
        window.localStorage.removeItem(key);
        i18n.reloadResources();
      })
      dispatch(setI18nUpdated());
    }
  },[i18n, dispatch, shouldUpdate])
}
