import React from 'react';
import Modal from "./Modal";
import {useSelector, useDispatch} from "react-redux";
import {selectActive, selectData, setActive, setData} from "./modalSlice";
import RatesAndCoverage from "../ratesAndCoverage/RatesAndCoverage";
import Cart from "../cart/Cart";
import EsimQr from "../esimQr/EsimQr";
import WhatsEsim from "../whatsEsim/WhatsEsim";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsAndConditions from "./TermsAndConditions";

const modalsConfig = [
  {
    id: 'ratesAndCoverage',
    component: RatesAndCoverage,
  },
  {
    id: 'cart',
    component: Cart,
  },
  {
    id: 'esimQr',
    component: EsimQr,
  },
  {
    id: 'whatsEsim',
    component: WhatsEsim,
  },
  {
    id: 'privacyPolicy',
    component: PrivacyPolicy,
  },
  {
    id: 'termsAndConditions',
    component: TermsAndConditions,
  }
];

export const useModalControls = () => {
  const dispatch = useDispatch();
  let controls = {};
  modalsConfig.forEach(modal => {
    controls[modal.id] = {
      show: (data = {}) => dispatch(setActive({modal: modal.id, data})),
    }
  });
  return controls;
}

const ModalsController = () => {
  const activeModal = useSelector(selectActive);
  const modalData = useSelector(selectData)
  const dispatch = useDispatch();
  const hideModal = () => dispatch(setActive({modal: null}));
  const setModalData = data => dispatch(setData({data}));

  const modals = modalsConfig.map(config => {
    const Component = config.component;
    return (
      <Modal
        key={config.id}
        onCloseClick={hideModal}
        show={activeModal === config.id}
        onHide={hideModal}
      >
        <Component {...{modalData, hideModal, setModalData}}/>
      </Modal>
    )
  })

  return (
    <>
      {modals}
    </>
  );
};

export default ModalsController;
