import React from 'react';
import styled from 'styled-components/macro';

const GridContainer = props => {
  return (
    <Wrapper {...props}>
      {props.children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-left: ${props => props.theme.gridContainer.paddingLeft};
  padding-right: ${props => props.theme.gridContainer.paddingRight};
  margin-left: auto;
  margin-right: auto;
  max-width: ${props => props.theme.gridContainer.maxWidth};
`

export default GridContainer;
