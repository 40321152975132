import React from 'react';
import Collapsible from 'react-collapsible';
import styled from 'styled-components/macro';

const TinyCollapse = props => {
  const {title, ...restProps} = props;
  return (
    <Wrapper>
      <Collapsible
        trigger={title}
        transitionTime={300}
        {...restProps}
      >
        {props.children}
      </Collapsible>
    </Wrapper>
  );
};

const Wrapper = styled.div`  
  .Collapsible__trigger {
    display: block;
    float: right;
    cursor: pointer;
    position: relative;
    padding-right: 1.5rem;
    
    &:after {
      content: "";
      display: block;
      position: absolute;
      right: 0.625rem;
      top: 50%;
      margin-top: -0.25rem;
      width: 0; 
      height: 0; 
      border-top: 0.25rem solid transparent;
      border-bottom: 0.25rem solid transparent; 
      border-right: 0.25rem solid currentColor; 
    }
    
    &.is-open:after {
      transform: rotateZ(-90deg);
    }
  }
  
  .Collapsible__contentOuter {
    clear: both;
  }
  
  .Collapsible__contentInner {
    text-align: justify;
    padding-top: ${props => props.theme.spacer};
  }
`

export default TinyCollapse;
