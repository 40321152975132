import React from 'react';
import styled, {keyframes} from 'styled-components/macro';
import Icon from "../../Icon/Icon";

const Spinner = props => {
  return (
    <SpinnerBox {...props}>
      <Icon name="Spinner" fullWidth/>
    </SpinnerBox>
  );
};

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const SpinnerBox = styled.div`
  font-size: 1rem;
  width: ${props => props.standalone ? '5rem' : '100%'};
  animation: ${spin} 0.8s linear infinite;
  ${props => props.standalone && `
    margin: 0 auto;
  `};
`

export default Spinner;
