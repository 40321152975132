export default [
  {
    "number": "37254348700",
    "airtime": {"product_id": 1261, "name": "10 USD", "price": 10, "display_price": "10 USD"},
    "product_id": 11307,
    "currency": "EUR",
  }, {
    "number": "37254348700",
    "airtime": {"product_id": 1261, "name": "10 USD", "price": 10, "display_price": "10 USD"},
    "product_id": 11307,
    "currency": "EUR",
  }, {
    "product_id": 1226,
    "airtime": {"name": "50 USD", "price": 50.12, "product_id": 1263},
    "data_package": {"name": "Zone A", "item": {"name": "1 GB", "price": 19, "product_id": 1264}},
    "number": "37254348708",
    "simType": "travelsim",
    "currency": "EUR",
  }, {
    "product_id": 1226,
    "airtime": {"name": "25 USD", "price": 25.99, "product_id": 17898},
    "data_package": {"name": "Zone A", "item": {"name": "1 GB", "price": 19, "product_id": 1264}},
    "simType": "travelsim",
    "currency": "EUR",
  }, {
    "product_id": 146351,
    "airtime": {"name": "50 USD", "price": 50, "product_id": 1263},
    "data_package": {"name": "Zone A", "item": {"name": "1 GB", "price": 19, "product_id": 1264}},
    "simType": "esim",
    "currency": "EUR",
  }, {
    "number": "37254348465",
    "data_package": {
      "name": "Zone A - 1GB",
      "item": {"product_id": 1264, "name": "1 GB", "price": 19, "display_price": "19 USD", "validity": 30}
    },
    "airtime": {"product_id": 1263, "name": "50 USD", "price": 50, "display_price": "50 USD"},
    "product_id": 11307,
    "currency": "EUR",
  }
];

