import {createSlice} from '@reduxjs/toolkit';
import config from "react-global-configuration";

export const STATUS = {
  ...config.get().fetchStatus,
}

export const purchaseHistorySlice = createSlice({
  name: 'purchaseHistory',
  initialState: {
    items: null,
    totalItems: 0,
    currentPage: 0,
    totalPages: 0,
    status: STATUS.idle,
    error: null,
  },
  reducers: {
    fetchStart: state => {
      state.status = STATUS.pending;
      state.error = null;
    },
    fetchSuccess: (state, action) => {
      const {data} = action.payload;
      state.status = STATUS.idle;
      state.items = data.data;
      state.totalItems = data.total;
      state.currentPage = data.current_page;
      state.totalPages = data.total_pages;
    },
    fetchFail: (state, action) => {
      state.status = STATUS.idle;
      state.error = action.payload.error;
    },
    fetchCancel: state => {
      state.status = STATUS.cancelled;
    }
  },
});

export const {
  fetchStart,
  fetchSuccess,
  fetchFail,
  fetchCancel,
} = purchaseHistorySlice.actions;

export const selectStatus = state => state.purchaseHistory.status;
export const selectItems = state => state.purchaseHistory.items;
export const selectError = state => state.purchaseHistory.error;
export const selectCurrentPage = state => state.purchaseHistory.currentPage;
export const selectTotalPages = state => state.purchaseHistory.totalPages;
export const selectTotalItems = state => state.purchaseHistory.totalItems;


export default purchaseHistorySlice.reducer;

