import React from 'react';
import {useTranslation} from "../i18n/i18n";
import styled from 'styled-components/macro';
import Button from "../../components/UI/Button/Button";
import useFormatPrice, {INPUT_FORMAT, OUTPUT_FORMAT} from "../../hooks/useFormatPrice";

const OrderPriceBreakDown = props => {
  const {order} = props;
  const {t} = useTranslation();
  const formatPrice = useFormatPrice({
    inputFormat: INPUT_FORMAT.float,
    outputFormat: OUTPUT_FORMAT.currencyCode,
    currency: order.currency,
  });

  const orderItems = order.items.map((orderItem, index) => {
    const {total, name} = orderItem;
    let price;
    if (total !== undefined) {
      price = formatPrice(total);
    }

    return (
      <tr key={index}>
        <td>
          {name}
        </td>
        <td>
          {price}
        </td>
      </tr>
    )
  });
  return (
    <>
      <Table>
        <tbody>
        {orderItems}
        {!!order.tax && (
          <tr>
            <td>{t('vat')}</td>
            <td>{formatPrice(order.tax)}</td>
          </tr>
        )}
        <TotalRow>
          <td>{t('total')}</td>
          <td>{formatPrice(order.total)}</td>
        </TotalRow>
        </tbody>
      </Table>
      <Button
        href={order.invoice_url}
        target="_blank"
        variation="sec"
        icon="Download"
      >
        {t('button.download_invoice')}
      </Button>
    </>
  );
};

const Table = styled.table`
  width: 100%;
  border: 0;
  border-collapse: collapse;
  
  td {
    padding: 0.25rem 0;
  }
 
  td:last-child {
    text-align: right;
    font-weight: ${props => props.theme.fontWeight.semiBold};
  }
`

const TotalRow = styled.tr`
  td {
    color: ${props => props.theme.color.support};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    text-transform: uppercase;
  }
`

export default OrderPriceBreakDown;
