import {createSlice} from '@reduxjs/toolkit';
import {createSelector} from 'reselect'
import cartTestItems from "./cartTestItems";
import {assertValidCartItems} from "./cartValidation";
import {nanoid} from 'nanoid';

export {assertValidCartItems};

export const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    items: [],
    // Keep track of all the orders we've already cleared the cart for.
    // This is to avoid accidentally clearing the cart when user navigates to some older checkout success page
    cartClearedForOrders: [],
  },
  reducers: {
    addToCart: (state, action) => {
      const {items} = action.payload;
      const existingCartItems = state.items;
      const cartItemsAreValid = assertValidCartItems(items);
      let currencyMatches = true;

      if (Array.isArray(existingCartItems) && existingCartItems.length) {
        items.forEach(item => {
          if (existingCartItems[0].currency !== item.currency) {
            currencyMatches = false;
          }
        });
      }

      if (!currencyMatches) {
        console.error('cartSlice: cart already contains items in a different currency');
      } else if (cartItemsAreValid) {
        state.items.push(...items.map(item => {
          return {...item, uid: nanoid()};
        }));
      }
    },
    removeItem: (state, action) => {
      const {index} = action.payload;
      if (index !== undefined) {
        state.items.splice(index, 1);
      }
    },
    clearCart: (state, action) => {
      let orderKey = action.payload?.orderKey;
      if (orderKey && state.cartClearedForOrders.indexOf(orderKey) === -1) {
        state.cartClearedForOrders.push(orderKey);
        state.items = [];
      } else {
        state.items = [];
      }
    },
  },
});

export const {addToCart, clearCart, removeItem} = cartSlice.actions;

export const addTestItemsToCart = () => dispatch => {
  dispatch(addToCart({items: cartTestItems}));
}

export const selectItems = state => state.cart.items;
export const selectItemsCount = state => state.cart.items.length;
export const selectCartCurrency = state => {
  let cartCurrency = null;
  const {items} = state.cart;
  if (items.length) {
    cartCurrency = items[0].currency;
  }
  return cartCurrency;
};

export const selectCheckoutItems = createSelector(
  [selectItems],
  (items) => {
    return items.map(item => {
      const itemObject = {
        product_id: item.product_id,
      };
      if (item.airtime?.product_id) {
        itemObject.airtime = item.airtime.product_id;
      }
      if (item.data_package?.item?.product_id) {
        itemObject.data_package = item.data_package.item.product_id;
      }
      if (item.number) {
        itemObject.number = parseInt(item.number);
      }

      return itemObject;
    });
  }
);

export default cartSlice.reducer;
