import React from 'react';
import styled from 'styled-components/macro';
import Spinner from "../UI/Spinner/Spinner";
import PropTypes from "prop-types";

const LoadingOverlay = props => {
  const {className, absolute, variation} = props;
  return (
    <Wrapper {...{className}} absolute={absolute} variation={variation}>
      <BackDrop variation={variation}/>
      <SpinnerWrap>
        <Spinner/>
      </SpinnerWrap>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  font-size: 1rem;
  position: ${props => props.absolute ? 'absolute' : 'fixed'};
  z-index: ${props => props.theme.zIndex.loadingOverlay};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const SpinnerWrap = styled.div`
  width: 6em;
  position: absolute;
  margin-top: -3em;
  margin-left: -3em;
  top: 50%;
  left: 50%;
`

const BackDrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.color.neutral0};
  opacity: 0.4;
  ${props => props.variation === 'solidBody' && `
    background-color: ${props.theme.body.bg};
    opacity: 1;
  `}
`

LoadingOverlay.propTypes = {
  variation: PropTypes.oneOf([
    'solidBody',
  ]),
};

export default LoadingOverlay;
