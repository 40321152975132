import React from 'react';
import MediaObject from "../../components/MediaObject/MediaObject";
import hash from "../../util/hash";
import BorderedList from "../../components/BorderedList/BorderedList";
import styled from "styled-components/macro";

const Articles = props => {
  const {data} = props;
  let articleItems;
  if (data?.articles && Array.isArray(data.articles)) {
    articleItems = data.articles.map(article => <MediaObject key={hash(article.title + article.url)} {...article}/>);
  }

  let title;
  if (data?.title) {
    title = <Title>{data.title}</Title>;
  }

  return (
    <div>
      {title}
      <BorderedList verticalPaddings>
        {articleItems}
      </BorderedList>
    </div>
  );
};

const Title = styled.div`
  font-weight: ${props => props.theme.fontWeight.semiBold};
  font-size: ${props => props.theme.fontSize.textBigger};
  margin-bottom: 0.125rem;  
`

export default Articles;
