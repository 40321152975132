import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import config from 'react-global-configuration';

import Backend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';


i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    debug: config.get('i18n.debug'),
    ns: ['common'],
    defaultNS: 'common',
    whitelist: config.get('languages').map(lang => lang.code),
    react: {
      useSuspense: true,
    },
    backend: {
      backends: [
        LocalStorageBackend,
        HttpBackend,
      ],
      backendOptions: [
        {
          prefix: config.get().i18nLocalStorageBackendPrefix, // we can use this prefix to manually clear the local storage
          expirationTime: 7 * 24 * 60 * 60 * 1000,
          defaultVersion: config.get().appVersion,
        },
        {
          // HttpBackend options
          crossDomain: true,
          loadPath: `${config.get('apiEndpointsWithUrl.locales')}/?lang={{lng}}`,
          requestOptions: { // used for fetch, can also be a function (payload) => ({ method: 'GET' })
            cache: 'reload',
          }
        }
      ]
    },
    detection: {
      order: ['localStorage', 'querystring', 'path', 'cookie', 'navigator', 'htmlTag', 'subdomain'],
    }
  });

export default i18n;





