import {useHistory, useLocation} from "react-router-dom";
import {useLastLocation} from "react-router-last-location";
import useRoutes from "./useRoutes";
import {useSelector, useDispatch} from "react-redux";
import {
  setStatus,
  selectStatus,
  STATUS as ONBOARDING_STATUS,
} from "../onboarding/onboardingSlice";

export default (params = {}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const lastLocation = useLastLocation();
  const routes = useRoutes();
  const status = useSelector(selectStatus);
  const {fallback} = params;

  let back;

  if (status === ONBOARDING_STATUS.login) {
    back = () => {
      history.push(routes.linkTo.LANG_AND_CURRENCY);
      dispatch(setStatus({status: ONBOARDING_STATUS.start}));
    };
  } else if (location.state && location.state.backPath) {
    back = () => {
      history.push(location.state.backPath)
    };
  } else if (lastLocation) {
    back = () => history.goBack();
  } else if (fallback) {
    if (typeof fallback === 'function') {
      back = () => fallback();
    } else {
      back = () => history.push(fallback);
    }
  }

  return back;
}
