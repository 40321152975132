import React, {useEffect, useState} from 'react';
import styled from 'styled-components/macro';
import GridContainer from "../../components/GridContainer/GridContainer";
import LangSelector from "../../components/LangSelector/LangSelector";
import CurrencySelector from "../../components/CurrencySelector/CurrencySelector";
import MainTitle from "../../components/MainTitle/MainTitle";
import {useTranslation} from "../i18n/i18n";
import {Swiper, Pagination, Controller} from 'swiper/js/swiper.esm';
import ReactIdSwiperCustom from 'react-id-swiper/lib/ReactIdSwiper.custom';
import Button from "../../components/UI/Button/Button";
import {useSelector, useDispatch} from "react-redux";
import {
  setStatus,
  selectStatus,
  STATUS as ONBOARDING_STATUS,
} from "../onboarding/onboardingSlice";
import {withRouter} from 'react-router-dom'
import Layout from "./Layout";
import useRoutes from "../routing/useRoutes";

const TemplateLangCurrency = props => {
  const {t} = useTranslation();
  const {pageData, history} = props;
  const {contentTranslationKey} = pageData;
  const [swiper, setSwiper] = useState(null);
  const [activeSlide, setActiveSlide] = useState(null);
  const onboardingStatus = useSelector(selectStatus);
  const dispatch = useDispatch();
  const routes = useRoutes();

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };

  useEffect(() => {
    if (swiper !== null) {
      swiper.on('slideChange', function () {
        setActiveSlide(swiper.realIndex);
      })
    }
  }, [swiper]);

  const swiperParams = {
    Swiper,
    modules: [Pagination, Controller],
    getSwiper: setSwiper,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    }
  };

  let finalButtonText = t('button.save');
  let finalButtonOnClick = () => {
    history.push(routes.linkTo.ACCOUNT);
  };
  if (onboardingStatus !== ONBOARDING_STATUS.completed) {
    finalButtonText = t('button.continue');
    finalButtonOnClick = () => {
      dispatch(setStatus({status:ONBOARDING_STATUS.login}));
      history.push(routes.linkTo.ACCOUNT);
    };
  }


  let hideBackButton = true;
  let back;
  if (onboardingStatus === ONBOARDING_STATUS.completed) {
    hideBackButton = false;
    back = routes.linkTo.ACCOUNT;
  }

  return (
    <Layout {...props} {...{hideBackButton, back}}>
      <Wrapper>
        <GridContainer>
          <MainTitle>{pageData.title}</MainTitle>
        </GridContainer>

        <ReactIdSwiperCustom {...swiperParams}>
          <div>
            <GridContainer>
              <SubTitle>{t(contentTranslationKey('sub_title_lang'))}</SubTitle>
              <LangSelector/>
            </GridContainer>
          </div>

          <div>
            <GridContainer>
              <SubTitle>{t(contentTranslationKey('sub_title_currency'))}</SubTitle>
              <CurrencySelector/>
            </GridContainer>
          </div>
        </ReactIdSwiperCustom>

        <GridContainer>
          {activeSlide !== 1 && (
            <Button onClick={goNext}>{t('button.next')}</Button>
          )}

          {activeSlide === 1 && (
            <Button onClick={finalButtonOnClick}>
              {finalButtonText}
            </Button>
          )}
        </GridContainer>

      </Wrapper>
    </Layout>
  );
};

const Wrapper = styled.div`
  position: relative;
  
  .swiper-container {
    padding-bottom: 3rem;
  }
  
  .swiper-pagination-bullet {
    width: ${props => props.theme.paginationBullet.width};
    height: ${props => props.theme.paginationBullet.height};
    background-color: ${props => props.theme.paginationBullet.bg};
    opacity: 1;
  }
  
  .swiper-pagination-bullet-active {
    background-color: ${props => props.theme.paginationBullet.bgActive};
  }
  
  .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin-left: ${props => props.theme.paginationBullet.spacing};
    margin-right: ${props => props.theme.paginationBullet.spacing};
  }
  
  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 0;
  }
  
`

const SubTitle = styled.h2`
  font-size: 1.125rem;
  font-weight: ${props => props.theme.fontWeight.normal};
  color: ${props => props.theme.color.neutral500};
  margin-bottom: 1.25rem;
`

export default withRouter(TemplateLangCurrency);
