import React from 'react';
import styled from 'styled-components/macro';
import {useTranslation} from '../i18n/i18n';
import NavBar from "../../components/NavBar/NavBar";
import Logo from "../../components/Logo/Logo";
import GridContainer from "../../components/GridContainer/GridContainer";
import {selectToken} from '../auth/authSlice';
import {useSelector} from "react-redux";
import ControlLink from "../../components/UI/ControlLink/ControlLink";
import PropTypes from "prop-types";
import {useHistory} from "react-router-dom";
import useBackNav from "../routing/useBackNav";
import CartButton from "../cart/CartButton";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import CountriesFetchError from "../countries/CountriesFetchError";

const Layout = props => {
  let {showLogo, back, hideBackButton, hideHeader, backFallback} = props;
  const {t} = useTranslation();
  const token = useSelector(selectToken);
  const history = useHistory();

  let backButtonOnClick = useBackNav({
    fallback: backFallback,
  });
  if (back) {
    if (typeof back === 'function') {
      backButtonOnClick = () => back();
    } else {
      backButtonOnClick = () => history.push(back)
    }
  }

  let backButton;
  if (backButtonOnClick && !hideBackButton) {
    backButton = (
      <ControlLink
        type="caretLeft"
        onClick={e => {
          e.preventDefault();
          backButtonOnClick();
        }}
      >
        {t('button.back')}
      </ControlLink>
    );
  }

  let header;
  if (!hideHeader) {
    header = (
      <Header>
        <GridContainer>
          <TopNav>
            {backButton}
          </TopNav>
        </GridContainer>
      </Header>
    )
  }

  return (
    <Wrapper>
      {header}

      {showLogo && (
        <GridContainer>
          <LogoBox>
            <LogoWrap>
              <Logo/>
            </LogoWrap>
            <Slogan>{t('brand_slogan')}</Slogan>
          </LogoBox>
        </GridContainer>
      )}

      <CartButton/>

      <CountriesFetchError/>

      <ErrorBoundary>
        {props.children}
      </ErrorBoundary>

      {token && (
        <NavBarHolder>
          <NavBar/>
        </NavBarHolder>
      )}

    </Wrapper>
  );
};
Layout.propTypes = {
  showLogo: PropTypes.bool,
  back: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
};


const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 6.25rem; // leave some space for the navbar
`

const TopNav = styled.div`
  position: relative;
  z-index: ${props => props.theme.zIndex.topNav};
  padding-top: 0.6875rem;
`

const NavBarHolder = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: ${props => props.theme.zIndex.navbar};
`

const Header = styled.header`
  min-height: 3.1875rem;
`

const LogoBox = styled.div`
  position: relative;
  margin-top: 1.9375rem;
`

const LogoWrap = styled.div`
  width: 4.75rem;
`

const Slogan = styled.div`
  color: ${props => props.theme.color.primary};
  white-space: nowrap;
  margin-top: 0.25rem;
`


export default Layout;

