import React from 'react';
import styled from 'styled-components/macro';
import {useTranslation} from '../../features/i18n/i18n';

const VatNote = () => {
  const {t} = useTranslation();
  return (
    <Wrapper>
      {t('vat_note_for_eu')}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  color: ${({theme}) => theme.textColorMuted};
`


export default VatNote;
