import React from 'react';
import Card from "../../components/UI/Card/Card";
import styled from 'styled-components/macro';
import {useTranslation} from "../i18n/i18n";
import useFormatPrice, {INPUT_FORMAT, OUTPUT_FORMAT} from "../../hooks/useFormatPrice";
import FancyTitle from "../../components/UI/FancyTitle/FancyTitle";
import CartItemTitleContent from "./CartItemTitleContent";
import Icon from "../../components/Icon/Icon";
import {resetButtonStyles} from "../../styles/mixins";
import {isVirtual} from "../../util/productType";

const calcSubTotal = (items) => {
  if (!items || !items.length) {
    return 0;
  }
  return items.reduce((acc, item) => (acc + item.price), 0)
}

const CartItem = props => {
  const {t} = useTranslation();
  const formatPrice = useFormatPrice({
    outputFormat: OUTPUT_FORMAT.currencyCode,
    inputFormat: INPUT_FORMAT.float,
  });
  const {
    footerButton,
    className,
    dataPackage,
    airtime,
    productId,
    phoneNumber,
    onRemove,
    simPrice,
    currency,
  } = props;

  let rows = [];
  let dataItem;
  if (dataPackage && dataPackage.item) {
    dataItem = dataPackage.item;
  }

  if (simPrice !== undefined) {
    rows.push({
      price: simPrice,
      itemType: 'simCard',
    })
  }
  if (dataItem) {
    rows.push({
      ...dataItem,
      itemType: 'dataPackage',
    })
  }
  if (airtime) {
    rows.push({
      ...airtime,
      itemType: 'airtime',
    });
  }

  const handleRemoveClick = () => {
    if (onRemove) {
      onRemove();
    }
  };

  const subTotal = calcSubTotal(rows);
  const isVirtualProduct = isVirtual(productId);
  return (
    <StyledCard {...{className}}>
      <Header>
        <CartItemTitleContent
          {...{productId, phoneNumber}}
          render={(titleContent) => {
            if (titleContent) {
              return <ItemTitle variation="sm">{titleContent}</ItemTitle>
            }
          }}
        />
        {onRemove && (
          <RemoveButtonWrap>
            <RemoveButton
              onClick={handleRemoveClick}
            >
              <Icon name="Close"/>
            </RemoveButton>
          </RemoveButtonWrap>
        )}
      </Header>

      {dataPackage && dataPackage.name && (
        <PlanTitle>{t('order.plan')} {dataPackage.name}</PlanTitle>
      )}


      <Table>
        <tbody>
        {simPrice !== undefined && (
          <tr>
            <td>{t('order.sim_card')}</td>
            <td>{formatPrice(simPrice, null, {currency})}</td>
          </tr>
        )}
        {dataItem && (
          <tr>
            <td>{t('order.data_plan')}</td>
            <td>{dataItem.name + ' - ' + formatPrice(dataItem.price, null, {currency})}</td>
          </tr>
        )}
        {airtime && (
          <tr>
            <td>{t('order.calls_and_texts')}</td>
            <td>{formatPrice(airtime.price, null, {currency})}</td>
          </tr>
        )}
        {!isVirtualProduct && (
          <tr>
            <td>{t('order.shipping')}</td>
            <td>{t('order.calculated_at_checkout')}</td>
          </tr>
        )}
        <SubtotalRow>
          <td>{t('order.subtotal')}</td>
          <td>{formatPrice(subTotal, null, {currency})}</td>
        </SubtotalRow>
        </tbody>
      </Table>
      {footerButton && (
        <FooterButtonWrap>
          {footerButton}
        </FooterButtonWrap>
      )}
    </StyledCard>
  );
};

const Header = styled.div`
  display: flex;
`

const RemoveButtonWrap = styled.div`
  margin-left: auto;
  margin-right: 0;
`

const RemoveButton = styled.button`
  ${resetButtonStyles};
  cursor: pointer;
  color: ${props => props.theme.color.danger};
  padding: 0.3125rem;
  margin-right: -0.5625rem;
  margin-top: -0.25rem;
`

const PlanTitle = styled.div`
  font-size: ${props => props.theme.fontSize.h3};
  font-weight: ${props => props.theme.fontWeight.bold};
`

const FooterButtonWrap = styled.div`
  margin: 1.5rem 0 1.1875rem 0;
`

const Table = styled.table`
  margin-top: 0.625rem;
  width: 100%;
  border-collapse: collapse;
  
  td {
    padding: 0.25rem 0;
  }
  
  td:last-child {
    font-size: ${props => props.theme.fontSize.textBigger};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    text-align: right;
  }
`

const SubtotalRow = styled.tr`
  font-weight: ${props => props.theme.fontWeight.semiBold};
  
  td:first-child {
    text-transform: uppercase;
  }
`

const ItemTitle = styled(FancyTitle)`
  position: relative;
  margin-bottom: 14px;
  margin-top: 5px;
`

const StyledCard = styled(Card)`
  padding-bottom: 0.875rem;
`

export default CartItem;
