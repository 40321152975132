import React from 'react';
import useExternalContent from "../externalContent/useExternalContent";
import config from "react-global-configuration";
import GridContainer from "../../components/GridContainer/GridContainer";
import SystemMessage from "../systemMessages/SystemMessage";
import Spinner from "../../components/UI/Spinner/Spinner";
import styled from 'styled-components/macro';
import ContentBuilder from "./ContentBuilder";
import {byAcfLayout} from "./availableBlocks";

const PageBlocks = props => {
  const {data} = props;

  let endpoint;
  let url = data.url; // this url param is useful if we need to fetch page contents from somewhere else than the pages endpoint

  // fall back to the page endpoint
  if (!url) {
    endpoint = config.get().apiEndpoints.page;
  }


  const {state, tryAgain} = useExternalContent({contentId: data.pageId, endpoint, url });
  const {isLoading, data: fetchedData, errorMessage} = state;

  let blocks;

  if (fetchedData && Array.isArray(fetchedData)) {
    blocks = fetchedData.map(block => {
      const acfLayout = block.layout;
      if (byAcfLayout[acfLayout]) {
        return {
          ...byAcfLayout[acfLayout],
          data: block,
        }
      }
      return undefined;
    })
  }

  return (
    <>
      {errorMessage && (
        <GridContainer>
          <SystemMessage spacingBottom message={errorMessage} {...{tryAgain}}/>
        </GridContainer>
      )}
      {isLoading && (
        <SpinnerWrap>
          <Spinner standalone/>
        </SpinnerWrap>
      )}
      <ContentBuilder blocks={blocks}/>
    </>
  );
};


const SpinnerWrap = styled.div`
  position: relative;
  min-height: 12.5rem;
`

export default PageBlocks;
