import React from 'react';
import Button from "../../components/UI/Button/Button";
import {useModalControls} from "../modal/ModalsController";
import {useTranslation} from "../i18n/i18n";

const EsimQrButton = props => {
  const {t} = useTranslation();
  const modalControls = useModalControls();
  const {qrImageUrl} = props;
  return (
    <Button
      noMargins
      alignLeft
      variation="tert"
      icon="QrCode"
      fullWidth={false}
      onClick={() => modalControls.esimQr.show({qrImageUrl})}
    >
      {t('link_display_qr_code')}
    </Button>
  );
};

export default EsimQrButton;
