import i18n from './i18n';
import queryString from "query-string";
import axios from "axios";
import globalConfig from "react-global-configuration";

class ApiRequest {
  constructor(settings) {
    this.i18n = settings.i18n;
    this.queryString = settings.queryString;
    this.globalConfig = settings.globalConfig;
  }

  fetch = (options) => {
    const defaults = {
      method: 'get',
      endpoint: '',
      withCredentials: false,
      authToken: null,
      query: null,
      headers: {},
      useLanguage: true,
      currency: null,
    }

    const config = Object.assign({}, defaults, options)

    const {query, headers, useLanguage, authToken, endpoint, ...restConf} = config;

    let reqConfig = {
      ...restConf,
    };

    if (authToken) {
      reqConfig.headers = {
        ...reqConfig.headers,
        'Authorization': `Bearer ${authToken}`,
      }
    }

    reqConfig.url = globalConfig.get('apiEndpoints.' + endpoint);

    let queryObject = {};
    if (useLanguage) {
      queryObject.lang = this.i18n.language;
    }

    if (config.currency) {
      queryObject.currency = config.currency;
    }

    if (query) {
      if (typeof query === 'string') {
        queryObject = {
          ...queryObject,
          ...this.queryString.parse(query),
        };
      } else if (typeof query === 'object') {
        queryObject = {
          ...queryObject,
          ...query,
        };
      }
    }
    reqConfig.url = reqConfig.url + '/?' + this.queryString.stringify(queryObject);

    return axios(reqConfig);
  }

  get = (options) => {
    return this.fetch({
      ...options,
      method: 'get',
    })
  }

  post = (options) => {
    return this.fetch({
      ...options,
      method: 'post',
    })
  }
}

export default new ApiRequest({
  i18n,
  queryString,
  globalConfig,
})
