import {createSlice} from '@reduxjs/toolkit';
import config from 'react-global-configuration';

export const appConfigSlice = createSlice({
  name: 'appConfig',
  initialState: {
    currencies: config.get('currencies'),
    languages: config.get('languages'),
  },
});

export const selectCurrencies = state => state.appConfig.currencies;
export const selectLanguages = state => state.appConfig.languages;

export default appConfigSlice.reducer;
