import {createSlice} from '@reduxjs/toolkit';

export const STATUS = {
  idle: 'idle',
  pending: 'pending',
  cancelled: 'cancelled',
}

export const topDestinationsSlice = createSlice({
  name: 'topDestinations',
  initialState: {
    topDestinations: [],
    status: STATUS.idle,
    error: null,
  },
  reducers: {
    fetchStart: state => {
      state.status = STATUS.pending;
      state.error = null;
    },
    fetchSuccess: (state, action) => {
      state.status = STATUS.idle;
      state.topDestinations = action.payload.data;
    },
    fetchFail: (state, action) => {
      state.status = STATUS.idle;
      state.error = action.payload.error;
    },
    fetchCancel: state => {
      state.status = STATUS.cancelled;
    }
  },
});

export const {
  fetchStart,
  fetchSuccess,
  fetchFail,
  fetchCancel,
} = topDestinationsSlice.actions;

export const selectStatus = state => state.topDestinations.status;
export const selectTopDestinations = state => state.topDestinations.topDestinations;
export const selectError = state => state.topDestinations.error;

export default topDestinationsSlice.reducer;
