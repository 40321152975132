import React from 'react';
import FancyTitle from "../../components/UI/FancyTitle/FancyTitle";

const ContentBuilderFancyTitle = props => {
  const {data} = props;
  const {title} = data;
  return (
    <FancyTitle htmlContent={title}/>
  );
};

export default ContentBuilderFancyTitle;
