import React from 'react';
import {selectItems, selectItemsCount, removeItem} from "./cartSlice";
import {useSelector, useDispatch} from "react-redux";
import {useTranslation} from "../i18n/i18n";
import GridContainer from "../../components/GridContainer/GridContainer";
import MainTitle from "../../components/MainTitle/MainTitle";
import VatNote from "../../components/VatNote/VatNote";
import CheckoutButton from "./CheckoutButton";
import CartItem from "./CartItem";
import styled from 'styled-components/macro';
import {Transition, TransitionGroup} from 'react-transition-group';

const ITEM_ANIMATION_DURATION = 300;

const ItemTransition = ({children, ...props}) => {
  // https://github.com/reactjs/react-transition-group/blob/1fd4a65ac45edd2aea3dec18eeb8b9c07c7eb93f/CHANGELOG.md#features
  const nodeRef = React.useRef(null);
  const handleOnExit = () => {
    nodeRef.current.style.height = nodeRef.current.offsetHeight + 'px';
    nodeRef.current.getBoundingClientRect();
  }
  const handleOnExiting = () => {
    nodeRef.current.style.height = 0;
  }
  return (
    <Transition
      {...props}
      nodeRef={nodeRef}
      timeout={ITEM_ANIMATION_DURATION}
      onExit={handleOnExit}
      onExiting={handleOnExiting}
    >
      {(status, innerProps) => {
        return React.cloneElement(children, {
          ...innerProps,
          status,
          ref: nodeRef,
        })
      }}
    </Transition>
  )
};

const Cart = props => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const cartItems = useSelector(selectItems);
  const itemsCount = useSelector(selectItemsCount);
  const {hideModal} = props;

  const cartContents = cartItems.map((item, index) => {
    const {data_package, airtime, product_id, number, simPrice, uid, currency} = item;
    const handleRemove = () => {
      dispatch(removeItem({index}));
    }

    return (
      <ItemTransition key={uid}>
        <ItemWrap>
          <CartItem
            phoneNumber={number}
            dataPackage={data_package}
            airtime={airtime}
            productId={product_id}
            currency={currency}
            onRemove={handleRemove}
            {...{simPrice}}
          />
        </ItemWrap>
      </ItemTransition>
    )
  });

  return (
    <Wrapper>
      <GridContainer>
        <MainTitle>
          {t('cart_title')} ({itemsCount})
        </MainTitle>
        {cartItems.length > 1 && (
          <CheckoutButton {...{hideModal}} />
        )}

        <TransitionGroup>
          {cartContents}
        </TransitionGroup>

        {!!cartItems.length && (
          <>
            <CheckoutButton {...{hideModal}}/>
            <VatNote/>
          </>
        )}
      </GridContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-bottom: 6rem;
`

const ItemWrap = styled.div`
  overflow: hidden;
  transition: ${ITEM_ANIMATION_DURATION}ms;
  opacity: ${({status}) => (status === 'exiting' || status === 'exited' ? 0 : 1)}; 
  transform: ${({status}) => (status === 'exiting' || status === 'exited' ? 'translateX(-100px)' : 'translateX(0)')};
  
  & + & {
    margin-top: 15px;
  }
`

export default Cart;
