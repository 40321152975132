import React from 'react';
import OrderPriceBreakDown from "../orderSummary/OrderPriceBreakdown";
import Card from "../../components/UI/Card/Card";
import {useTranslation} from "../i18n/i18n";
import OrderTitleContent from "../orderSummary/OrderTitleContent";
import styled from "styled-components/macro";
import TinyCollapse from "../../components/UI/TinyCollapse/TinyCollapse";
import TrackPurchase from "../analytics/TrackPurchase";

const ContentBuilderOrderSummary = props => {
  const {t} = useTranslation();
  const {data = {}} = props;
  const {order = {}} = data;

  return (
    <>
      <TrackPurchase order={order} />
      <Card
        labelSlot={t('order_nr') + ' #' + order.id}
      >
        <OrderTitle>
          <OrderTitleContent order={order}/>
        </OrderTitle>

        <BreakDownSection>
          <TinyCollapse title={t('see_price_breakdown')}>
            <OrderPriceBreakDown order={order}/>
          </TinyCollapse>
        </BreakDownSection>
      </Card>
    </>
  );
};

const OrderTitle = styled.div`
  font-size: ${props => props.theme.fontSize.textBigger};
  font-weight: ${props => props.theme.fontWeight.semiBold};
`

const BreakDownSection = styled.div`
  margin: 1.125rem 0 0.3125rem 0;
`

export default ContentBuilderOrderSummary;
