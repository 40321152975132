import React, {createContext, useContext} from "react";
import {useTranslation} from "react-i18next";

const systemMessagesContext = createContext(null);

/*
  Provider component that wraps the app and makes the system messages object
  available to any child component that calls useSystemMessages().
 */
export function SystemMessagesProvider(props) {
  const {t} = useTranslation();
  const {children, systemMessagesConfig} = props;
  const systemMessages = new SystemMessages({
    messagesConf: systemMessagesConfig,
    t,
  });
  return <systemMessagesContext.Provider value={systemMessages}>{children}</systemMessagesContext.Provider>;
}
/*
  Hook for child components to get the system messages object
  and re-render when it changes.
 */
export const useSystemMessages = () => {
  return useContext(systemMessagesContext);
};

class SystemMessages {
  constructor(conf) {
    this.messagesConf = conf.messagesConf;
    this.t = conf.t; // the translation function
    this.messages = {};
    Object.keys(conf.messagesConf).forEach(key => {
      this.messages[key] = {
        ...this.messagesConf[key],
        text: this.t(this.messagesConf[key].id),
      };
    });
  }
}



