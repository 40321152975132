import React from 'react';
import {useTranslation} from "../i18n/i18n";
import GridContainer from "../../components/GridContainer/GridContainer";
import MainTitle from "../../components/MainTitle/MainTitle";
import styled from 'styled-components/macro';
import ContentBuilder from "../contentBuilder/ContentBuilder";
import LinkEsimInstallManual from "../globalLinks/LinkEsimInstallManual";
import Section from "../../components/Section/Section";
import useRoutes from "../routing/useRoutes";
import LinkEsimFaq from "../globalLinks/LinkEsimFaq";

const WhatsEsim = props => {
  const {pageTitle, hideModal, inModal} = props;
  const {t} = useTranslation();
  const routes = useRoutes();
  let title = t('title_esim_info_modal');
  if (pageTitle) {
    title = pageTitle;
  }

  const pageId = routes.routesByKey.ABOUT_ESIM.customId;

  return (
    <Wrapper inModal={inModal}>
      <GridContainer>
        <MainTitle>{title}</MainTitle>

        <SubTitle>
          {t('subtitle_esim_info')}
        </SubTitle>
      </GridContainer>

      <ContentBuilder {...{pageId}}/>

      <Section>
        <GridContainer>
          <LinkEsimInstallManual/>
        </GridContainer>
      </Section>

      <Section>
        <GridContainer>
          <LinkEsimFaq
            onClick={() => {
              if (hideModal) {
                hideModal();
              }
            }}
          />
        </GridContainer>
      </Section>

    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-bottom: ${props => props.inModal ? '5rem' : 0};
`

const SubTitle = styled.h3`
  font-size: ${props => props.theme.fontSize.textBigger};
  font-weight: ${props => props.theme.fontWeight.semiBold};
`

export default WhatsEsim;
