// customId is for finding the correct translation and external content as well

// Note: pages with parents have to come first due to route matching rules
const pagesConfig = {
  RECHARGE: {
    customId: 'recharge',
    template: 'TemplateRecharge',
    parent: 'MY_CARDS',
    slugPrefix: ':number',
    private: true,
  },
  ABOUT: {
    customId: 'about',
    template: 'TemplateAbout',
  },
  ABOUT_ESIM: {
    customId: 'about_esim',
    template: 'TemplateAboutEsim',
  },
  ACCOUNT: {
    customId: 'account',
    template: 'TemplateAccount',
    private: true,
  },
  CALL_LOG: {
    customId: 'call_log',
    template: 'TemplateCallLog',
    private: true,
  },
  CHECKOUT: {
    customId: 'checkout',
    template: 'TemplateCheckout',
  },
  CHECKOUT_SUCCESS: {
    customId: 'checkout_success',
    template: 'TemplateCheckoutSuccess',
  },
  CREATE_ACCOUNT: {
    customId: 'create_account',
    template: 'TemplateCreateAccount',
  },
  ESIM_FAQ: {
    customId: 'esim_faq',
    template: 'TemplateContentBlocks',
  },
  ESIM_INSTALL_INSTRUCTIONS: {
    customId: 'esim_install_instructions',
    template: 'TemplateContentBlocks',
  },
  FORGOT_PASSWORD: {
    customId: 'forgot_password',
    template: 'TemplateForgotPassword',
  },
  GET_HELP: {
    customId: 'get_help',
    template: 'TemplateGetHelp',
  },
  INSTALL_ESIM: {
    customId: 'install_esim',
    template: 'TemplateInstallEsim',
  },
  LOG_IN: {
    customId: 'log_in',
    template: 'TemplateLogIn',
  },
  MY_CARDS: {
    customId: 'my_cards',
    template: 'TemplateMyCards',
    private: true,
  },
  LANG_AND_CURRENCY: {
    customId: 'language_and_currency',
    template: 'TemplateLangCurrency',
  },
  PRIVACY_POLICY: {
    customId: 'privacy_policy',
    template: 'TemplateContentBlocks',
  },
  PURCHASE_HISTORY: {
    customId: 'purchase_history',
    template: 'TemplatePurchaseHistory',
    private: true,
  },
  RATES_AND_COVERAGE: {
    customId: 'rates_and_coverage',
    template: 'TemplateRatesAndCoverage',
  },
  RESET_PASSWORD: {
    customId: 'reset_password',
    template: 'TemplateResetPassword',
  },
  SHIPPING: {
    customId: 'shipping',
    template: 'TemplateContentBlocks',
  },
  SIM_CONFIGURATOR: {
    customId: 'sim_configurator',
    template: 'TemplateSimConfigurator',
  },
  TERMS_AND_CONDITIONS: {
    customId: 'terms_and_conditions',
    template: 'TemplateContentBlocks',
  },
  WELCOME: {
    customId: 'welcome',
    template: 'TemplateWelcome',
  },

}

export default pagesConfig;

