import React, {useContext} from 'react';
import ReactSelect, {components} from 'react-select';
import {ThemeContext} from 'styled-components';
import styled from 'styled-components/macro';

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    fontSize: state.selectProps.theme.input.fontSize,
  }),
  control: (provided, state) => ({
    ...provided,
    borderRadius: state.selectProps.theme.input.borderRadius,
    borderColor: state.selectProps.theme.input.borderColor,
    boxShadow: state.selectProps.theme.input.boxShadow,
    backgroundColor: state.selectProps.theme.input.backgroundColor,
    '&:hover': {
      borderColor: state.selectProps.theme.input.borderColor,
    }
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '12px 20px',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: 0,
    width: '31px',
    position: 'relative',
    color: state.selectProps.theme.body.color,
    '&:hover': {
      color: state.selectProps.theme.body.color,
    }
  }),
  menu: (provided, state) => ({
    ...provided,
    borderRadius: state.selectProps.theme.input.borderRadius,
  }),
  option: (provided, state) => {
    let backgroundColor = 'transparent';
    if (state.isSelected) {
      backgroundColor = state.selectProps.theme.color.support300;
    }
    if (state.isFocused) {
      backgroundColor = state.selectProps.theme.color.support200;
    }
    return {
      ...provided,
      padding: '8px 20px',
      backgroundColor: backgroundColor,
      '&:hover': {
        backgroundColor: state.isSelected ? state.selectProps.theme.color.support300 : state.selectProps.theme.color.support200,
      },
      '&:active': {
        backgroundColor: state.isSelected ? state.selectProps.theme.color.support : state.selectProps.theme.color.support200,
      },
    };
  },
  multiValue: (provided, state) => {
    const {theme} = state.selectProps;
    return {
      ...provided,
      background: theme.pill.bg,
      borderRadius: theme.pill.borderRadius,
    };
  },
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: state.selectProps.theme.pill.color,
    borderRadius: 0,
    padding: '0.375rem 0.1875rem 0.375rem 0.5625rem',
    paddingLeft: '0.5625rem',
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    color: state.selectProps.theme.pill.color,
    borderRadius: 0,
    opacity: 0.5,
    ':hover': {
      opacity: 1,
    },
  }),
}
const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDown/>
    </components.DropdownIndicator>
  );
};

const Select = props => {
  const theme = useContext(ThemeContext);
  return (
    <ReactSelect styles={customStyles} {...props} {...{theme}} components={{DropdownIndicator}}/>
  );
};

const CaretDown = styled.div`
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -0.125rem;
  width: 0;
  height: 0; 
  border-top: 0.25rem solid currentColor;
  border-left: 0.25rem solid transparent; 
  border-right: 0.25rem solid transparent; 
`

export default Select;
