import React from 'react';
import styled from 'styled-components/macro';
import {ReactComponent as TravelsimLogoShadow} from "../../assets/svg/travelsim-logo-shadow.svg";
import {ReactComponent as TravelsimLogo} from "../../assets/svg/travelsim-logo.svg";

const Logo = (
  {
    withShadow,
    slogan,
  }
) => {

  let iconContents;

  if (withShadow) {
    iconContents = <TravelsimLogoShadow/>;
  } else {
    iconContents = <TravelsimLogo/>;
  }

  return (
    <Wrapper>
      <LogoWrapper>
        {iconContents}
      </LogoWrapper>
    </Wrapper>
  );
};

const LogoWrapper = styled.div`
  padding-top: ${props => props.withShadow ? '77%' : '73%'};
  width: 100%;
  position: relative;
  
  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const Wrapper = styled.div`
  position: relative;
`

export default Logo;


