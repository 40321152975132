import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import apiRequest from "../../apiRequest";
import {errorHandler} from "../errorHandling/errorHandler";
import {clearCart} from '../cart/cartSlice';
import {clearCards} from '../myCards/myCardsSlice';
import {reset as resetCheckout} from '../checkout/checkoutSlice';
import {resetConfig as resetSimConfig} from "../simConfigurator/simConfiguratorSlice";
import config from "react-global-configuration";

export const STATUS = {
  ...config.get().fetchStatus,
}

export const login = createAsyncThunk(
  'auth/loginStatus',
  async (params, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    const formData = new FormData();
    formData.append('username', params.email);
    formData.append('password', params.password);
    try {
      const response = await apiRequest.post({
        endpoint: 'authToken',
        data: formData,
        // withCredentials must be true or the refresh cookie will not get set by the browser
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      const {message} = errorHandler.handleApiError(error);
      return rejectWithValue({
        error: message,
      });
    }
  }
)

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    userEmail: null,
    userDisplayName: null,
    userNiceName: null,
    isLoading: false,
    error: null,
    errorShown: false,
    token: null,
    tokenExpiry: null,
    tokenRefreshStatus: STATUS.idle,
    tokenRefreshError: null,
  },
  reducers: {
    removeToken: (state) => {
      state.token = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    errorShown: (state) => {
      state.errorShown = state.error ? state.error.timestamp : true;
    },
    resetFetchState: (state) => {
      state.isLoading = false;
    },
    tokenRefreshStart: (state) => {
      state.tokenRefreshStatus = STATUS.pending;
      state.tokenRefreshError = null;
    },
    tokenRefreshSuccess: (state, action) => {
      const {payload} = action;
      if (payload.data) {
        state.token = payload.data.token;
        state.tokenExpiry = payload.data.exp;
      }
      state.tokenRefreshStatus = STATUS.idle;
    },
    tokenRefreshFail: (state, action) => {
      state.tokenRefreshStatus = STATUS.idle;
      state.tokenRefreshError = action.payload.error;
    },
    tokenRefreshCancel: (state) => {
      state.tokenRefreshStatus = STATUS.cancelled;
    },
    setTokenExpiry: (state, action) => {
      state.tokenExpiry = action.payload.expiry;
    }
  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [login.fulfilled]: (state, action) => {
      const {payload} = action;
      state.userEmail = payload.user_email;
      state.userDisplayName = payload.display_name;
      state.userNiceName = payload.nice_name;
      state.isLoading = false;
      state.error = null;
      state.token = payload.token;
      state.tokenExpiry = payload.exp;
    },
    [login.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [login.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = {
        ...action.payload.error,
        timestamp: Date.now(),
      };
    }
  }
});

export const {
  removeToken,
  start,
  errorShown,
  clearError,
  resetFetchState,
  tokenRefreshStart,
  tokenRefreshSuccess,
  tokenRefreshFail,
  tokenRefreshCancel,
  setTokenExpiry,
} = authSlice.actions;

export const logout = () => dispatch => {
  dispatch(removeToken());
  dispatch(clearCart());
  dispatch(clearCards());
  dispatch(resetCheckout());
  dispatch(resetSimConfig());
};

export const selectIsLoading = state => state.auth.isLoading;
export const selectToken = state => state.auth.token;
export const selectTokenExpiry = state => state.auth.tokenExpiry;
export const selectUserDisplayName = state => state.auth.userDisplayName;
export const selectError = state => state.auth.error;
export const selectIsLoggedIn = state => {
  return !!state.auth.token;
}
export const selectShouldShowError = state => {
  // we should only show the error message if it's newer than the last shown one
  const timestamp = state.auth.error ? state.auth.error.timestamp : null;
  return state.auth.errorShown !== timestamp;
}

export default authSlice.reducer;
