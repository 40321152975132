import {trim} from 'lodash-es';
import { generatePath } from "react-router";

class RoutesConfigurator {
  constructor(pagesConfig, settings) {
    this.pagesConfig = pagesConfig;
    this.t = settings.t; // translation function
    this.lang = settings.lang;
    this.createPageContentTranslationKey = settings.createPageContentTranslationKey;
    this.routes = this.makeRoutes(this.pagesConfig);
    this.customIds = {};
    Object.keys(pagesConfig).forEach(key => {
      this.customIds[key] = pagesConfig[key].customId;
    });
    this.linkTo = {};
    this.routesByKey = {};
    this.routes.forEach(route => {
      this.linkTo[route.key] = route.linkTo;
      this.routesByKey[route.key] = route;
    });
  }

  makeRoutes = (pagesConfig) => {
    return Object.keys(pagesConfig)
      .map(key => {
        const page = pagesConfig[key];
        const slug = this.t(this.translationPrefix(page.customId) + 'slug');

        let parentSlug;
        let path = slug;
        if (page.parent) {
          parentSlug = this.t(this.translationPrefix(pagesConfig[page.parent].customId) + 'slug');
          let childSlug = slug;
          if (page.slugPrefix) {
            childSlug = page.slugPrefix + '/' + childSlug;
          }
          path = parentSlug + '/' + childSlug;
        }
        path = this.prefixPath(path, ':lang');
        let linkTo = generatePath(path, {lang: this.lang, number: '1'});

        return {
          slug,
          title: this.t(this.translationPrefix(page.customId) + 'title'),
          customId: page.customId,
          template: page.template,
          private: page.private,
          contentId: page.contentId,
          path,
          linkTo,
          generateLinkTo: (params) => generatePath(path, {lang: this.lang, ...params}),
          contentTranslationKey: this.createPageContentTranslationKey(page.customId),
          key,
        }
      });
  }

  getRoute = (customId) => {
    return this.routes.find(route => route.customId === customId);
  }

  getRouteTitle = (customId) => {
    return this.getRoute(customId).title;
  }

  prefixPath (path, prefix) {
    return `/${prefix}/${trim(path, '/')}`
  }

  translationPrefix = customId => 'system_pages.' + customId + '.';
}

export default RoutesConfigurator;
