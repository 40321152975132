import React from 'react';
import styled from 'styled-components/macro';
import {ReactComponent as Account} from '../../assets/svg/account-box-line.svg';
import {ReactComponent as ArrowRightCircle} from '../../assets/svg/arrow-right-circle.svg';
import {ReactComponent as MailOpen} from '../../assets/svg/mail-open.svg';
import {ReactComponent as Key} from '../../assets/svg/key.svg';
import {ReactComponent as TravelsimLogoLine} from '../../assets/svg/travelsim-logo-line.svg';
import {ReactComponent as Spinner} from '../../assets/svg/spinner.svg';
import {ReactComponent as TravelsimLogoShadow} from '../../assets/svg/travelsim-logo-shadow.svg';
import {ReactComponent as TravelesimLogoShadow} from '../../assets/svg/travelesim-logo-shadow.svg';
import {ReactComponent as CaretLeft} from '../../assets/svg/caret-left.svg';
import {ReactComponent as SmartPhone} from '../../assets/svg/smartphone.svg';
import {ReactComponent as SmartPhoneLine} from '../../assets/svg/smartphone-line.svg';
import {ReactComponent as Close} from '../../assets/svg/close.svg';
import {ReactComponent as Calendar} from '../../assets/svg/calendar.svg';
import {ReactComponent as Cloud} from '../../assets/svg/cloud.svg';
import {ReactComponent as MapPin} from '../../assets/svg/map-pin.svg';
import {ReactComponent as Phone} from '../../assets/svg/phone.svg';
import {ReactComponent as Cart} from '../../assets/svg/cart.svg';
import {ReactComponent as Refresh} from '../../assets/svg/refresh.svg';
import {ReactComponent as Download} from '../../assets/svg/download.svg';
import {ReactComponent as MailSend} from '../../assets/svg/mail-send.svg';
import {ReactComponent as ExternalLink} from '../../assets/svg/external-link.svg';
import {ReactComponent as QrCode} from '../../assets/svg/qr-code.svg';
import {ReactComponent as MessageBubbles} from '../../assets/svg/message-bubbles.svg';
import {ReactComponent as MoneyEuro} from '../../assets/svg/money-euro.svg';
import {ReactComponent as MoneyDollar} from '../../assets/svg/money-dollar.svg';
import {ReactComponent as MoneyPound} from '../../assets/svg/money-pound.svg';
import {ReactComponent as CheckboxCircle} from '../../assets/svg/checkbox-circle.svg';
import {ReactComponent as Lock} from '../../assets/svg/lock.svg';
import {ReactComponent as Smile} from '../../assets/svg/smile.svg';
import {ReactComponent as Facebook} from '../../assets/svg/facebook.svg';
import {ReactComponent as Instagram} from '../../assets/svg/instagram.svg';
import {ReactComponent as Website} from '../../assets/svg/website.svg';
import {ReactComponent as ArrowRight} from '../../assets/svg/arrow-right.svg';
import {ReactComponent as CloudOffline} from '../../assets/svg/cloud-offline.svg';
import {ReactComponent as IosShare} from '../../assets/svg/ios-share.svg';

import PropTypes from "prop-types";

const icons = {
  Account,
  ArrowRight,
  ArrowRightCircle,
  Calendar,
  CaretLeft,
  Cart,
  CheckboxCircle,
  Close,
  Cloud,
  CloudOffline,
  Download,
  ExternalLink,
  Facebook,
  Instagram,
  IosShare,
  Key,
  Lock,
  MailOpen,
  MailSend,
  MapPin,
  MessageBubbles,
  MoneyDollar,
  MoneyEuro,
  MoneyPound,
  Phone,
  QrCode,
  Refresh,
  SmartPhone,
  SmartPhoneLine,
  Smile,
  TravelsimLogoLine,
  TravelsimLogoShadow,
  TravelesimLogoShadow,
  Spinner,
  Website,
}

const currencyIcon = {
  USD: 'MoneyDollar',
  EUR: 'MoneyEuro',
  CAD: 'MoneyDollar',
  AUD: 'MoneyDollar',
  GBP: 'MoneyPound',
}

const Icon = props => {
  const {name, currency} = props;
  let iconContents;
  if (currencyIcon[currency] && icons[currencyIcon[currency]]) {
    const Svg = icons[currencyIcon[currency]];
    iconContents = <Svg/>;
  } else if (icons[name]) {
    const Svg = icons[name];
    iconContents = <Svg/>;
  } else {
    iconContents = '';
  }

  return (
    <Wrapper {...props}>
      {iconContents}
    </Wrapper>
  );
};

Icon.propTypes = {
  name: PropTypes.oneOf([
    'Account',
    'ArrowRight',
    'ArrowRightCircle',
    'Calendar',
    'CaretLeft',
    'Cart',
    'CheckboxCircle',
    'Close',
    'Cloud',
    'CloudOffline',
    'Download',
    'ExternalLink',
    'Facebook',
    'Instagram',
    'IosShare',
    'Key',
    'Lock',
    'MailOpen',
    'MailSend',
    'MapPin',
    'MessageBubbles',
    'MoneyDollar',
    'MoneyEuro',
    'MoneyPound',
    'Phone',
    'QrCode',
    'Refresh',
    'SmartPhone',
    'SmartPhoneLine',
    'Smile',
    'TravelesimLogoShadow',
    'TravelsimLogoLine',
    'TravelsimLogoShadow',
    'Spinner',
    'Website',
  ]),
};

const Wrapper = styled.div`
  font-size: 1rem;
  position: relative;
  height: ${props => props.theme.iconSize};
  width: ${props => props.theme.iconSize};
  opacity: inherit;
  
  ${props => props.fullWidth && `
    width: 100%;
    height: auto;
    padding-top: 100%;
  `}
  
  ${props => props.name === 'TravelsimLogoShadow' && `
      padding-top: 77%;
  `}
  
  ${props => props.name === 'TravelesimLogoShadow' && `
    padding-top: 77%;
  `}
  
  svg {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    opacity: inherit;
    fill: currentColor;
    ${props => props.stroke ? 'stroke: ' + props.stroke : ''}
  }
  ${props => props.stroke && `
    .stroke {
      stroke: ${props.stroke}
    }
  `}
`

export default Icon;
