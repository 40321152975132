import React from 'react';
import Button from "../../components/UI/Button/Button";
import {useTranslation} from "../i18n/i18n";

const TryAgainButton = props => {
  const {t} = useTranslation();
  return <Button variation="sec" icon="Refresh" {...props} >{t('button.try_again')}</Button>;
};

export default TryAgainButton;
