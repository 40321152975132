import React from 'react';
import styled from 'styled-components/macro';
import Spinner from "../UI/Spinner/Spinner";
import Logo from "../Logo/Logo";
import PropTypes from "prop-types";

const LoadingScreen = props => {
  const {bg} = props;
  return (
    <Wrapper bg={bg}>
      <Content>
        <LogoWrap>
          <Logo withShadow/>
        </LogoWrap>
        <SpinnerWrap>
          <Spinner/>
        </SpinnerWrap>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  font-size: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.color.neutral0};
  ${props => props.bg === 'body' && `
    background-color: ${props.theme.body.bg};
  `}
`

const Content = styled.div`
  position: absolute;
  top: 50%;
  width: 100%;
  margin-top: -7.25em;
`

const LogoWrap = styled.div`
  max-width: 13.125em;
  width: 100%;
  margin: 0 auto 2.3125em auto;
`

const SpinnerWrap = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 6.125em;
`

LoadingScreen.propTypes = {
  bg: PropTypes.oneOf([
    'body',
  ]),
};

export default LoadingScreen;
