import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {messageIdsByKey} from "../systemMessages/systemMessagesConf";
import apiRequest from "../../apiRequest";
import {createSelector} from 'reselect'
import config from "react-global-configuration";
import {errorHandler} from "../errorHandling/errorHandler";

export const SIM_TYPES = {
  ...config.get().simTypes,
}

export const STEPS = {
  destinations: 'destinations',
  simType: 'simType',
  dataCredit: 'dataCredit',
}

export const FlOWS = {
  getNew: {
    key: 'getNew',
    steps: [STEPS.simType, STEPS.destinations, STEPS.dataCredit],
  },
  recharge: {
    key: 'recharge',
    steps: [STEPS.destinations, STEPS.dataCredit],
  }
}

export const fetchConfigOptions = createAsyncThunk(
  'simConfigurator/configOptionsStatus',
  async (params, thunkAPI) => {
    const {getState, rejectWithValue} = thunkAPI;
    const {destinationCountries, simType, phoneNumber} = getState().simConfigurator;
    const {currency} = getState().userSettings;
    if (!destinationCountries || !destinationCountries.length) {
      return rejectWithValue({error: {code: messageIdsByKey.sm_please_choose_destination_country}});
    }

    let queryObject = {
      type: simType,
      'countries[]': destinationCountries.map(country => country.value),
      currency,
    }

    if (phoneNumber) {
      queryObject.number = phoneNumber;
    }

    try {
      const response = await apiRequest.get({
        endpoint: 'simConfigOptions',
        query: queryObject,
      });
      return response.data;
    } catch (error) {
      const {message} = errorHandler.handleApiError(error);
      return rejectWithValue({
        error: message,
      });
    }
  }
)

const INITIAL_STATE = {
  phoneNumber: null,
  simType: null,
  destinationCountries: null,
  configOptions: {},
  currentConfig: null,
  step: STEPS.simType,
  entryPath: null,
  message: null,
  configOptionsError: null,
  isLoadingOptions: false,
  flow: FlOWS.getNew.key,
  durationOptions: [
    {
      label: "up_to_2_weeks",
      value: 14,
    },
    {
      label: "2_to_4_weeks",
      value: 15,
    }
  ],
  chosenDuration: [
    {
      value: 14
    }
  ],
  chosenDataItems: null,
  chosenCredit: null,
}

export const simConfiguratorSlice = createSlice({
  name: 'simConfigurator',
  initialState: INITIAL_STATE,
  reducers: {
    resetConfig: () => {
      return {
        ...INITIAL_STATE
      };
    },
    setPhoneNumber: (state, action) => {
      state.phoneNumber = action.payload.phoneNumber;
    },
    setSimType: (state, action) => {
      state.message = null;
      state.simType = action.payload.simType;
    },
    setDestinationCountries: (state, action) => {
      state.destinationCountries = action.payload.destinations;
    },
    setStep: (state, action) => {
      state.step = action.payload.step;
    },
    stepBack: (state) => {
      if (state.step === STEPS.dataCredit) {
        state.step = STEPS.destinations;
      } else if (state.step === STEPS.destinations) {
        state.step = STEPS.simType;
      }
    },
    confirmSimType: (state, action) => {
      state.message = null;
      if (!state.simType) {
        state.message = {code: messageIdsByKey.sm_please_choose_sim_type};
      } else {
        state.step = STEPS.destinations;
      }
    },
    clearConfigOptionsError: (state) => {
      state.configOptionsError = null;
    },
    setChosenDuration: (state, action) => {
      state.chosenDuration = action.payload.value;
      if (state.chosenDataItems) {
        state.chosenDataItems = state.chosenDataItems.filter(item => item.validity > state.chosenDuration[0].value);
      }
    },
    setChosenDataItems: (state, action) => {
      state.chosenDataItems = action.payload.value;
    },
    setChosenCredit: (state, action) => {
      state.chosenCredit = action.payload.value;
    },
    setEntryPath: (state, action) => {
      state.entryPath = action.payload.path;
    },
    setFlow: (state, action) => {
      state.flow = action.payload.flow;
    }
  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [fetchConfigOptions.fulfilled]: (state, action) => {
      const {payload} = action;
      state.configOptions = payload;
      state.isLoadingOptions = false;
      state.step = STEPS.dataCredit;
      state.chosenDuration = INITIAL_STATE.chosenDuration;
      state.chosenDataItems = INITIAL_STATE.chosenDataItems;
      state.chosenCredit = INITIAL_STATE.chosenCredit;
    },
    [fetchConfigOptions.pending]: (state, action) => {
      state.isLoadingOptions = true;
    },
    [fetchConfigOptions.rejected]: (state, action) => {
      const {payload} = action;
      state.configOptionsError = payload.error
      state.isLoadingOptions = false;
    }
  }
});

export const {
  resetConfig,
  setPhoneNumber,
  setSimType,
  confirmSimType,
  setDestinationCountries,
  clearConfigOptionsError,
  setChosenDuration,
  setChosenDataItems,
  setChosenCredit,
  stepBack,
  setEntryPath,
  setStep,
  setFlow,
} = simConfiguratorSlice.actions;

export const startNewSimConfig = payload => dispatch => {
  dispatch(resetConfig(payload));
  dispatch(setEntryPath(payload));
};

export const startRechargeConfig = payload => dispatch => {
  dispatch(resetConfig(payload));
  dispatch(setPhoneNumber(payload));
  dispatch(setSimType(payload));
  dispatch(setStep({step: STEPS.destinations}));
  dispatch(setFlow({flow: FlOWS.recharge.key}));
  dispatch(setEntryPath(payload));
};

export const selectStep = state => state.simConfigurator.step;
export const selectSimType = state => state.simConfigurator.simType;
export const selectMessage = state => state.simConfigurator.message;
export const selectIsLoadingOptions = state => state.simConfigurator.isLoadingOptions;
export const selectConfigOptionsError = state => state.simConfigurator.configOptionsError;
export const selectDestinationCountries = state => state.simConfigurator.destinationCountries;
export const selectConfigOptions = state => state.simConfigurator.configOptions;
export const selectChosenDuration = state => state.simConfigurator.chosenDuration;
export const selectChosenDataItems = state => state.simConfigurator.chosenDataItems;
export const selectChosenCredit = state => state.simConfigurator.chosenCredit;
export const selectDurationOptions = state => state.simConfigurator.durationOptions;
export const selectFlow = state => state.simConfigurator.flow;
export const selectPhoneNumber = state => state.simConfigurator.phoneNumber;
export const selectEntryPath = state => state.simConfigurator.entryPath;

export const selectAvailableDataOptions = createSelector(
  [selectConfigOptions, selectChosenDuration],
  (options, duration) => {
    let items = [];
    if (options.data_packages && options.data_packages.length) {
      const matchingItemGroup = options.data_packages.find(dataPackage => dataPackage.validity >= duration[0].value);
      if (matchingItemGroup) {
        items = matchingItemGroup.items;
      }
    }
    return items;
  }
);

export default simConfiguratorSlice.reducer;
