import {createSlice} from '@reduxjs/toolkit';
import { createSelector } from 'reselect'
import config from "react-global-configuration";

export const STATUS = {
  ...config.get().fetchStatus,
}
export const shippingCountriesSlice = createSlice({
  name: 'shippingCountries',
  initialState: {
    countries: [],
    status: STATUS.idle,
    error: null,
  },
  reducers: {
    fetchStart: state => {
      state.status = STATUS.pending;
      state.error = null;
    },
    fetchSuccess: (state, action) => {
      state.status = STATUS.idle;
      state.countries = action.payload.data;
    },
    fetchFail: (state, action) => {
      state.status = STATUS.idle;
      state.error = action.payload.error;
    },
    fetchCancel: state => {
      state.status = STATUS.cancelled;
    }
  },
});

export const {
  fetchStart,
  fetchSuccess,
  fetchFail,
  fetchCancel,
} = shippingCountriesSlice.actions;

export const selectStatus = state => state.shippingCountries.status;
export const selectCountries = state => state.shippingCountries.countries;

export const selectShippingCountriesInLabelFormat = createSelector(
  [selectCountries],
  countries => {
    if (!Array.isArray(countries)) {
      return [];
    }
    return countries.map((country) => {
      return {
        label: country.value,
        value: country.code,
      }
    })
  }
)
export const selectError = state => state.shippingCountries.error;

export default shippingCountriesSlice.reducer;
