import React from 'react';
import useExternalContent from "../externalContent/useExternalContent";
import SystemMessage from "../systemMessages/SystemMessage";
import styled from 'styled-components/macro';
import Spinner from "../../components/UI/Spinner/Spinner";
import GridContainer from "../../components/GridContainer/GridContainer";
import config from "react-global-configuration";
import {byAcfLayout} from "./availableBlocks";
import ContentBuilder from "./ContentBuilder";

const SingleBlock = props => {
  const {contentId} = props;
  const {state, tryAgain} = useExternalContent({contentId, endpoint: config.get('apiEndpoints.globalContent')});
  const {isLoading, data: fetchedData, errorMessage} = state;

  let blocks;

  if (fetchedData) {
    const acfLayout = fetchedData.layout;
    if (byAcfLayout[acfLayout]) {
      blocks = [{
        ...byAcfLayout[acfLayout],
        inContainer: false,
        wrapInSection: false, // these are already handled by the first contentBuilder
        data: fetchedData,
      }]
    }
  }

  return (
    <Wrapper>
      <GridContainer>
        <SystemMessage spacingBottom message={errorMessage} {...{tryAgain}}/>
      </GridContainer>
      {isLoading && (
        <Spinner standalone/>
      )}
      <ContentBuilder blocks={blocks}/>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  min-height: 6rem;
`

export default SingleBlock;

export const contentBlockIds = config.get('apiEndpoints.contentBlockIds');
