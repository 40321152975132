import React from 'react';
import styled from 'styled-components/macro';
import Icon from "../../Icon/Icon";

const CustomList = props => {
  const {children} = props;
  let items;
  if (children && Array.isArray(children)) {
    items = children.map((item, index) => (
      <ListItem key={index}>
        <IconWrap>
          <Icon name="CheckboxCircle"/>
        </IconWrap>
        {item}
      </ListItem>
    ));
  }

  return (
    <List>
      {items}
    </List>
  );
};

const IconWrap = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  color: ${props => props.theme.color.support};
`

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: block;
`

const ListItem = styled.li`
  margin: 0;
  padding: 0 0 0 2.125rem;
  display: block;
  position: relative;

  & + & {
    margin-top: 0.5625rem;
  }
`

export default CustomList;
