import React from 'react';
import Accordion from "../../components/UI/Accordion/Accordion";

const Faq = props => {
  const {data} = props;
  let questions;
  if (data) {
    questions = data.faq_questions || data.esim_faq_questions;
  }

  let faqItems = [];
  if (questions && Array.isArray(questions)) {
    faqItems = questions.map((item, index) => (
      <div
        key={index}
        header={item.question}
      >
        <div
          dangerouslySetInnerHTML={{__html: item.answer}}
        />
      </div>
    ))
  }
  return (
    <Accordion>
      {faqItems}
    </Accordion>
  );
};

export default Faq;
