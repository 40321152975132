import React from 'react';
import styled from 'styled-components/macro';

const colorMap = [
  '#FF5000',
  '#FF5000',
  '#FFA982',
  '#5EBBBD',
  '#5EBBBD',
  '#338C8F',
  '#338C8F',
  '#338C8F',
  '#338C8F',
  '#338C8F',
];

function getColor(value, colorMap) {
  const mapLength = colorMap.length;
  if (value >= 1) {
    return colorMap[mapLength - 1];
  }

  if (value <= 0) {
    return colorMap[0];
  }

  const stepSize = 1 / mapLength;

  return colorMap[Math.floor(value / stepSize)];
}

const Meter = props => {
  return (
    <Wrapper {...props}>
      <Bar value={props.value}/>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  font-size: 1rem;
  height: 0.8125em;
  border-radius: 1.25em;
  background-color: ${props => props.theme.meter.bg};
  overflow: hidden;
  position: relative;
`

const Bar = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: ${props => (props.value * 100).toFixed(2)}%;
  background-color: ${props => getColor(props.value, colorMap)};
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  transition: width 0.4s, background-color 0.4s;
`

export default Meter;
