import React from 'react';
import LoadingScreen from "./components/LoadingScreen/LoadingScreen";
import {Provider} from "react-redux";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import {persistor, store} from "./app/store";
import {ThemeProvider} from "styled-components/macro";
import theme from "./styles/theme";
import GlobalStyle from "./styles/GlobalStyle";
import {PersistGate} from 'redux-persist/integration/react'
import './i18n';
import * as Sentry from "@sentry/browser";
import AppInner from "./AppInner";
import DevUtilBar from "./features/devHelpers/DevUtilBar";
import ServiceWorkerWrapper from "./features/pwa/ServiceWorkerWrapper";
import "./features/analytics/GoogleTagManager";


Sentry.init({
  dsn: "https://209f2caa39b74badae70136e903cd055@o404641.ingest.sentry.io/5269034",
  environment: process.env.REACT_APP_SENTRY_ENV,
  release: 'travelsim-web-app@' + process.env.REACT_APP_VERSION,
  debug: false,
});

// ServiceWorkerWrapper needs the store
function App() {
  return (
    <React.StrictMode>
      <ErrorBoundary>
        <Provider store={store}>
          <ThemeProvider theme={theme.theme}>
            <PersistGate loading={<LoadingScreen/>} persistor={persistor}>
              <ErrorBoundary persistor={persistor} shouldPurge>
                <React.Suspense fallback={<LoadingScreen/>}>
                  <GlobalStyle/>
                  <DevUtilBar/>
                  <ServiceWorkerWrapper/>
                  <AppInner/>
                </React.Suspense>
              </ErrorBoundary>
            </PersistGate>
          </ThemeProvider>
        </Provider>
      </ErrorBoundary>
    </React.StrictMode>
  );
}

export default App;
