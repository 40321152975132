import React, {useEffect, useState} from 'react';
import styled from "styled-components/macro";
import CountryFlag from "../CountryFlag/CountryFlag";
import {resetButtonStyles} from "../../../styles/mixins";

const newMultiSelectValue = (option, value, selectedValues, isSelected) => {
  if (isSelected) {
    return selectedValues.filter(element => element.value !== value);
  } else {
    return [...selectedValues, option];
  }
}

const CountryList = props => {
  const {countries, onClick, value, onChange, borderTop} = props;
  const initialValue = Array.isArray(value) ? value : [];
  const [selectedValues, setSelectedValues] = useState(initialValue)

  useEffect(() => {
    setSelectedValues(value || []);
  }, [value])

  let list;
  if (countries && Array.isArray(countries)) {

    list = countries.map(country => {
      const {value, code} = country;
      const currentOptionIndex = selectedValues.findIndex(element => element.value === value);
      const isSelected = currentOptionIndex !== -1;

      const clickHandler = () => {
        const newSelected = newMultiSelectValue(country, value, selectedValues, isSelected);
        setSelectedValues(newSelected);
        if (onClick) {
          onClick(country);
        }
        if (onChange) {
          onChange(newSelected);
        }
      }

      return (
        <Item key={code} borderTop={borderTop}>
          <Button
            onClick={clickHandler}
            isSelected={isSelected}
          >
            <div>
              <CountryFlag countryCode={code}/>
            </div>
            <TextCol>
              {value}
            </TextCol>
          </Button>
        </Item>
      )
    });
  }
  return (
    <>
      {list}
    </>
  );
};

const Item = styled.div`
  border-bottom: ${props => props.theme.borderWidth} solid ${props => props.theme.borderColor};
  margin-left: ${props => props.theme.wideContentMarginLeft};
  margin-right: ${props => props.theme.wideContentMarginRight};
  ${props => props.borderTop && `
    &:first-child {
      border-top: ${props.theme.borderWidth} solid ${props.theme.borderColor};
    }
  `}
`

const TextCol = styled.div`
  padding-left: 0.8125rem;
`

const Button = styled.button`
  ${resetButtonStyles};
  padding: 0.8125rem  ${props => props.theme.wideContentPaddingLeft};
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: ${props => props.theme.fontWeight.semiBold};
  font-size: ${props => props.theme.fontSize.textBigger};
  cursor: pointer;
  ${props => props.isSelected && `
    color: ${props.theme.color.support};
  `}
`

export default CountryList;
