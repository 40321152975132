import React from 'react';
import styled from "styled-components/macro";

const BorderedList = props => {
  const {verticalPaddings} = props;
  let nodes = props.children.map((node, index) => <Item verticalPaddings={verticalPaddings} key={index}>{node}</Item>);
  return (
    <div>
      {nodes}
    </div>
  );
};

const Item = styled.div`
  padding: ${props => props.verticalPaddings ? '1.0625rem' : '0'}  ${props => props.theme.wideContentPaddingLeft};
  border-bottom: ${props => props.theme.borderWidth} solid ${props => props.theme.borderColor};
  margin-left: ${props => props.theme.wideContentMarginLeft};
  margin-right: ${props => props.theme.wideContentMarginRight};
`



export default BorderedList;
