import { createSlice } from '@reduxjs/toolkit';

export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState: {
    trackedPurchases: [],
  },
  reducers: {
    addTrackedPurchase: (state, action) => {
      state.trackedPurchases.push(action.payload);
    },
  },
});

export const { addTrackedPurchase } = analyticsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectTrackedPurchases = state => state.analytics.trackedPurchases;

export default analyticsSlice.reducer;
