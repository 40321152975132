import React from 'react';
import useRoutes from "../routing/useRoutes";
import {useHistory} from "react-router-dom";
import Button from "../../components/UI/Button/Button";

const EsimInstallButton = props => {
  const {data} = props;
  const routes = useRoutes()
  const history = useHistory();
  let buttonTitle;
  if (data && data.button_title) {
    buttonTitle = data.button_title;
  }

  return (
    <Button
      onClick={() => {
        history.push(routes.linkTo.ESIM_INSTALL_INSTRUCTIONS);
      }}
    >
      {buttonTitle}
    </Button>
  );
};

export default EsimInstallButton;
