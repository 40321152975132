import config from "react-global-configuration";
import Faq from "./Faq";
import Content from "./Content";
import PageBlocks from "./PageBlocks";
import SingleBlock from "./SingleBlock";
import Contact from "./Contact";
import EsimCompatibility from "./EsimCompatibility";
import EsimInfo from "./EsimInfo";
import ContentBuilderFancyTitle from "./ContentBuilderFancyTitle";
import Articles from "./Articles";
import ContentBuilderOrderSummary from "./ContentBuilderOrderSummary";
import LinkToLogin from "./LinkToLogin";
import EsimInstallButton from "./EsimInstallButton";

// blocks that can be used in the contentBuilder
export const availableBlocks = {
  articles: {
    component: Articles,
    name: 'articles',
    wrapInSection: true,
    inContainer: true,
  },
  contact: {
    component: Contact,
    name: 'contact',
    wrapInSection: true,
    inContainer: true,
  },
  content: {
    component: Content,
    name: 'content',
    wrapInSection: true,
    inContainer: true,
  },
  esimCompatibility: {
    component: EsimCompatibility,
    name: 'esimCompatibility',
    inContainer: true,
    wrapInSection: true,
  },
  esimInfo: {
    component: EsimInfo,
    name: 'esimInfo',
    inContainer: true,
    wrapInSection: true,
  },
  esimInstallButton: {
    component: EsimInstallButton,
    name: 'esimInstallButton',
    inContainer: true,
    wrapInSection: true,
  },
  fancyTitle: {
    component: ContentBuilderFancyTitle,
    name: 'fancyTitle',
    inContainer: true,
    wrapInSection: true,
  },
  faq: {
    component: Faq,
    name: 'faq',
    wrapInSection: true,
    inContainer: true,
  },
  globalEsimInfo: {
    component: SingleBlock,
    name: 'globalEsimInfo',
    inContainer: true,
    props: {
      contentId: config.get().globalContentBlockIds.esimInfo,
    }
  },
  globalEsimFaq: {
    component: SingleBlock,
    name: 'globalEsimFaq',
    inContainer: true,
    wrapInSection: true,
    props: {
      contentId: config.get().globalContentBlockIds.globalEsimFaq,
    }
  },
  globalFaq: {
    component: SingleBlock,
    name: 'globalFaq',
    inContainer: true,
    wrapInSection: true,
    props: {
      contentId: config.get().globalContentBlockIds.faq,
    }
  },
  globalContact: {
    component: SingleBlock,
    name: 'globalContact',
    inContainer: true,
    wrapInSection: true,
    props: {
      contentId: config.get().globalContentBlockIds.contact,
    }
  },
  linkToLogin: {
    component: LinkToLogin,
    name: 'linkToLogin',
    inContainer: true,
    wrapInSection: true,
  },
  orderConfirmation: {
    component: Content,
    name: 'orderConfirmation',
    inContainer: true,
    wrapInSection: true,
  },
  orderSummary: {
    component: ContentBuilderOrderSummary,
    name: 'orderSummary',
    inContainer: true,
    wrapInSection: true,
  },
  pageBlocks: {
    component: PageBlocks,
    name: 'pageBlocks',
  },
}

// this is how we know how the availableBlocks maps to the acf blocks data fetched from the api
export const byAcfLayout = {
  articles: availableBlocks.articles,
  faq: availableBlocks.faq,
  fancy_title: availableBlocks.fancyTitle,
  content: availableBlocks.content,
  contact: availableBlocks.contact,
  esim_info: availableBlocks.esimInfo,
  esim_install_button: availableBlocks.esimInstallButton,
  esim_compatibility: availableBlocks.esimCompatibility,
  link_to_login: availableBlocks.linkToLogin,
  order_confirmation: availableBlocks.orderConfirmation,
  order_summary: availableBlocks.orderSummary,
}
