// https://stackoverflow.com/questions/41742390/javascript-to-check-if-pwa-or-mobile-web
function isInStandaloneMode() {
  return (
      window.matchMedia('(display-mode: standalone)').matches) ||
    (window.navigator.standalone) ||
    document.referrer.includes('android-app://'
    );
}

export default isInStandaloneMode();
