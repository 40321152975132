import React from 'react';
import DescriptionItem from "../../components/UI/DescriptionItem/DescriptionItem";

const Contact = props => {
  const {data = {}} = props;
  return (
    <>
      <DescriptionItem
        icon="SmartPhoneLine"
        term={data.phone_label}
        description={data.phone_text}
        extra={data.phone_extra}
        url={'tel:' + data.phone_text}
      />
      <DescriptionItem
        icon="MailSend"
        term={data.email_label}
        description={data.email_text}
        extra={data.email_extra}
        url={'mailto:' + data.email_text}
      />
      <DescriptionItem
        icon="MapPin"
        term={data.address_label}
        extra={data.address_extra}
        description={data.address_text}
      />
    </>
  );
};

export default Contact;
