import TagManager from 'react-gtm-module';

import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addTrackedPurchase, selectTrackedPurchases} from "./analyticsSlice";

export default props => {
  const {order} = props;
  const dispatch = useDispatch();
  const trackedPurchases = useSelector(selectTrackedPurchases);

  useEffect(() => {
    if (!order) {
      return;
    }

    const orderId = String(order.id);

    let transactionProducts = [];
    if (order.items && Array.isArray(order.items)) {
      transactionProducts = order.items.map(item => ({
        sku: '0',
        name: item.name,
        price: item.total,
        quantity: item.quantity || 1,
      }));
    }

    if (!trackedPurchases.includes(orderId)) {
      dispatch(addTrackedPurchase(orderId));
      TagManager.dataLayer({
          dataLayer: {
            'event': 'transaction',
            'transactionId': String(order.id),
            'transactionTotal': order.total,
            'transactionTax': order.tax,
            'transactionProducts': transactionProducts,
            'currency': order.currency,
          }
        })
    }
  }, [dispatch, order, trackedPurchases]);

  return null;
};
