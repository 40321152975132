import React from 'react';
import styled from 'styled-components/macro';

const Badge = props => {
  return (
    <Wrapper>
      {props.children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: inline-block;
  vertical-align: top;
  text-transform: uppercase;
  padding: 0.1875rem 0.375rem;
  border-radius: ${props => props.theme.badge.borderRadius};
  font-size: ${props => props.theme.badge.fontSize};
  font-weight: ${props => props.theme.badge.fontWeight};
  color: ${props => props.theme.badge.color};
  background: ${props => props.theme.badge.bg};
`

export default Badge;
