import React from 'react';
import styled from 'styled-components/macro';
import Icon from "../Icon/Icon";

const MainTitle = props => {
  const {children, subTitle, className, icon} = props;

  let hasIcon;
  let iconContent;
  if (icon) {
    hasIcon = true;
    iconContent = (
      <IconWrapper>
        <Icon name={icon} fullWidth/>
      </IconWrapper>
    );
  }
  return (
    <Wrapper {...{className, hasIcon}}>
      {iconContent}
      <Title>
        {children}
      </Title>
      {subTitle && (
        <SubtTitle>
          {subTitle}
        </SubtTitle>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 1.75rem;
  position: relative;
  ${props => props.hasIcon && `
    padding-left: 1.8125rem;
  `}
`

const Title = styled.h1`
  margin: 0;
`

const SubtTitle = styled.h2`
  margin: 0.4375rem 0 0 0;
  font-size: 1rem;
  font-weight: ${({theme}) => theme.fontWeight.semiBold};
`

const IconWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0.3125rem;
  color: currentColor;
  width: 1.5rem;
`


export default MainTitle;
