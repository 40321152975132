import {selectCurrency} from "../features/userSettings/userSettingsSlice";
import {useSelector} from "react-redux";
import {useTranslation} from "../features/i18n/i18n";

export const INPUT_FORMAT = {
  cents: 'cents',
  float: 'float',
}

export const OUTPUT_FORMAT = {
  intl: 'intl',
  currencyCode: 'currencyCode',
}

export default (settings) => {
  const selectedCurrency = useSelector(selectCurrency);
  const {t, i18n} = useTranslation();
  const defaults = {
    inputFormat: INPUT_FORMAT.cents,
    replaceZeroPriceWith: t('price_0'),
    replaceNullWith: t('no_coverage'),
    outputFormat: OUTPUT_FORMAT.intl,
    currency: null,
  }
  const config = Object.assign({}, defaults, settings);

  return (amount, appendText, params = {}) => {

    if ((amount === null || amount === undefined) && config.replaceNullWith) {
      return config.replaceNullWith;
    } else if (amount === 0 && config.replaceZeroPriceWith) {
      return config.replaceZeroPriceWith;
    }

    let value = amount;

    if (typeof amount === 'number') {
      if (config.inputFormat === INPUT_FORMAT.cents) {
        value = (amount / 100).toFixed(2);
      } else if (config.inputFormat === INPUT_FORMAT.float) {
        value = amount.toFixed(2);
      }
    }

    let formattedPrice;
    const currency = params.currency || config.currency || selectedCurrency;
    if (config.outputFormat === OUTPUT_FORMAT.intl) {
      formattedPrice = new Intl.NumberFormat([i18n.language, 'en'], {
        style: 'currency',
        currency: currency,
      }).format(value);
    } else {
      formattedPrice = value + ' ' + currency;
    }

    if (appendText) {
      formattedPrice = formattedPrice + ' ' + appendText;
    }

    return formattedPrice;
  }
}
