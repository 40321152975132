import {createSlice} from '@reduxjs/toolkit';

export const STATUS = {
  idle: 'idle',
  pending: 'pending',
  cancelled: 'cancelled',
}

export const myCardsSlice = createSlice({
  name: 'myCards',
  initialState: {
    cards: null,
    status: STATUS.idle,
    error: null,
  },
  reducers: {
    fetchStart: state => {
      state.status = STATUS.pending;
      state.error = null;
    },
    fetchSuccess: (state, action) => {
      state.status = STATUS.idle;
      state.cards = action.payload.data;
    },
    fetchFail: (state, action) => {
      state.status = STATUS.idle;
      state.error = action.payload.error;
    },
    fetchCancel: state => {
      state.status = STATUS.cancelled;
    },
    clearCards: state => {
      state.cards = null;
    }
  },
});

export const {
  fetchStart,
  fetchSuccess,
  fetchFail,
  fetchCancel,
  clearCards,
} = myCardsSlice.actions;

export const selectStatus = state => state.myCards.status;
export const selectCards = state => state.myCards.cards;
export const selectError = state => state.myCards.error;

export default myCardsSlice.reducer;
