import React from 'react';
import ReactCountryFlag from "react-country-flag";
import styled from 'styled-components/macro';

const CountryFlag = props => {
  const {className} = props;
  return (
    <Wrapper {...{className}}>
      <ReactCountryFlag
        svg
        countryCode={props.countryCode}
        style={{
          display: 'block',
          height: '100%',
          width: 'auto',
          position: 'absolute',
          top: 0,
          left: '50%',
          marginLeft: '-1.25em',
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  font-size: 1rem;
  width: 1.875em;
  height: 1.875em;
  border-radius: 100%;
  overflow: hidden;
  position: relative;
`

export default CountryFlag;
