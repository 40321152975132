import {createSlice} from '@reduxjs/toolkit';

export const STATUS = {
  start: 'start',
  login: 'login',
  completed: 'completed',
}

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState: {
    status: STATUS.start,
  },
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload.status;
    }
  }
});

export const {
  setStatus,
} = onboardingSlice.actions;

export const selectStatus = state => state.onboarding.status;

export default onboardingSlice.reducer;
