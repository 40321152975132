import config from "react-global-configuration";
import TagManager from 'react-gtm-module';

const gtmId = config.get().gtmId;

if (gtmId) {
  console.log('initialize tag manager');
  TagManager.initialize({
    gtmId,
  });
}

export default TagManager;
