import React from 'react';
import styled from 'styled-components/macro';

const Section = props => {
  const {marginTop} = props;
  return (
    <Wrapper {...{marginTop}}>
      {props.children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: ${props => props.marginTop ? '1.625rem' : 0};
  & + & {
    margin-top: 1.625rem;
  }
`

export default Section;
