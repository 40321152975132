import React, {useState, useEffect} from 'react';
import styled from 'styled-components/macro';
import SimCard from '../../components/UI/SimCard/SimCard';
import hash from '../../util/hash';
// https://react-id-swiper.ashernguyen.site/doc/custom-build
import {Swiper, Pagination, Controller} from 'swiper/js/swiper.esm';
import ReactIdSwiperCustom from 'react-id-swiper/lib/ReactIdSwiper.custom';
import 'swiper/css/swiper.css';
import StatsBlock from '../simStats/StatsBlock';
import GridContainer from "../../components/GridContainer/GridContainer";
import MainTitle from "../../components/MainTitle/MainTitle";
import Layout from "./Layout";
import config from 'react-global-configuration';
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";
import SystemMessage from "../systemMessages/SystemMessage";
import {useSelector} from 'react-redux';
import formatTravelsimNumber from "../../util/formatTravelsimNumber";
import {
  selectStatus,
  selectCards,
  selectError,
  STATUS
} from "../myCards/myCardsSlice";
import useFetchMyCards from "../myCards/useFetchMyCards";
import {useSystemMessages} from "../systemMessages/useSystemMessages";

const loadingStateData = [
  {
    number: '+372 XXX XXX XXX',
    plan: '',
  },
]


const TemplateMyCards = props => {
  const [cardSwiper, setCardSwiper] = useState(null);
  const [statSwiper, setStatSwiper] = useState(null);
  const status = useSelector(selectStatus);
  const cards = useSelector(selectCards);
  const error = useSelector(selectError);
  const systemMessages = useSystemMessages();

  const {tryAgain} = useFetchMyCards({
    pollInterval: config.get('myCardsPollInterval'),
  })

  const cardSwiperParams = {
    Swiper,
    modules: [Pagination, Controller],
    getSwiper: setCardSwiper,
    spaceBetween: 20,
    centeredSlides: true,
    slidesPerView: 'auto',
    slideToClickedSlide: true,
    shouldSwiperUpdate: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    }
  };

  const statSwiperParams = {
    Swiper,
    modules: [Controller],
    getSwiper: setStatSwiper,
    shouldSwiperUpdate: true,
    // we can't use autoheight here because the slide height won't update properly when esim collapses are opened or closed
  };

  let mockCardType = 'loadingMock';
  let statType = 'loadingMock';
  if (cards && cards.length) {
    mockCardType = null;
    statType = null;
  }


  useEffect(() => {
    if (
      cardSwiper !== null &&
      cardSwiper.controller &&
      statSwiper !== null &&
      statSwiper.controller
    ) {
      cardSwiper.controller.control = statSwiper;
      statSwiper.controller.control = cardSwiper;
    }
  }, [cardSwiper, statSwiper]);

  let cardsData = cards || loadingStateData;

  let content = (
    <div>
      <CardsWrapper>
        <ReactIdSwiperCustom {...cardSwiperParams}>
          {cardsData.map(card => (
            <CardSlide key={hash(card.number)}>
              <SimCard
                phoneNumber={formatTravelsimNumber(card.number)}
                type={mockCardType || card.type}
                cardName={card.label}
              />
            </CardSlide>
          ))}
        </ReactIdSwiperCustom>
      </CardsWrapper>

      <StatsWrapper>
        <ReactIdSwiperCustom {...statSwiperParams}>
          {cardsData.map(card => (
            <StatSlide key={hash(card.number)}>
              <StatsBlock {...{card}} type={statType}/>
            </StatSlide>
          ))}
        </ReactIdSwiperCustom>
      </StatsWrapper>
    </div>
  )

  if (error && !cards && status !== STATUS.pending) {
    content = undefined;
  }

  let message;

  if (error) {
    message = (
      <MessageWrap>
        <SystemMessage message={error} {...{tryAgain}}/>
      </MessageWrap>
    )
  } else if (cards && !cards.length) {
    message = (
      <MessageWrap>
        <SystemMessage message={systemMessages.messages.sm_no_cards_found}/>
      </MessageWrap>
    )
  }

  return (
    <Layout {...props} hideBackButton>
      {!cards && status === STATUS.pending && (
        <LoadingOverlay/>
      )}
      <GridContainer>
        <MainTitle>{props.pageData.title}</MainTitle>

        {message}
      </GridContainer>

      {content}

    </Layout>
  );
};

const CardSlide = styled.div`
  width: 16.3125rem;
  margin-bottom: 1.6875rem;
`

const StatsWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: ${props => props.theme.gridContainer.maxWidth};
`

const StatSlide = styled.div`
  padding-left: ${props => props.theme.gridContainer.paddingLeft};
  padding-right: ${props => props.theme.gridContainer.paddingRight};
`

const CardsWrapper = styled.div`
  .swiper-pagination-bullet {
    width: ${props => props.theme.paginationBullet.width};
    height: ${props => props.theme.paginationBullet.height};
    background-color: ${props => props.theme.paginationBullet.bg};
    opacity: 1;
  }
  
  .swiper-pagination-bullet-active {
    background-color: ${props => props.theme.paginationBullet.bgActive};
  }
  
  .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin-left: ${props => props.theme.paginationBullet.spacing};
    margin-right: ${props => props.theme.paginationBullet.spacing};
  }
`


const MessageWrap = styled.div`
  margin-bottom: ${props => props.theme.spacer};
`

export default TemplateMyCards;
