export const vars = {
  color: {
    white: '#ffffff',
    gray100: '#f8f8f8',
    gray200: '#eeeeee',
    gray300: '#e0e0e0',
    gray400: '#858585',
    gray500: '#545454',
    gray600: '#313131',
    brandOrange: '#ff5000',
    tealLightest: '#c1dcdd',
    tealLighter: '#57a1a3',
    teal: '#338c8f',
    tealDarker: '#1C7072',
  },
  fontFamily: {
    primary: '"Source Sans Pro", sans-serif;'
  },
  fontWeight: {
    normal: '400',
    semiBold: '600',
    bold: '700',
  },
  lineHeight: {
    base: 1.4,
    reduced: 1.27,
  },
  spacer: '0.9375rem',
}

class Theme {
  constructor(vars) {
    this.vars = vars;
    this.base = {
      fontFamily: vars.fontFamily,
      fontWeight: vars.fontWeight,
      lineHeight: vars.lineHeight,
      transitionBaseDuration: '0.2s',
      borderRadius: '0.25rem',
      borderRadiusLg: '0.375rem',
      spacer: vars.spacer,
      iconSize: '1.5rem',
    };
    this.base.color = {
      primary: vars.color.brandOrange,
      primary400: vars.color.brandOrange,
      support200: vars.color.tealLightest,
      support300: vars.color.tealLighter,
      support: vars.color.teal,
      support400: vars.color.teal,
      support500: vars.color.tealDarker,
      neutral0: vars.color.white,
      neutral100: vars.color.gray100,
      neutral200: vars.color.gray200,
      neutral300: vars.color.gray300,
      neutral400: vars.color.gray400,
      neutral500: vars.color.gray500,
      neutral600: vars.color.gray600,
      iOSSystemBlue: '#0a84ff',
      successLighter: '#d4edda',
      success: '#c3e6cb',
      successDarker: '#155724',
      warningLighter: '#fff3cd',
      warning: '#ffeeba',
      warningDarker: '#856404',
      dangerLighter: '#f8d7da',
      dangerLight: '#f5c6cb',
      danger: '#ff0033',
      dangerDarker: '#721c24',
      infoLighter: '#d1ecf1',
      info: '#bee5eb',
      infoDarker: '#0c5460',
    };
    this.base.boxShadow = {
      support: '0 2px 4px 0 rgba(51,140,143,0.31)',
      neutral: '0 2px 4px 0 #EEEEEE',
    };
    this.base.fontSize = {
      root: '16px',
      textSmaller: '0.875rem',
      text: '0.9375rem',
      textBigger: '1rem',
      h1: '1.75rem',
      h2: '1.625rem',
      h3: '1.1875rem',
    };
    this.base.gradient = {
      primary: 'linear-gradient(134.24deg, #FFA982 0%, #FF5000 100%)',
      support: 'linear-gradient(45deg, #48A3A6 0%, #338C8F 100%)',
      supportFocus: 'linear-gradient(45deg, #338C8F 0%, #338C8F 100%)',
      brandText: 'linear-gradient(134.24deg, #FFA982 0%, #FF5000 70%)',
    };
    this.base.body = {
      bg: this.base.color.neutral100,
      color: this.base.color.neutral600,
    };
    this.base.borderWidth = '0.1875rem';
    this.base.borderColor = this.base.color.neutral200;
  }
  get theme() {
    return {
      ...this.base,
      textColor: this.base.color.neutral600,
      textColorOnPrimary: this.base.color.neutral0,
      textColorMuted: this.base.color.neutral400,
      headings: {
        fontWeight: this.base.fontWeight.bold,
        lineHeight: this.base.lineHeight.reduced,
      },
      link: {
        color: this.base.color.primary,
        decoration: 'none',
        hoverColor: this.base.color.primary,
        fontWeight: this.base.fontWeight.bold,
      },
      wideContentMarginLeft: '-0.3125rem',
      wideContentMarginRight: '-0.3125rem',
      wideContentPaddingLeft: '0.3125rem',
      wideContenPaddingRight: '0.3125rem',
      gridContainer: {
        paddingLeft: '2.1875rem',
        paddingRight: '2.1875rem',
        maxWidth: '30.75rem',
      },
      navBar: {
        bg: this.base.color.neutral0,
        color: 'inherit',
        activeColor: this.base.color.primary,
      },
      meter: {
        bg: this.base.color.neutral300,
      },
      button: {
        fontSize: this.base.fontSize.text,
        fontWeight: this.base.fontWeight.semiBold,
        primColor: this.base.color.neutral0,
        primBg: this.base.gradient.support,
        primFocusBg: this.base.gradient.supportFocus,
        primShadow: '0 2px 2px 0 rgba(0,0,0,0.14)',
        secBg: this.base.color.neutral0,
        secColor: this.base.color.support,
        secShadow: '0 2px 4px 0 #EEEEEE',
        secBorderColor: 'rgba(51,140,143,0.31)',
        secFocusBorderColor: 'rgba(51,140,143,1)',
        tertColor: this.base.color.support,
        tertColorFocus: this.base.color.support500,
        tertFontSize: this.base.fontSize.textBigger,
      },
      paginationBullet: {
        width: '0.625rem',
        height: '0.625rem',
        bg: this.base.color.neutral300,
        bgActive: this.base.color.neutral400,
        spacing: '0.625rem',
      },
      input: {
        fontSize: '16px',
        backgroundColor: this.base.color.neutral0,
        placeholderColor: this.base.color.neutral400,
        borderRadius: this.base.borderRadius,
        borderColor: this.base.color.neutral200,
        focusBorderColor: this.base.color.support,
        boxShadow: `0 1px 4px 0 ${this.base.color.neutral200}`,
      },
      form: {
        fieldSpacing: this.base.spacer,
      },
      account: {
        headerBg: this.base.color.neutral0,
        headerShadow: this.base.boxShadow.neutral,
      },
      zIndex: {
        loadingOverlay: '990',
        navbar: '1000',
        topNav: '1000',
        cartButton: '1010',
        modalBackdrop: '1020',
        modalDialog: '1040',
        popup: '1050',
      },
      modalDialog: {
        bgColor: this.base.color.neutral100,
        borderRadius: '1rem',
      },
      pill: {
        borderRadius: this.base.borderRadius,
        bg: this.base.gradient.support,
        color: this.base.color.neutral0,
      },
      cartButton: {
        bg: this.base.gradient.primary,
        color: this.base.color.neutral0,
      },
      card: {
        bg: this.base.color.neutral0,
        borderRadius: this.base.borderRadiusLg,
        shadow: this.base.boxShadow.neutral,
      },
      popup: {
        bgColor: this.base.color.neutral100,
        borderRadius: this.base.borderRadiusLg,
      },
      badge: {
        fontSize: '0.75rem',
        fontWeight: this.base.fontWeight.semiBold,
        borderRadius: '0.125rem',
        color: this.base.color.neutral0,
        bg: this.base.gradient.primary,
      }
    }
  }
}

const theme = new Theme(vars);
export default theme;

