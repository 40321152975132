import {createSlice} from '@reduxjs/toolkit';
import config from "react-global-configuration";

export const STATUS = {
  ...config.get().fetchStatus,
}

export const callLogSlice = createSlice({
  name: 'callLog',
  initialState: {
    items: null,
    status: STATUS.idle,
    error: null,
  },
  reducers: {
    fetchStart: state => {
      state.status = STATUS.pending;
      state.items = null;
    },
    fetchSuccess: (state, action) => {
      const {data} = action.payload;
      state.status = STATUS.idle;
      state.items = data;
    },
    fetchFail: (state, action) => {
      state.status = STATUS.idle;
      state.error = action.payload.error;
    },
    fetchCancel: state => {
      state.status = STATUS.cancelled;
    }
  },
});

export const {
  fetchStart,
  fetchSuccess,
  fetchFail,
  fetchCancel,
} = callLogSlice.actions;

export const selectStatus = state => state.callLog.status;
export const selectItems = state => state.callLog.items;
export const selectError = state => state.callLog.error;


export default callLogSlice.reducer;

