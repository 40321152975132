
import routesContext from "./RoutesContext";
import React, {useContext} from "react";
import RoutesConfigurator from "./RoutesConfigurator";
import pagesConfig from "../../pagesConfig";
import {useTranslation, createPageContentTranslationKey} from "../i18n/i18n";

/*
  Provider component that wraps the app and makes routes object
  available to any child component that calls useRoutes.
 */
export function ProvideRoutes(props) {
  const {children} = props;
  const routes = useProvideRoutes();
  return <routesContext.Provider value={routes}>{children}</routesContext.Provider>;
}

function useProvideRoutes() {
  const {t, i18n} = useTranslation();
  return new RoutesConfigurator(pagesConfig, {
    t,
    lang: i18n.language,
    createPageContentTranslationKey,
  });
}

export default () => useContext(routesContext);
