import React from 'react';
import Card from "../../components/UI/Card/Card";
import Badge from "../../components/UI/Badge/Badge";
import styled from 'styled-components/macro';

const EsimCompatibility = props => {
  const {data} = props;
  let labelContent, mainContent;
  if (data?.esim_label) {
    labelContent = data.esim_label;
  }

  if (data.esim_content) {
    mainContent = data.esim_content;
  }

  return (
    <Card>
      {labelContent && (
        <BadgeWrap>
          <Badge>{labelContent}</Badge>
        </BadgeWrap>
      )}
      
      <ContentWrap>
        <div dangerouslySetInnerHTML={{__html: mainContent}}/>
      </ContentWrap>
    </Card>
  );
};

const BadgeWrap = styled.div`
  margin-top: 0.625rem;
`

const ContentWrap = styled.div`
  margin-top: 0.625rem;
`

export default EsimCompatibility;
