import React, {createContext, useContext} from "react";
import ReactGA from 'react-ga';
import config from "react-global-configuration";
const trackingId = config.get().gaTrackingId;
if (trackingId) {
  console.log('initialize ReactGa');
  ReactGA.initialize(trackingId);
}

const GaContext = createContext(null);

/*
  Provider component that wraps the app and makes the ga object
  available to any child component that calls useGa().
 */
export function GaProvider(props) {
  const {children} = props;
  return <GaContext.Provider value={ReactGA}>{children}</GaContext.Provider>;
}
/*
  Hook for child components to get the ga object
  and re-render when it changes.
 */
export const useGa = () => {
  return useContext(GaContext);
};



