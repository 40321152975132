import React from 'react';
import styled from 'styled-components/macro';

const MutedText = props => <Wrapper>{props.children}</Wrapper>;

const Wrapper = styled.span`
  color: ${props => props.theme.textColorMuted};
`

export default MutedText;
