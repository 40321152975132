import {createSlice} from '@reduxjs/toolkit';
import config from "react-global-configuration";
import {selectCountries} from "../shippingCountries/shippingCountriesSlice";
import {createSelector} from "reselect";

export const STATUS = {
  ...config.get().fetchStatus,
}

const findFieldAndUpdate = (stateSlice, newField) => {
  const index = stateSlice.findIndex(oldField => oldField.name === newField.name);
  if (index !== -1) {
    stateSlice[index] = {
      ...stateSlice[index],
      ...newField,
    }
  }
  return stateSlice;
}
const INITIAL_STATE = {
  fetchStatus: STATUS.idle,
  totals: null,
  billingFields: null,
  billingInfoComplete: false,
  error: null,
  originKey: null,
  paymentMethods: null,
  deliverToFields: null,
  deliverToComplete: false,
  shippingUserInfo: null,
  paymentError: null,
  paymentStatus: STATUS.idle,
  termsFields: null,
  shipToDifferentAddressFields: null,
  shippingFields: null,
  orderFields: null,
  couponCodeFields: null,
  accountFields: null,
  currency: null,
}


export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState: INITIAL_STATE,
  reducers: {
    reset: () => {
      return {
        ...INITIAL_STATE
      };
    },
    setFragments: (state, action) => {
      state.fragments = action.payload.fragments;
    },
    fetchStart: state => {
      state.fetchStatus = STATUS.pending;
      state.error = null;
      state.paymentError = null;
    },
    fetchEnd: state => {
      state.fetchStatus = STATUS.idle;
    },
    setAllFields: (state, action) => {
      const {data} = action.payload;
      if (!data) {
        return state;
      }

      const {
        totals,
        billing,
        origin_key,
        payment_methods,
        deliver_to,
        terms,
        ship_to_different_address,
        shipping,
        order,
        coupon_code,
        account,
        currency,
      } = data;

      if (totals) {
        state.totals = totals;
      }
      if (billing?.fields) {
        state.billingFields = billing.fields;
      }
      if (billing) {
        state.billingInfoComplete = billing.complete || false;
      }
      if (origin_key) {
        state.originKey = origin_key;
      }
      if (payment_methods) {
        state.paymentMethods = payment_methods;
      } else {
        state.paymentMethods = null;
      }
      if (deliver_to) {
        if (deliver_to.fields) {
          state.deliverToFields = deliver_to.fields;
        }
        state.deliverToComplete = deliver_to.complete || false;
        if (deliver_to.shipping_user_info) {
          state.shippingUserInfo = deliver_to.shipping_user_info;
        }
      } else {
        state.deliverToFields = null;
        state.deliverToComplete = false;
        state.shippingUserInfo = null;
      }

      if (terms?.fields) {
        state.termsFields = terms.fields;
      }
      if (ship_to_different_address?.fields) {
        state.shipToDifferentAddressFields = ship_to_different_address.fields;
      }
      if (shipping?.fields) {
        state.shippingFields = shipping.fields;
      }
      if (order?.fields) {
        state.orderFields = order.fields;
      }
      if (coupon_code?.fields) {
        state.couponCodeFields = coupon_code.fields;
      }
      if (account?.fields) {
        state.accountFields = account.fields;
      } else {
        state.accountFields = null;
      }
      if (currency) {
        state.currency = currency;
      }
    },
    fetchFail: (state, action) => {
      state.fetchStatus = STATUS.idle;
      state.error = action.payload.error;
    },
    fetchCancel: state => {
      state.fetchStatus = STATUS.cancelled;
    },
    updateBillingField: (state, action) => {
      findFieldAndUpdate(state.billingFields, action.payload.field)
    },
    updateShippingField: (state, action) => {
      findFieldAndUpdate(state.shippingFields, action.payload.field)
    },
    updateDeliverToField: (state, action) => {
      findFieldAndUpdate(state.deliverToFields, action.payload.field)
    },
    updateOrderField: (state, action) => {
      findFieldAndUpdate(state.orderFields, action.payload.field)
    },
    updateShipToDifferentAddressField: (state, action) => {
      findFieldAndUpdate(state.shipToDifferentAddressFields, action.payload.field)
    },
    updateCouponCodeField: (state, action) => {
      findFieldAndUpdate(state.couponCodeFields, action.payload.field)
    },
    updateAccountField: (state, action) => {
      findFieldAndUpdate(state.accountFields, action.payload.field)
    },
    updateTermsField: (state, action) => {
      findFieldAndUpdate(state.termsFields, action.payload.field)
    },
    paymentStart: (state) => {
      state.paymentError = null;
      state.paymentStatus = STATUS.pending;
    },
    paymentEnd: (state) => {
      state.paymentStatus = STATUS.idle;
    },
    paymentFail: (state, action) => {
      state.paymentStatus = STATUS.idle;
      state.paymentError = action.payload.message;
    },
    setDeliverToComplete:  (state, action) => {
      state.deliverToComplete = action.payload.complete;
    },
  },
});

export const {
  reset,
  fetchCancel,
  fetchFail,
  fetchEnd,
  fetchStart,
  paymentEnd,
  setAllFields,
  updateBillingField,
  paymentStart,
  paymentFail,
  updateDeliverToField,
  setDeliverToComplete,
  updateShipToDifferentAddressField,
  updateShippingField,
  updateOrderField,
  updateCouponCodeField,
  updateAccountField,
  updateTermsField,
} = checkoutSlice.actions;

export const fetchSuccess = payload => dispatch => {
  dispatch(setAllFields(payload));
  dispatch(fetchEnd());
}

export const selectTotals = state => {
  const {checkout} = state;
  if (checkout.totals) {
    return checkout.totals;
  } else {
    return null;
  }
};
export const selectBillingFields = state => state.checkout.billingFields;
export const selectFetchStatus = state => state.checkout.fetchStatus;
export const selectError = state => state.checkout.error;
export const selectOriginKey = state => state.checkout.originKey;
export const selectPaymentMethods = state => state.checkout.paymentMethods;
export const selectDeliverTo = state => state.checkout.deliverTo;
export const selectPaymentError = state => state.checkout.paymentError;
export const selectPaymentStatus = state => state.checkout.paymentStatus;
export const selectBillingInfoComplete = state => state.checkout.billingInfoComplete;
export const selectDeliverToFields = state => state.checkout.deliverToFields;
export const selectDeliverToComplete = state => state.checkout.deliverToComplete;
export const selectShippingUserInfo = state => state.checkout.shippingUserInfo;
export const selectShipToDifferentAddressFields = state => state.checkout.shipToDifferentAddressFields;
export const selectShippingFields = state => state.checkout.shippingFields;
export const selectOrderFields = state => state.checkout.orderFields;
export const selectCouponCodeFields = state => state.checkout.couponCodeFields;
export const selectAccountFields = state => state.checkout.accountFields;
export const selectTermsFields = state => state.checkout.termsFields;
export const selectCurrency = state => state.checkout.currency;

export const selectCurrentDeliverToCountry = createSelector(
  [selectDeliverToFields, selectCountries],
  (fields, countries) => {
    let countryField;
    if (fields && Array.isArray(fields)) {
      countryField = fields.find(field => field.name === config.get().apiFieldNamesMap.estimate_shipping_country);
      if (countries && Array.isArray(countries)) {
        return countries.find(country => country.code === countryField.value);
      }
    }
  }
)

export default checkoutSlice.reducer;
