import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from "prop-types";
import Icon from "../../Icon/Icon";

const Alert = props => {
  const {type, icon} = props;
  const hasIcon = !!icon;
  return (
    <Wrapper {...{type, hasIcon}}>
      {icon && (
        <IconWrap>
          <Icon name={icon.name}/>
        </IconWrap>
      )}
      <div>
        {props.children}
      </div>
    </Wrapper>
  );
};

export const alertTypes = {
  info: 'info',
  danger: 'danger',
  warning: 'warning',
  success: 'success',
}

const Wrapper = styled.div`
  display: flex;
  border-radius: ${props => props.theme.borderRadius};
  padding: 0.5rem 1rem;
  border: 1px solid ${props => props.theme.color.info};
  background-color: ${props => props.theme.color.infoLighter};
  color: ${props => props.theme.color.infoDarker};
  ${props => props.type === alertTypes.success && `
    border-color: ${props.theme.color.success};
    background-color: ${props.theme.color.successLighter};
    color: ${props.theme.color.successDarker};
  `}
   ${props => props.type === alertTypes.warning && `
    border-color: ${props.theme.color.warning};
    background-color: ${props.theme.color.warningLighter};
    color: ${props.theme.color.warningDarker};
  `}
   ${props => props.type === alertTypes.danger && `
    border-color: ${props.theme.color.dangerLight};
    background-color: ${props.theme.color.dangerLighter};
    color: ${props.theme.color.dangerDarker};
  `}
`

const IconWrap = styled.div`
  margin-left: -0.3125rem;
  padding-right: 0.625rem;
`

Alert.propTypes = {
  messageId: PropTypes.oneOf(['info', 'danger', 'warning', 'success']),
};

export default Alert;
