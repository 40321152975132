import {createSlice} from '@reduxjs/toolkit';
import {createSelector} from 'reselect'
import config from "react-global-configuration";

export const STATUS = {
  ...config.get().fetchStatus,
}

export const countriesSlice = createSlice({
  name: 'countries',
  initialState: {
    countries: [],
    status: STATUS.idle,
    error: null,
  },
  reducers: {
    fetchStart: state => {
      state.status = STATUS.pending;
      state.error = null;
    },
    fetchSuccess: (state, action) => {
      state.status = STATUS.idle;
      if (Array.isArray(action.payload.data)) {
        state.countries = action.payload.data;
      }
    },
    fetchFail: (state, action) => {
      state.status = STATUS.idle;
      state.error = action.payload.error;
    },
    fetchCancel: state => {
      state.status = STATUS.cancelled;
    }
  },
});

export const {
  fetchStart,
  fetchSuccess,
  fetchFail,
  fetchCancel,
} = countriesSlice.actions;

export const selectStatus = state => state.countries.status;
export const selectCountries = state => state.countries.countries;

export const selectCountriesInLabelFormat = createSelector(
  [selectCountries],
  countries => countries.map((country) => {
    return {
      label: country.value,
      value: country.code,
    }
  })
)
export const selectError = state => state.countries.error;
export const selectShouldShowCountriesError = state => {
  let shouldShow = false;
  if (state.countries.error) {
    if (!state.countries.countries || !state.countries.countries.length) {
      shouldShow = true;
    }
  }

  return shouldShow;
}

export default countriesSlice.reducer;
