import config from 'react-global-configuration';

const apiEndpoints = {
  activateDataPlan: '/travelsim/v1/simcard/data',
  appConfig: '/travelsim/v1/app/config',
  authToken: '/jwt-auth/v1/token',
  authTokenRefresh: '/jwt-auth/v1/token/refresh',
  callLog: '/travelsim/v1/simcard/call',
  checkoutFragments: '/travelsim/v1/order/checkout',
  checkVerificationCode: '/travelsim/v1/user/check',
  countries: '/travelsim/v1/country',
  createAccount: '/travelsim/v1/user/complete',
  globalContent: '/travelsim/v1/content',
  locales: '/travelsim/v1/app/strings',
  makePayment: '/travelsim/v1/order',
  page: '/travelsim/v1/page',
  purchaseHistory: '/travelsim/v1/simcard/orders',
  regions: '/travelsim/v1/country/regions',
  reqPasswordReset: '/travelsim/v1/user/forgot',
  reqVerificationCode: '/travelsim/v1/user/register',
  resetPassword: '/travelsim/v1/user/forgot/password',
  shippingCountries: '/travelsim/v1/country/shipping',
  singleCountry: '/travelsim/v1/simcard/rates',
  simConfigOptions: '/travelsim/v1/simcard/product-options',
  topDestinations: '/travelsim/v1/country/top-destination',
  topupOptions: '/travelsim/v1/simcard/topup-options',
  userSimCards: '/travelsim/v1/simcard',
  user: '/travelsim/v1/user',
  apiManifest: '/travelsim/v1/app/revisions',
}

const apiEndpointsWithUrl = {...apiEndpoints};
Object.keys(apiEndpointsWithUrl).forEach(key => {
  apiEndpointsWithUrl[key] = process.env.REACT_APP_API_URL + apiEndpointsWithUrl[key];
})

config.set(
  {
    appVersion: process.env.REACT_APP_VERSION,
    showDevUtils: process.env.REACT_APP_SHOW_DEV_UTILS === 'true',
    gaTrackingId: process.env.REACT_APP_GA_TRACKING_ID,
    gtmId: process.env.REACT_APP_GTM_ID,
    i18n: {
      debug: process.env.REACT_APP_I18N_DEBUG === 'true',
    },
    showErrors: process.env.REACT_APP_DEBUG === 'true',
    purgeStateOnError: process.env.REACT_APP_PURGE_STATE_ON_ERROR === 'true',
    apiUrl: process.env.REACT_APP_API_URL,
    pwaUpdateSnoozeTime: parseInt(process.env.REACT_APP_PWA_UPDATE_SNOOZE_TIME),
    iOSAddToHomeScreenSnoozeTime: 48 * 60 * 60 * 1000, // 48h
    apiManifestPollInterval: 5 * 60 * 1000, // 5 minutes
    apiManifestInteractionTimeout: 5 * 60 * 1000, // we stop polling if the user has not interacted with the app for that long
    myCardsPollInterval: 20000,
    countriesPollInterval: 24 * 60 * 60 * 1000, // 24h
    regionsPollInterval: 24 * 60 * 60 * 1000, // 24h
    tokenRefreshTimeWindow: 2 * 60 * 1000, // refresh 2 minutes before expiry
    apiEndpoints,
    apiEndpointsWithUrl,
    i18nLocalStorageBackendPrefix: 'i18next_res_',
    adyen: {
      env: process.env.REACT_APP_ADYEN_ENV,
    },
    globalContentBlockIds: {
      faq: 'faq',
      esimInfo: 'esim_info',
      esimCompatibility: 'esim_compatibility',
      contact: 'contact',
      globalEsimFaq: 'esim_faq',
    },
    simTypes: {
      esim: 'esim',
      travelsim: 'travelsim',
    },
    fetchStatus: {
      idle: 'idle',
      pending: 'pending',
      cancelled: 'cancelled',
    },
    // the list of shipping countries will be injected to all the selects that have the following names:
    shippingCountrySelectNames: ['estimate_shipping_country', 'billing_country', 'shipping_country'],
    apiFieldNamesMap: {
      billing_first_name: 'billing_first_name',
      billing_last_name: 'billing_last_name',
      shipping: 'ship_to_different_address',
      terms_accepted: 'terms_accepted',
      estimate_shipping_country: 'estimate_shipping_country',
      coupon_code: 'coupon_code',
    },
    productTypes: {
      newTravelsim: 'newTravelsim',
      newEsim: 'newEsim',
      rechargeTravelsim: 'rechargeTravelsim',
      rechargeESim: 'rechargeESim',
    },
    productIds: [
      {
        id: 1226,
        type: 'newTravelsim',
      },
      {
        id: 146351,
        type: 'newEsim',
      },
      {
        id: 11307,
        type: 'rechargeTravelsim',
      },
      {
        id: 146358,
        type: 'rechargeESim',
      }
    ],
    languages: [
      {
        "code": "en",
        "name": "English",
        "shortName": "EN",
      },
      {
        "code": "fr",
        "name": "Français",
        "shortName": "FR",
      },
      {
        "code": "de",
        "name": "Deutsch",
        "shortName": "DE",
      },
      {
        "code": "it",
        "name": "Italiano",
        "shortName": "IT",
      },
      {
        "code": "es",
        "name": "Español",
        "shortName": "ES",
      },
      {
        "code": "id",
        "name": "Indonesia",
        "shortName": "ID",
      }
    ],
    currencies: [
      {
        "code": "USD",
        "symbol": "$"
      },
      {
        "code": "EUR",
        "symbol": "€",
        "default": true
      },
      {
        "code": "CAD",
        "symbol": "$"
      },
      {
        "code": "AUD",
        "symbol": "$"
      },
      {
        "code": "GBP",
        "symbol": "£"
      }
    ]
  }
);
