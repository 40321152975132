import React from 'react';
import {availableBlocks} from "./availableBlocks";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import Section from "../../components/Section/Section";
import GridContainer from "../../components/GridContainer/GridContainer";

const ConditionalContainerProvider = props => {
  const {render, shouldWrap} = props;

  if (shouldWrap) {
    return (
      <GridContainer>
        {render(props)}
      </GridContainer>
    )
  } else {
    return render(props);
  }
}

const ConditionalSectionProvider = props => {
  const {render, shouldWrap} = props;

  if (shouldWrap) {
    return (
      <Section>
        {render(props)}
      </Section>
    )
  } else {
    return render(props);
  }
}


const ContentBuilder = props => {
  const {pageId} = props;

  let {blocks = []} = props;
  if (pageId) {
    blocks = [
      {
        name: availableBlocks.pageBlocks.name,
        data: {
          pageId: pageId,
        }
      }
    ];
  }

  let builtBlocks;
  builtBlocks = blocks.map((block, index) => {
    if (!block || !block.name) {
      return undefined;
    }
    const blockConfig = availableBlocks[block.name];
    if (blockConfig) {
      const Block = blockConfig.component;
      /*
      this kind of nesting might look funny, but we can't use HOC inside
      the render function (functional component is the render function)
      https://reactjs.org/docs/higher-order-components.html#dont-use-hocs-inside-the-render-method
      */
      return (
        <ConditionalSectionProvider
          key={index}
          shouldWrap={block.wrapInSection}
          render={() => (
            <ConditionalContainerProvider
              shouldWrap={block.inContainer}
              render={() => <Block data={block.data} {...blockConfig.props}/>}
            />
          )}
        />
      );
    }
    return undefined;
  })


  return (
    <ErrorBoundary>
      {builtBlocks}
    </ErrorBoundary>
  );
};

export default ContentBuilder;
export {availableBlocks};
