import {createGlobalStyle} from 'styled-components/macro';
import {normalize} from './normalize';

const GlobalStyle = createGlobalStyle`
  ${normalize}
  
  html {
    font-size: 16px;
  }
  
  body {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: ${props => props.theme.body.bg};
    font-family: ${props => props.theme.fontFamily.primary};
    font-size: ${props => props.theme.fontSize.text};
    color: ${props => props.theme.body.color};
    line-height: ${props => props.theme.lineHeight.base};
  }
  
  h1,
  h2,
  h3 {
    line-height: ${props => props.theme.headings.lineHeight};
    font-weight: ${props => props.theme.headings.fontWeight};
  }
  
  h1 {
    font-size: ${props => props.theme.fontSize.h1};
    margin-bottom: 1.75rem;
  }
  
  h2 {
    font-size: ${props => props.theme.fontSize.h2};
  }
  
  h3 {
    font-size: ${props => props.theme.fontSize.h3};
  }
  
  a {
    color: ${props => props.theme.link.color};
    font-weight: ${props => props.theme.link.fontWeight};
    text-decoration: ${props => props.theme.link.decoration};
  }
  
  p {
    margin-top: 0;
    margin-bottom: 1em;
  }
`

export default GlobalStyle;
