import {createSlice} from '@reduxjs/toolkit';
import {createSelector} from 'reselect'
import config from "react-global-configuration";
import {selectCountries} from "../countries/countriesSlice";

export const STATUS = {
  ...config.get().fetchStatus,
}

export const regionsSlice = createSlice({
  name: 'regions',
  initialState: {
    regions: [],
    currentRegionName: null,
    status: STATUS.idle,
    error: null,
  },
  reducers: {
    fetchStart: state => {
      state.status = STATUS.pending;
      state.error = null;
    },
    fetchSuccess: (state, action) => {
      state.status = STATUS.idle;
      state.regions = action.payload.data;
      if (action.payload.data[0]) {
        state.currentRegionName = action.payload.data[0].name;
      }
    },
    fetchFail: (state, action) => {
      state.status = STATUS.idle;
      state.error = action.payload.error;
    },
    fetchCancel: state => {
      state.status = STATUS.cancelled;
    },
    setCurrentRegionName: (state, action) => {
      state.currentRegionName = action.payload.regionName;
    }
  },
});


export const {
  fetchStart,
  fetchSuccess,
  fetchFail,
  fetchCancel,
  setCurrentRegionName,
} = regionsSlice.actions;

export const selectStatus = state => state.regions.status;
export const selectRegions = state => state.regions.regions;
export const selectCurrentRegionName = state => state.regions.currentRegionName;

export const selectError = state => state.regions.error;
export const selectRegionsWithCountries = createSelector(
  [selectRegions, selectCountries],
  (regions, countries) => regions.map((region) => {
    if (!regions || !countries || !regions.length || !countries.length) {
      return [];
    }
    return {
      ...region,
      countries: region.countries.map(countryCode => countries.find(country => country.code === countryCode))
    }
  })
)
export const selectCurrentRegion = createSelector(
  [selectRegionsWithCountries, selectCurrentRegionName],
  (regions, currentRegionName) => {
    return regions.find(region => region.name === currentRegionName);
  }
)

export default regionsSlice.reducer;
