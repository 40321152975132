import React from 'react';
import useRoutes from "../routing/useRoutes";
import MainTitle from "../../components/MainTitle/MainTitle";
import {useHistory, useRouteMatch} from "react-router-dom";
import SelectCountry from "../../components/UI/SelectCountry/SelectCountry";
import {useTranslation} from '../i18n/i18n';
import RatesAndCoverageSingle from './RatesAndCoverageSingle';
import GridContainer from "../../components/GridContainer/GridContainer";
import ControlLink from "../../components/UI/ControlLink/ControlLink";
import styled from 'styled-components/macro';
import CountriesByRegion from "../../components/UI/CountriesByRegion/CountriesByRegion";

const RatesAndCoverage = props => {
  const {t} = useTranslation();
  const routes = useRoutes();
  const history = useHistory();
  const parentMatch = useRouteMatch();
  const countryMatch = useRouteMatch(parentMatch.path + '/:country');
  const ratesRoute = routes.getRoute(routes.customIds.RATES_AND_COVERAGE);
  const {inModal, setModalData, modalData} = props;
  const handleCountryClick = (country) => {
    if (inModal) {
      setModalData({country: country.code});
    } else {
      // remove trailing slash
      const baseUrl = parentMatch.url.replace(/\/$/, '');
      history.push(baseUrl + '/' + country.code);
    }
  }

  let content = (
    <>
      <ContentWrap>
        <GridContainer>
          <SelectCountry
            onChange={(option, action) => {
              if (action.action === 'select-option' && option.value) {
                handleCountryClick({code: option.value});
              }
            }}
          />
        </GridContainer>
      </ContentWrap>
      <GridContainer>
        <SectionLabel>
          {t('calls_text_and_data_plans_available')}
        </SectionLabel>
      </GridContainer>

      <CountriesByRegion
        onClick={handleCountryClick}
      />
    </>
  )

  let activeCountry;
  if (inModal && modalData) {
    activeCountry = modalData.country;
  } else if (countryMatch && countryMatch.isExact) {
    activeCountry = countryMatch.params.country;
  }

  if (activeCountry) {
    content = (
      <RatesAndCoverageSingle country={activeCountry}/>
    );
  }

  let backButton;
  if (inModal && activeCountry) {
    backButton = (
      <BackButtonWrap>
        <ControlLink
          type="caretLeft"
          onClick={e => {
            e.preventDefault();
            setModalData({country: null});
          }}
        >
          {t('all_countries')}
        </ControlLink>
      </BackButtonWrap>
    )
  }

  //https://github.com/JedWatson/react-select/issues/3128
  return (
    <div>
      {backButton}
      <GridContainer>
        <MainTitle>{ratesRoute.title}</MainTitle>
      </GridContainer>

      {content}

    </div>
  );
};

const BackButtonWrap = styled.div`
  position: absolute;
  top: 1.25rem;
  left: ${({theme}) => theme.gridContainer.paddingLeft};
`

const SectionLabel = styled.div`
  margin-bottom: 0.5rem;
`

const ContentWrap = styled.div`
  margin-bottom: 1.25rem;
`


export default RatesAndCoverage;
