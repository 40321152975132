import React from 'react';
import templates from "./templates"

/*
  The Page component is responsible for composing the Layouts and Templates and also for holding their shared state.
  Shared state is needed because for example,
  some Templates might want to change the visibility of some layout elements.
 */
const Page = props => {
  const {pageData} = props;
  const {Template} = templates[pageData.template];
  return (
    <Template {...props}/>
  )
}

export default Page;
