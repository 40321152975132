import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import useFetchData from "../../hooks/useFetchData";
import config from "react-global-configuration";
import MutedText from "../../components/MutedText/MutedText";
import Button from "../../components/UI/Button/Button";
import Popup from "../popup/Popup";
import styled from 'styled-components/macro';
import Meter from "../../components/UI/Meter/Meter";
import SystemMessage from "../systemMessages/SystemMessage";
import {useSystemMessages} from "../systemMessages/useSystemMessages";
import formatTravelsimNumber from "../../util/formatTravelsimNumber";

const DataSection = props => {
  const {t} = useTranslation();
  const {dataPackage, card} = props;
  let packageStatus = dataPackage.status;
  const [activationConfirmation, setActivationConfirmation] = useState(null);
  const systemMessages = useSystemMessages();

  const {state, setConfig} = useFetchData({
    endpoint: config.get('apiEndpoints.activateDataPlan'),
    useAuthToken: true,
    enabled: false,
    method: 'post',
  });

  if (state.data) {
    packageStatus = 'active';
  }

  const handleActivateDataClick = (card, dataPackage) => {
    setActivationConfirmation({
      type: card.type,
      number: card.number,
      plan: dataPackage.plan,
      billing_id: dataPackage.billing_id,
    });
  }

  const cancelActivation = () => {
    setActivationConfirmation(null);
  }

  const confirmActivation = () => {
    if (!activationConfirmation) {
      return;
    }
    const {number, billing_id} = activationConfirmation;
    const formData = new FormData();
    formData.append('number', number);
    formData.append('billing_id', billing_id);
    setConfig(prevState => ({
      ...prevState,
      formData,
      enabled: true,
    }))
    setActivationConfirmation(null);
  }

  const shouldShowConfirmationPopup = activationConfirmation !== null;
  let popupTitle;
  if (activationConfirmation) {
    popupTitle = t('activate_data_for', {
      plan: activationConfirmation.plan,
      type: activationConfirmation.type,
      number: formatTravelsimNumber(activationConfirmation.number),
    })
  }

  return (
    <StatsSection>
      <Popup
        show={shouldShowConfirmationPopup}
        onCloseClick={cancelActivation}
        onHide={cancelActivation}
        title={popupTitle}
      >
        <ButtonGroup>
          <ButtonGroupItem>
            <Button
              noMargins
              fullWidth={false}
              size="sm"
              variation="sec"
              onClick={cancelActivation}
            >
              {t('button.cancel')}
            </Button>
          </ButtonGroupItem>
          <ButtonGroupItem>
            <Button
              noMargins
              fullWidth={false}
              size="sm"
              onClick={confirmActivation}
            >
              {t('button.activate')}
            </Button>
          </ButtonGroupItem>
        </ButtonGroup>
      </Popup>

      <StatsRow alignItems="flex-end" status={packageStatus}>
        <StatsRowItem type="first">
          <StatValue>{dataPackage.remaining} {dataPackage.unit}</StatValue>
        </StatsRowItem>
        <StatsRowItem>
          <MutedText>{t('card_stats.plan')}: {dataPackage.plan}</MutedText>
        </StatsRowItem>
      </StatsRow>

      <StatsRow alignItems="flex-start" status={packageStatus}>
        <StatsRowItem type="first">
          <MutedText>{t('card_stats.data_remaining', {amount: dataPackage.remaining})}</MutedText>
        </StatsRowItem>
        <StatsRowItem>
          {dataPackage.total && (
            <StyledMeter value={dataPackage.remaining / dataPackage.total}/>
          )}
        </StatsRowItem>
      </StatsRow>

      {state.data && (
        <SystemMessage
          spacingTop
          spacingBottom
          message={systemMessages.messages.sm_data_activation_success}
        />
      )}
      <SystemMessage
        spacingTop
        spacingBottom
        message={state.errorMessage}
      />

      {packageStatus === 'inactive' && (
        <PackageButtonHolder>
          <SystemMessage spacingBottom messageId={state.errorMessage}/>
          <Button
            icon={state.isLoading ? 'Spinner' : undefined}
            disabled={state.isLoading}
            noMargins variation="sec"
            onClick={() => {
              handleActivateDataClick(card, dataPackage);
            }}
          >
            {t('button.activate_data_plan')}
          </Button>
        </PackageButtonHolder>
      )}
    </StatsSection>
  )
}

const StatsSection = styled.div`
  margin-top: 1.25rem;

  &:first-child {
    margin-top: 0;
  }
`

const StatsRow = styled.div`
  display: flex;
  align-items: ${props => props.alignItems};
  opacity: ${props => props.status === 'inactive' ? 0.5 : 1};
`

const StatsRowItem = styled.div`
  width: 9.0625rem;
  text-align: right;
  flex-shrink: 0;
    
  @media (min-width: 360px) {
    width: 10.6875rem;
    ${props => props.type === 'first' && `
      width: 100%;
    `};
  }
  
  ${props => props.type === 'first' && `
    width: 100%;
    padding-right: 0.625rem;
    text-align: left;
    flex-shrink: 1;
  `};
`

const StatValue = styled.div`
  font-weight: ${props => props.theme.fontWeight.bold};
  font-size: ${props => props.type === 'small' ? '1.1875rem' : '1.5625rem'};
  margin-bottom: ${props => props.type === 'small' ? '0.125rem ' : '0'};
  line-height: 1.1;
`

const StyledMeter = styled(Meter)`
  margin-top: 0.3125rem;
`

const PackageButtonHolder = styled.div`
  margin-top: 1.25rem;
  margin-bottom: 1.875rem;
`

const ButtonGroup = styled.div`
  display: flex;
  margin-left: -7px;
  margin-right: -7px;
  flex-wrap: wrap;
  justify-content: flex-end;
`
const ButtonGroupItem = styled.div`
  padding: 7px;
`

export default DataSection;
