import React, {useEffect} from 'react';
import Bowser from "bowser";
import isInStandaloneMode from "../../util/isInStandaloneMode";
import styled from "styled-components/macro";
import Icon from "../../components/Icon/Icon";
import {Snackbar, SnackbarContent} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {resetButtonStyles} from "../../styles/mixins";
import {Trans, useTranslation} from "../i18n/i18n";
import {iOSDismiss, iOSMaybeShow, selectIOSShouldShow} from "./addToHomeScreenSlice";
import {useDispatch, useSelector} from "react-redux";
import history from "../routing/history";

const ShareIcon = () => <IconWrap><Icon name="IosShare"/></IconWrap>;

const IOSAddToHomeScreen = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const shouldShow = useSelector(selectIOSShouldShow);
  const browser = Bowser.getParser(window.navigator.userAgent);
  const isMobileIOS = browser.satisfies({
    mobile: {
      safari: '>=11.3',
    },
    tablet: {
      safari: '>=11.3',
    },
  });

  const shouldShowTheNotification = (
    isMobileIOS &&
    !isInStandaloneMode &&
    'serviceWorker' in navigator &&
    shouldShow
  );

  let content = (
    <>
      <Trans
        i18nKey="add_to_home_screen_ios"
        defaults="Install this app on your device: tap<icon/>and then 'Add to Home Screen'"
        components={{ icon: <ShareIcon/>}}
      />
    </>
  );

  const handleDismiss = () => {
    dispatch(iOSDismiss());
  }

  useEffect(() => {
    history.listen(() => {
      dispatch(iOSMaybeShow());
    });
  }, [dispatch])

  return (
    <>
      <Snackbar
        open={shouldShowTheNotification}
      >
        <StyledSnackBarContent
          message={content}
          action={
            <>
              <TextButton
                onClick={handleDismiss}
              >
                {t('button.later')}
              </TextButton>
            </>
          }
        />
      </Snackbar>
    </>
  );
};

const TextButton = styled.button`
  ${resetButtonStyles};
  color: inherit;
  margin-left: 1rem;
  padding: 0 8px;
  text-transform: uppercase;
  font-weight: ${props => props.theme.fontWeight.semiBold};
`

const IconWrap = styled.div`
  display: inline-block;
  vertical-align: middle;
  color: ${props => props.theme.color.iOSSystemBlue};
  margin: -7px 5px 0 5px;
`

const StyledSnackBarContent = withStyles({
  root: {
    backgroundColor: 'rgb(255, 255, 255)',
    color: 'currentColor',
  },
})(SnackbarContent);

export default IOSAddToHomeScreen;
