import React from 'react';
import CustomList from "../../components/UI/CustomList/CustomList";

const EsimInfo = props => {
  const {data} = props;

  let listItems;

  if (data.esim_info && Array.isArray(data.esim_info)) {
    listItems = data.esim_info.map((item, index) => (
      <div key={index}>{item.item}</div>
    ));
  }
  return (
    <>
      <CustomList>
        {listItems}
      </CustomList>
    </>
  );
};

export default EsimInfo;
