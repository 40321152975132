import React from 'react';
import styled from 'styled-components/macro';
import CountryFlag from "../CountryFlag/CountryFlag";

const ChoiceButton = props => {
  const {children, name, id, countryFlag, ...restProps} = props;
  return (
    <Wrapper
      countryFlag={countryFlag}
    >
      {countryFlag && (
        <Flag countryCode={countryFlag}/>
      )}
      <Input
        type="radio"
        {...{name, id}}
        {...restProps}
      />
      <FakeButton htmlFor={id}>
        <Indicator/>
        <TextContent>{children}</TextContent>
      </FakeButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  
  & + & {
    margin-top: ${props => props.theme.spacer};
  }
`

const Input = styled.input`
  display: none;
  position: absolute;
`

const Indicator = styled.div`
  width: 1.0625rem;
  height: 1.0625rem;
  border: 0.125rem solid ${props => props.theme.color.support};
  background-color: transparent;
  border-radius: 100%;
  position: relative;

  &:before {
    content: "";
    display: block;
    width: 0.5625rem;
    height: 0.5625rem;
    background-color: transparent;
    border-radius: 100%;
    position: absolute;
    top: 0.125rem;
    left: 0.125rem;
  }
`

const FakeButton = styled.label`
  display: flex;
  align-items: center;
  padding: 0.75rem 1.125rem;
  background-color: ${props => props.theme.button.secBg};;
  border: 1px solid rgba(49,49,49,0.31);
  cursor: pointer;
  font-size: ${props => props.theme.fontSize.textBigger};
  font-weight: ${props => props.theme.button.fontWeight};
  transition: ${props => props.theme.transitionBaseDuration};
  border-radius: 50px;
  box-shadow: ${props => props.theme.button.secShadow};
  ${Input}:checked ~ & {
    color: ${props => props.theme.color.support};
    box-shadow: ${props => props.theme.boxShadow.support};
    border-color: rgba(51,140,143,0.31);
  }
  ${Input}:checked ~ & ${Indicator}:before {
    background-color: ${props => props.theme.color.support};
  }
`

const TextContent = styled.div`
  padding-left: 0.625rem;
`

const Flag = styled(CountryFlag)`
  font-size: 20px;
  position: absolute;
  left: 7px;
  top: 6px;
`


export default ChoiceButton;
