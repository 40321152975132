import React from 'react';
import Alert, {alertTypes} from "../../components/UI/Alert/Alert";
import {useSystemMessages} from './useSystemMessages';
import styled from 'styled-components/macro';
import TryAgainButton from "./TryAgainButton";
import {Trans} from "../i18n/i18n";
import {Link} from "react-router-dom";
import useRoutes from "../routing/useRoutes";

const SystemMessage = props => {
  const routes = useRoutes();
  const systemMessages = useSystemMessages();
  const {spacingTop, spacingBottom, message, tryAgain} = props;
  if (!message) {
    return <div/>;
  }
  let {code, text, details, type = 'info'} = message;
  let mainContent;
  let detailsContent;

  if (Array.isArray(details) && details.length) {
    detailsContent = (
      <ul>
        {(
          details.map((detail, index) => (
            <li key={index}>{detail}</li>
          ))
        )}
      </ul>
    )
  } else if (details) {
    detailsContent = details;
  }

  let alertType = type;
  let icon;

  if (code && systemMessages.messages[code]) {
    const sysMessage = systemMessages.messages[code];
    mainContent = sysMessage.text;
    alertType = sysMessage.type;
    icon = sysMessage.icon;

    if (code === systemMessages.messages.account_exists_error.id) {
      mainContent = <Trans i18nKey={systemMessages.messages.account_exists_error.id}>
        An account is already registered with your email address. Please
        <Link to={routes.linkTo.LOG_IN}> log in</Link>.
      </Trans>
    }
  } else if (text) {
    mainContent = text;
  }


  if (!mainContent) {
    mainContent = systemMessages.messages['sm_default_error'].text;
    alertType = systemMessages.messages['sm_default_error'].type;
  }

  if (alertType === 'error') {
    alertType = alertTypes.danger;
  }

  return (
    <Wrapper {...{spacingTop, spacingBottom}}>
      <Alert type={alertType} icon={icon}>
        {mainContent}
        {detailsContent}
      </Alert>

      {tryAgain && typeof tryAgain === 'function' && (
        <TryAgainButton onClick={tryAgain}/>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${props => props.spacingTop && `margin-top: 1rem;`}
  ${props => props.spacingBottom && `margin-bottom: 1rem;`}
`

export default SystemMessage;
