import React from 'react';
import parse, { domToReact } from 'html-react-parser';
import {Link} from "react-router-dom";

const Content = props => {
  const {data} = props;
  const {content} = data;

  const contentWithLinks = parse(content, {
    replace: ({attribs, children}) => {
      if (attribs && attribs.href && attribs.href.includes('app://')) {
        const to = attribs.href.replace('app://', '');
        return (<Link to={to}>{domToReact(children)}</Link>);
      }
    }
  });

  return (
    <div>
      {contentWithLinks}
    </div>
  );
};

export default Content;
