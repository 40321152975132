import React from 'react';
import styled from 'styled-components/macro';

const OptionButton = props => {
  const {children, name, id, subLabel, wrap, ...restProps} = props;
  let additionalLabel;
  if (subLabel) {
    additionalLabel = <SubLabel>{subLabel}</SubLabel>;
  }
  return (
    <Wrapper>
      <Input
        type="checkbox"
        {...{name, id}}
        {...restProps}
      />
      <FakeButton htmlFor={id} $wrap={wrap}>
        <Text>
          {children}
          {additionalLabel}
        </Text>
      </FakeButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
`

const SubLabel = styled.div`
  font-weight: ${({theme}) => theme.fontWeight.normal};
  color: ${({theme}) => theme.color.neutral400};
  margin-top: 0.1875rem;
`

const Input = styled.input`
  display: none;
  position: absolute;
`

const FakeButton = styled.label`
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0.625rem 1.25rem;
  line-height: 1.1;
  background: ${props => props.theme.button.secBg};
  cursor: pointer;
  box-shadow: ${props => props.theme.boxShadow.neutral};
  border-radius: 0.375rem;
  font-size: ${props => props.theme.fontSize.textBigger};
  font-weight: ${props => props.theme.button.fontWeight};
  white-space: ${props => props.$wrap ? 'wrap' : 'nowrap'};
  height: 100%;
  justify-content: center;

  ${Input}:checked ~ & {
    color: ${props => props.theme.color.neutral0};
    background: ${props => props.theme.gradient.support};
    
    ${SubLabel} {
      color: inherit;
    }
  }
`

const Text = styled.div`
  user-select: none;
`

export default OptionButton;
