import {createSlice} from '@reduxjs/toolkit';
import config from "react-global-configuration";

export const STATUS = {
  ...config.get().fetchStatus,
}

const findI18nManifestEntry = manifest => {
  if (!manifest) {
    return;
  }
  const i18nEndpoint = config.get().apiEndpoints.locales;
  return manifest.find(item => {
    let found = false;
    if (!item.url || !item.revision) {
      found = false;
    } else if (item.url.includes(i18nEndpoint) || i18nEndpoint.includes(item.url)) {
      found = true;
    }
    return found;
  });
}

export const apiManifestSlice = createSlice({
  name: 'apiManifest',
  initialState: {
    manifest: [],
    i18nRevision: null,
    status: STATUS.idle,
    error: null,
  },
  reducers: {
    fetchStart: state => {
      state.status = STATUS.pending;
      state.error = null;
    },
    fetchSuccess: (state, action) => {
      state.status = STATUS.idle;
      if (Array.isArray(action.payload.data)) {
        state.manifest = action.payload.data;
      }
    },
    fetchFail: (state, action) => {
      state.status = STATUS.idle;
      state.error = action.payload.error;
    },
    fetchCancel: state => {
      state.status = STATUS.cancelled;
    },
    setI18nUpdated: state => {
      const manifestEntry = findI18nManifestEntry(state.manifest);
      if (manifestEntry) {
        state.i18nRevision = manifestEntry.revision;
      }
    }
  },
});

export const {
  fetchStart,
  fetchSuccess,
  fetchFail,
  fetchCancel,
  setI18nUpdated,
} = apiManifestSlice.actions;

export const selectStatus = state => state.apiManifest.status;
export const selectManifest = state => state.apiManifest.manifest;
export const selectShouldReloadI18nResources = state => {
  const {i18nRevision, manifest} = state.apiManifest;
  let shouldReload = false;

  if (!manifest) {
    return;
  }
  const manifestEntry = findI18nManifestEntry(manifest);

  if (manifestEntry && manifestEntry.revision !== i18nRevision) {
    shouldReload = true;
  }

  return shouldReload;
}

export default apiManifestSlice.reducer;
