import React from 'react';
import Collapsible from "react-collapsible";
import styled from 'styled-components/macro';

const Collapse = props => {
  const {children, header, ...restProps} = props;
  return (
    <Wrapper>
      <Collapsible
        trigger={(
          <CollapseHeader>
            {header}
          </CollapseHeader>
        )}
        transitionTime={300}
        easing="ease-in-out"
        {...restProps}
      >
        {children}
      </Collapsible>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border-color: ${props => props.theme.borderColor};
  border-width: ${props => props.theme.borderWidth};
  border-bottom-style: solid;
  margin-left:  ${props => props.theme.wideContentMarginLeft};
  margin-left:  ${props => props.theme.wideContentMarginRight};
  
  & & {
    border: 0;
    margin: 0;
  }
 
  &:first-child {
    border-top-style: solid;
  }
  
  .Collapsible__trigger {
    display: block;
    padding: 1.0625rem 1.5rem 0.9375rem 0.9375rem;
    position: relative;
    cursor: pointer;
    
    &:after {
      content: "";
      display: block;
      position: absolute;
      right: 0.625rem;
      top: 50%;
      margin-top: -0.25rem;
      width: 0; 
      height: 0; 
      border-top: 0.25rem solid transparent;
      border-bottom: 0.25rem solid transparent; 
      border-right: 0.25rem solid currentColor; 
    }
    
    &.is-open:after {
      transform: rotateZ(-90deg);
    }
  }
  
  .Collapsible__contentOuter {
    padding: 0 0.9375rem; 
  }
  
  .Collapsible__contentInner {
    padding-bottom: 0.9375rem;
  }
  
  .Collapsible__contentOuter .Collapsible__contentOuter {
    padding: 0;
  }
  
  .Collapsible__contentOuter .Collapsible__trigger {
    padding-left: 0;
    margin-right: -0.9375rem;
  }
 
  
  .is-open {
    background-color: ${props => props.theme.color.neutral0};
  }
  .is-open ~ .Collapsible__contentOuter {
    background-color: ${props => props.theme.color.neutral0};
  }
`

const CollapseHeader = styled.div`
  font-size: ${props => props.theme.fontSize.textBigger};
  font-weight: ${props => props.theme.fontWeight.semiBold};
`


export default Collapse;
