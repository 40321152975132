// https://github.com/axios/axios/issues/383#issuecomment-234079506

export default (code) => {
  let result = code;

  let formattedString = code.trim().toLowerCase().replace(/\s/g, '');
  if (formattedString === 'networkerror') {
    result = 'sm_network_error';
  }

  return result;
}
