import React from 'react';
import {selectCountriesInLabelFormat, selectStatus, STATUS} from "../../../features/countries/countriesSlice";
import Select from "../Select/Select";
import {useTranslation} from "../../../features/i18n/i18n";
import {useSelector} from "react-redux";

const SelectCountry = props => {
  const {t} = useTranslation();
  const countriesStatus = useSelector(selectStatus);
  const countries = useSelector(selectCountriesInLabelFormat);
  return (
    <Select
      ignoreAccents={false}
      options={countries}
      noOptionsMessage={() => countriesStatus === STATUS.pending ? t('loading') : t('no_options')}
      placeholder={t('search_for_country')}
      {...props}
    />
  );
};

export default SelectCountry;
