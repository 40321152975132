import React, {useState} from 'react';
import useFetchData from "../../hooks/useFetchData";
import config from "react-global-configuration";
import {Trans, useTranslation} from '../i18n/i18n';
import GridContainer from "../../components/GridContainer/GridContainer";
import styled, {css} from 'styled-components/macro';
import Spinner from "../../components/UI/Spinner/Spinner";
import {useSelector} from "react-redux";
import CountryFlag from "../../components/UI/CountryFlag/CountryFlag";
import SystemMessage from "../systemMessages/SystemMessage";
import RatesTable from "./RatesTable";
import DataPackagesTable from "./DataPackagesTable";
import {Link} from "react-router-dom";
import useRoutes from "../routing/useRoutes";

const RatesAndCoverageSingle = props => {
  const country = useSelector((state) => {
    return state.countries.countries.find(country => country.code === props.country);
  });
  const routes = useRoutes();
  const [activeTab, setActiveTab] = useState(0);
  const {t} = useTranslation();
  const {state} = useFetchData({
    endpoint: config.get('apiEndpoints.singleCountry'),
    queryObject: {
      country: props.country
    },
    useCurrency: true,
    enabled: true,
  });
  const {data, isLoading} = state;

  let tabsData = [];

  if (data) {
    tabsData = [
      {
        ...data.travelsim,
        key: 'travelsim',
        title: t('rates.travelsim_rates')
      },
      {
        ...data.esim,
        key: 'esim',
        title: t('rates.esim_rates')
      }
    ]
  }

  const tabsContent = tabsData.map((tabData, index) => {
    const {key, call_in, call_out, sms, data, data_package} = tabData;
    const shouldShowDataPlans = !!(data_package && data_package.length);
    return (
      <TabContent key={key} active={index === activeTab}>
        <StyledRatesTable
          callIn={call_in}
          callOut={call_out}
          sms={sms}
          data={data}
        />

        {shouldShowDataPlans && (
          <>
            <TableTitle>{t('rates.data_plans')}</TableTitle>
            <StyledDataPackagesTable dataPackages={data_package}/>
          </>
        )}
      </TabContent>
    )
  });

  const tabNavButtons = tabsData.map((tabData, index) => {
    return (
      <div key={tabData.key}>
        <TabNavButton
          onClick={() => setActiveTab(index)}
          active={index === activeTab}
        >
          {tabData.title}
        </TabNavButton>
      </div>
    )
  });

  return (
    <div>
      <GridContainer>
        <SystemMessage spacingBottom message={state.errorMessage}/>
        {country && (
          <CountryHeader>
            <FlagWrap>
              <CountryFlag countryCode={country.code}/>
            </FlagWrap>
            <CountryName>{country.value}</CountryName>
          </CountryHeader>
        )}
        {!data && isLoading && (
          <Loader>
            <Spinner/>
          </Loader>
        )}

        {/*
          TODO: the designs did not account for multiple carriers, so, this will be implemented in the next phase
          <Table>
            <tbody>
            <tr>
              <td>{t('rates.operator')}</td>
              <td>asdfaf</td>
            </tr>
            <tr>
              <td>{t('rates.network')}</td>
              <td>asdfasfda</td>
            </tr>
            <tr>
              <td>{t('rates.calls_and_texts')}</td>
              <td>asdfasdfasfd</td>
            </tr>
            <tr>
              <td>{t('rates.data')}</td>
              <td>asfasdfasfd</td>
            </tr>
            <tr>
              <td>{t('rates.call')}</td>
              <td>asdfasf</td>
            </tr>
            </tbody>
          </Table>
        */}
      </GridContainer>
      <TabNavHeader>
        <GridContainer>
          <TabNavButtons>
            {tabNavButtons}
          </TabNavButtons>
        </GridContainer>
      </TabNavHeader>
      <GridContainer>
        {tabsContent}

        <Trans
          i18nKey="go_to_my_cards_to_top_up"
          defaults="You can add data and call credit to your cards on the <cardsLink>My Cards</cardsLink> page."
          components={{
            cardsLink: <Link to={routes.linkTo.MY_CARDS}/>
          }}
        />
        <Disclaimer>
          <Trans i18nKey="rates.premium_rates_apply">
            Premium rates apply to some calling destinations. See the full list
            <a target="_blank" rel="noopener noreferrer" href={t('rates.rates_link')}>here</a>.
          </Trans>
        </Disclaimer>
      </GridContainer>

    </div>
  );
};

const TabNavHeader = styled.div`
  background-color: ${props => props.theme.color.neutral0};
  margin-bottom: 14px;
`

const TabNavButtons = styled.div`
  display: flex;
  justify-content: space-between;
`

const TabContent = styled.div`
  display: ${props => props.active ? 'block' : 'none'};
`

const Loader = styled.div`
  position: relative;
  width: 4.375rem;
  margin: 3rem auto;
`

const TableTitle = styled.div`
  font-weight: ${props => props.theme.fontWeight.semiBold};
  font-size: 1rem;
  margin-bottom: 0.625rem;
`

const TabNavButton = styled.button`
  color: currentColor;
  display: block;
  position: relative;
  padding: 13px 8px;
  font-weight: ${props => props.theme.fontWeight.semiBold};
  font-size: 1rem;
  margin: 0 6px;
  border: 0;
  border-radius: 0;
  border-bottom: 2px solid transparent;
  -webkit-appearance: none;
  box-shadow: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  ${props => props.active && `
    color: ${props.theme.navBar.activeColor};
    border-bottom-color: currentColor;
  `}
`

const Disclaimer = styled.div`
  color: ${props => props.theme.color.neutral400};
  margin-top: 1rem;
 
  a {
    font-weight: ${props => props.theme.fontWeight.bold};
    color: ${props => props.theme.color.neutral500};
  }
`

const tableStyles = css`
  width: 100%;
  margin-bottom: 1.5rem;
  border-collapse: collapse;
  
  td {
    padding: 0.25rem 0;
  }
  
  td:last-child {
    text-align: right;
    font-weight: ${props => props.theme.fontWeight.semiBold};
    font-size: ${props => props.theme.fontSize.textBigger};
  }
`

const StyledRatesTable = styled(RatesTable)`
  ${tableStyles}
`

const StyledDataPackagesTable = styled(DataPackagesTable)`
  ${tableStyles}
`

const CountryHeader = styled.div`
  display: flex;
  margin-top: 1.25rem;
  margin-bottom: 1.625rem;
  align-items: center;
`

const CountryName = styled.div`
  padding-left: 0.8125rem;
`

const FlagWrap = styled.div`
  flex-shrink: 0;
`


export default RatesAndCoverageSingle;
