import React from 'react';
import useRoutes from "../routing/useRoutes";
import GridContainer from "../../components/GridContainer/GridContainer";
import MainTitle from "../../components/MainTitle/MainTitle";
import ContentBuilder from "../contentBuilder/ContentBuilder";

const PrivacyPolicy = () => {
  const routes = useRoutes();
  const currentRoute = routes.routesByKey.PRIVACY_POLICY;
  const pageId = currentRoute.customId;

  return (
    <div>
      <GridContainer>
        <MainTitle>{currentRoute.title}</MainTitle>
      </GridContainer>

      <ContentBuilder {...{pageId}}/>
    </div>
  );
};

export default PrivacyPolicy;
