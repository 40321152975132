import React, {useEffect, useRef, useState} from 'react';
import * as serviceWorker from '../../serviceWorker';
import {Snackbar} from '@material-ui/core';
import history from "../routing/history";
import {selectShouldShow, dismiss, accept, maybeShow} from "./pwaReloadSlice";
import {useDispatch, useSelector} from "react-redux";
import Button from "../../components/UI/Button/Button";
import styled from "styled-components/macro";
import {resetButtonStyles} from "../../styles/mixins";
import {useTranslation} from "../i18n/i18n";

const ServiceWorkerWrapper = () => {
  const {t} = useTranslation();
  const waitingWorker = useRef(null);
  // we'll use this to manually cause re renders since the useRef doesnt'
  const [renderTrigger, setRenderTrigger] = useState(null);
  const shouldShowReload = useSelector(selectShouldShow);
  const dispatch = useDispatch();
  // we only want to show the reload snackbar if a waitingWorker actually exists
  const showReload = waitingWorker.current && shouldShowReload;

  useEffect(() => {
    if (navigator?.serviceWorker) {
      // we need to reload when the new service worker is activated to get rid of the stale data
      // note: this will reload all our apps tabs and pages because we're listening to controllerchange in each of them
      // and since the same service worker is shared between all those pages and tabs, all tabs and pages receive
      // the controllerchange event
      navigator.serviceWorker.addEventListener('controllerchange', () => window.location.reload());
    }

    const onUpdateIsWaiting = (registration) => {
      if (registration) {
        dispatch(maybeShow({timestamp: Date.now()}));
        waitingWorker.current = registration.waiting;
        setRenderTrigger(Date.now());
      }
    };

    const onRegistration = (registration) => {

      // try to update service worker on each navigation change similar to how it would work
      // with the native browser navigation. We just have to do it manually because this is an SPA and
      // we don't use the native browser navigation
      // https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration/update
      // https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle#updates
      history.listen(() => {
        if (registration) {
          registration.update();
        }

        if (waitingWorker.current) {
          dispatch(maybeShow({timestamp: Date.now()}));
          setRenderTrigger(Date.now());
        }
      });
    }

    serviceWorker.register({
      onRegistration,
      onUpdateIsWaiting,
    });
    // we only want this to run once since we're adding event listeners on service worker events
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // post the SKIP_WAITING message to the waiting service worker
  // service worker will then skip waiting, which means that it'll become active
  // without page reload. However, we still need to refresh all pages and tabs to get rid of the stale data
  // this will be done on the controllerchange event
  const skipWaiting = () => {
    console.log('waitingWorker: ');
    console.log(waitingWorker);
    if (waitingWorker.current) {
      waitingWorker.current.postMessage({type: 'SKIP_WAITING'});
    }
    dispatch(accept());
  };

  const handleDismiss = () => {
    dispatch(dismiss());
  }


  return (
    <Snackbar
      open={showReload}
      renderTrigger={renderTrigger}
      message={t('new_version_is_available')}
      anchorOrigin={{vertical: 'top', horizontal: 'center'}}
      action={
        <>
          <Button
            onClick={skipWaiting}
            variation="ghost"
            noMargins
            size="sm"
          >
            {t('button.refresh')}
          </Button>
          <TextButton
            onClick={handleDismiss}
          >
            {t('button.postpone')}
          </TextButton>
        </>
      }
    />
  );
};

const TextButton = styled.button`
  ${resetButtonStyles};
  color: inherit;
  margin-left: 1rem;
  padding: 0 8px;
`

export default ServiceWorkerWrapper;
