import './util/imulPolyfill';
import './config';
import React from 'react';
import { hydrate, render } from "react-dom";
import App from './App';

/*
let deferredPrompt;
window.addEventListener('beforeinstallprompt', (e) => {
  // Prevent the mini-infobar from appearing on mobile
  e.preventDefault();
  // Stash the event so it can be triggered later.
  deferredPrompt = e;
  console.log(deferredPrompt);
});
 */


window.loadEventFired = false;
window.addEventListener('load', () => {
  window.loadEventFired = true;
});

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}
