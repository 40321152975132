import React from 'react';
import styled from 'styled-components/macro';

const Card = props => {
  const {labelSlot, titleSlot, actionsSlot} = props;

  return (
    <Wrapper className={props.className}>
      {(labelSlot || titleSlot || actionsSlot) && (
       <Header>
         {labelSlot && (
           <HeaderCol>
             <Label>{labelSlot}</Label>
           </HeaderCol>
         )}
         {titleSlot && (
           <HeaderCol>
             {titleSlot}
           </HeaderCol>
         )}
         {actionsSlot && (
           <HeaderCol last>
             {actionsSlot}
           </HeaderCol>
         )}

       </Header>
      )}

      {props.children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border-radius: ${({theme}) => theme.card.borderRadius};
  background: ${({theme}) => theme.card.bg};
  box-shadow: ${({theme}) => theme.card.shadow};
  padding: 0.625rem 0.9375rem;
  margin-left: -0.3125rem;
  margin-right: -0.3125rem;
  & + & {
    margin-top: ${props => props.theme.spacer};
  }
`

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.4375rem;
`

const Label = styled.div`
  color: ${props => props.theme.textColorMuted};
`

const HeaderCol = styled.div`
  ${props => props.last && `
    margin-left: auto;
    margin-right: 0;
    padding-left: 1rem;
  `}
`

export default Card;
