import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from "prop-types";
import Icon from "../../Icon/Icon";

const ControlLink = props => {
  let icon = null;
  const {type} = props;
  if (type === 'caretLeft') {
    icon = (
      <IconWrap>
        <Icon name="CaretLeft" fullWidth/>
      </IconWrap>
    )
  }
  return (
      <Link href={props.href || '#'} {...props}>
        {icon}
        {props.children}
      </Link>
  );
};

const Link = styled.a`
  font-size: ${props => props.theme.fontSize.text};
  color: ${props => props.theme.color.neutral400};
  display: inline-block;
  vertical-align: top;
  font-weight: ${props => props.theme.fontWeight.normal};
  position: relative;
`

const IconWrap = styled.div`
  position: absolute;
  width: 0.9375rem;
  top: 50%;
  margin-top: -0.46875rem;
  right: calc(100% + 0.25rem);
`

ControlLink.propTypes = {
  type: PropTypes.oneOf(['caretLeft']),
};

export default ControlLink;
