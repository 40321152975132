import React from 'react';
import ChoiceButton from "../UI/ChoiceButton/ChoiceButton";
import {selectCurrency, setCurrency} from "../../features/userSettings/userSettingsSlice";
import {useDispatch, useSelector} from "react-redux";
import {selectCurrencies} from "../../features/appConfig/appConfigSlice";

const CurrencySelector = () => {
  const currencies = useSelector(selectCurrencies);
  const selectedCurrency = useSelector(selectCurrency);
  const dispatch = useDispatch();

  const currencyButtons = currencies.map(currency => {
    const {code, symbol} = currency;

    return (
      <ChoiceButton
        key={code}
        name="currency"
        value={code}
        checked={code === selectedCurrency}
        id={'currency_' + code}
        onChange={e => {
          dispatch(setCurrency({
            code: e.target.value,
          }))
        }}
      >
        {code + ' - ' + symbol}
      </ChoiceButton>
    )
  })

  return (
    <form>
      {currencyButtons}
    </form>
  );
};

export default CurrencySelector;
