import {createSlice} from '@reduxjs/toolkit';

export const flashMessageSlice = createSlice({
  name: 'flashMessage',
  initialState: {
    messages: null,
    shown: false,
    pathShown: null,
    timestamp: null,
  },
  reducers: {
    flashMessagesShown: (state, action) => {
      const timestamp = state.timestamp;
      if (state.shown === timestamp && state.pathShown !== action.payload.path) {
        // now we know that we are on another page and that the message was seen on the last page
        // so, we should remove it.
        state.message = null;
      } else {
        state.pathShown = action.payload.path;
        state.shown = state.timestamp;
      }
    },
    setFlashMessages: (state, action) => {
      if (!action.payload.messages) {
        state.messages = null;
      } else if (state.messages) {
        state.messages = state.messages.concat(action.payload.messages);
      } else {
        state.messages = action.payload.messages;
      }
      state.timestamp = Date.now();
      state.pathShown = null;
    },

    clearFlashMessagesIfSeen: (state) => {
      const timestamp = state.timestamp;
      if (state.shown === timestamp) {
        state.messages = null;
        state.shown = false;
        state.timestamp = null;
      }
    }
  },
});

export const {
  flashMessagesShown,
  setFlashMessages,
  clearFlashMessagesIfSeen,
} = flashMessageSlice.actions;

export const selectFlashMessages = state => state.flashMessage.messages;

export default flashMessageSlice.reducer;
