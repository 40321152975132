import {configureStore, getDefaultMiddleware, combineReducers} from '@reduxjs/toolkit';
import analyticsMiddleware from "../features/analytics/analyticsMiddleware";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist'
import localForage from 'localforage';
import counterReducer from '../features/counter/counterSlice';
import authReducer from '../features/auth/authSlice';
import flashMessageReducer from '../features/flashMessage/flashMessageSlice';
import userSettingsReducer from '../features/userSettings/userSettingsSlice';
import appConfigReducer from '../features/appConfig/appConfigSlice';
import accountCreationReducer from '../features/accountCreation/accountCreationSlice';
import myCardsReducer from '../features/myCards/myCardsSlice';
import onboardingReducer from '../features/onboarding/onboardingSlice';
import countriesReducer from '../features/countries/countriesSlice';
import modalReducer from '../features/modal/modalSlice';
import simConfiguratorReducer from '../features/simConfigurator/simConfiguratorSlice'
import cartReducer from '../features/cart/cartSlice';
import purchaseHistoryReducer from '../features/purchaseHistory/purchaseHistorySlice';
import callLogReducer from '../features/callLog/callLogSlice';
import checkoutReducer from '../features/checkout/checkoutSlice';
import shippingCountriesReducer from '../features/shippingCountries/shippingCountriesSlice';
import regionsReducer from '../features/regions/regionsSlice';
import topDestinationsReducer from '../features/topDestinations/topDestinationsSlice';
import pwaReloadReducer from '../features/pwa/pwaReloadSlice';
import apiManifestReducer from '../features/apiManifest/apiManifestSlice';
import addToHomeScreenReducer from '../features/addToHomeScreen/addToHomeScreenSlice';
import analyticsReducer from '../features/analytics/analyticsSlice';

import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

const persistConfig = {
  key: 'root',
  version: 1,
  storage: localForage,
  whitelist: [
    'auth',
    'userSettings',
    'onboarding',
    'cart',
    'countries',
    'regions',
    'apiManifest',
    'addToHomeScreen',
    'analytics',
  ], // state slices that should be persisted
  stateReconciler: autoMergeLevel2,
}

const reducers = combineReducers({
  accountCreation: accountCreationReducer,
  addToHomeScreen: addToHomeScreenReducer,
  analytics: analyticsReducer,
  apiManifest: apiManifestReducer,
  appConfig: appConfigReducer,
  auth: authReducer,
  callLog: callLogReducer,
  cart: cartReducer,
  checkout: checkoutReducer,
  counter: counterReducer,
  countries: countriesReducer,
  flashMessage: flashMessageReducer,
  modal: modalReducer,
  myCards: myCardsReducer,
  onboarding: onboardingReducer,
  purchaseHistory: purchaseHistoryReducer,
  pwaReload: pwaReloadReducer,
  regions: regionsReducer,
  shippingCountries: shippingCountriesReducer,
  simConfigurator: simConfiguratorReducer,
  topDestinations: topDestinationsReducer,
  userSettings: userSettingsReducer,
})

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    }
  }).concat(analyticsMiddleware),
});

let persistor = persistStore(store);

export {
  store,
  persistor,
}
