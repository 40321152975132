import React from 'react';
import ModalBase from 'react-overlays/Modal'
import styled from 'styled-components/macro';
import {Transition} from 'react-transition-group';
import {resetButtonStyles} from '../../styles/mixins';
import Icon from "../../components/Icon/Icon";

const DIALOG_ANIMATION_DURATION = 300;

const ModalTransition = ({children, ...props}) => {
  // https://github.com/reactjs/react-transition-group/blob/1fd4a65ac45edd2aea3dec18eeb8b9c07c7eb93f/CHANGELOG.md#features
  const nodeRef = React.useRef(null)
  return (
    <Transition {...props} nodeRef={nodeRef} timeout={DIALOG_ANIMATION_DURATION}>
      {(status, innerProps) => (
        React.cloneElement(children, {
          ...innerProps,
          status,
          ref: nodeRef,
        })
      )}
    </Transition>
  )
};


const Modal = props => {
  const {show, onCloseClick, children, onHide} = props;
  const renderBackdrop = (props) => <Backdrop {...props} />;
  const renderDialog = (props) => (
    <ModalDialog {...props}>
      <CloseButton onClick={onCloseClick}>
        <Icon name="Close"/>
      </CloseButton>
      <DialogInner>
        {(
          /*
            inject the inModal prop to all children so they know that they are inside a modal
            useful for when the component should behave differently when inside a modal
           */
          React.cloneElement(children, {
            inModal: true,
          })
        )}
      </DialogInner>
    </ModalDialog>
  )
  return (
    <ModalBase
      show={show}
      onHide={onHide}
      transition={ModalTransition}
      backdropTransition={ModalTransition}
      renderBackdrop={renderBackdrop}
      renderDialog={renderDialog}
      aria-labelledby="modal-label"
    />
  );
};

const Backdrop = styled.div`
  position: fixed;
  z-index: ${props => props.theme.zIndex.modalBackdrop};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  transition: ${DIALOG_ANIMATION_DURATION}ms;
  opacity: ${({status}) => (status === 'entering' || status === 'entered' ? 0.5 : 0)}; 
`;

const ModalDialog = styled.div`
  position: fixed;
  padding-top: 2.875rem;
  top: 1rem;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${props => props.theme.zIndex.modalDialog};
  border-top-left-radius: ${props => props.theme.modalDialog.borderRadius};
  border-top-right-radius: ${props => props.theme.modalDialog.borderRadius};
  background-color: ${props => props.theme.modalDialog.bgColor};
  transition: ${DIALOG_ANIMATION_DURATION}ms;
  opacity: ${({status}) => (status === 'entering' || status === 'entered' ? 1 : 0)}; 
  transform: ${({status}) => (status === 'entering' || status === 'entered' ? 'translateY(0)' : 'translateY(100px)')};
  
  &:focus {
    outline: none;
  }
`;

const CloseButton = styled.button`
  ${resetButtonStyles};
  position: absolute;
  top: 0.5625rem;
  right: 1.0625rem;
  padding: 0.625rem;
  color: ${props => props.theme.color.neutral400};
  cursor: pointer;
  z-index: 100;
`

const DialogInner = styled.div`
  height: 100%;
  overflow: auto;
`

export default Modal;
