import {createSlice} from '@reduxjs/toolkit';
import axios from "axios";
import config from "react-global-configuration";
import {errorHandler} from "../errorHandling/errorHandler";
import apiRequest from "../../apiRequest";
import {setFlashMessages} from "../flashMessage/flashMessageSlice";
import {messageIdsByKey} from "../systemMessages/systemMessagesConf";

const INITIAL_STATE = {
  verify_code: null,
  travelsimNumber: null,
  verified: false,
  currentStep: 1,
  error: null,
  isLoading: false,
  errorShown: null,
  accountCreationSuccessful: false,
}

export const accountCreationSlice = createSlice({
  name: 'accountCreation',
  initialState: INITIAL_STATE,
  reducers: {
    reset: () => {
      return {
        ...INITIAL_STATE
      };
    },
    stepBack: state => {
      const newState = state.currentStep - 1;
      state.currentStep = newState < 1 ? 1 : newState;
    },
    goToStep: (state, action) => {
      state.currentStep = action.payload.step;
    },
    fetchStart: state => {
      state.accountCreationSuccessful = false;
      state.isLoading = true;
      state.error = null;
    },
    fetchFail: (state, action) => {
      state.isLoading = false;
      state.error = {
        ...action.payload.message,
        timestamp: Date.now(),
      };
    },
    fetchReqCodeSuccess: (state, action) => {
      state.isLoading = false;
      state.verified = false;
      state.travelsimNumber = action.payload.number;
      state.currentStep = 2;
    },
    fetchCheckCodeSuccess: (state, action) => {
      state.isLoading = false;
      state.verified = true;
      state.verify_code = action.payload.verify_code;
      state.travelsimNumber = action.payload.number;
      state.currentStep = 3;
      state.error = null;
    },
    fetchCreateAccountSuccess: state => {
      state.isLoading = false;
      state.accountCreationSuccessful = true;
    },
    errorShown: (state) => {
      state.errorShown = state.error ? state.error.timestamp : true;
    },
  },
});

export const {
  stepBack,
  goToStep,
  fetchStart,
  fetchReqCodeSuccess,
  fetchCheckCodeSuccess,
  fetchCreateAccountSuccess,
  fetchFail,
  errorShown,
  reset,
} = accountCreationSlice.actions;

export const fetchReqCode = (number) => dispatch => {
  const formData = new FormData();
  formData.append('number', number);
  dispatch(fetchStart());
  axios({
    method: 'post',
    url: config.get('apiEndpoints.reqVerificationCode'),
    data: formData,
    withCredentials: false,
  }).then(res => {
    dispatch(fetchReqCodeSuccess({
      data: res.data,
      number,
    }));
  }).catch(error => {
    const {message} = errorHandler.handleApiError(error);
    dispatch(fetchFail({message}));
  });
};

export const checkVerificationCode = (verify_code, number) => dispatch => {
  const formData = new FormData();
  formData.append('verify_code', verify_code);
  formData.append('number', number);
  dispatch(fetchStart());
  axios({
    method: 'post',
    url: config.get('apiEndpoints.checkVerificationCode'),
    data: formData,
    withCredentials: false,
  }).then(res => {
    dispatch(fetchCheckCodeSuccess({
      data: res.data,
      number,
      verify_code,
    }));
  }).catch(error => {
    const {message} = errorHandler.handleApiError(error);
    dispatch(fetchFail({message}));
  });
};

export const createAccount = (data) => dispatch => {
  const formData = new FormData();
  Object.keys(data).forEach(key => {
    formData.append(key, data[key]);
  })

  dispatch(fetchStart());
  apiRequest.post({
    endpoint: 'createAccount',
    data: formData,
  }).then(res => {
    dispatch(setFlashMessages({messages: null}));
    dispatch(setFlashMessages({
      messages: [{
        code: messageIdsByKey.sm_account_creation_success,
      }],
    }));
    dispatch(fetchCreateAccountSuccess());
    // set step back to 1, users would otherwise get stuck at step 3 on recurring account creation attempts
    dispatch(goToStep({step: 1}));

  }).catch(error => {
    const {message} = errorHandler.handleApiError(error);
    dispatch(fetchFail({message}));
  });
};

export const selectIsLoading = state => state.accountCreation.isLoading;
export const selectSystemMessage = state => state.accountCreation.systemMessage;
export const selectCurrentStep = state => state.accountCreation.currentStep;
export const selectTravelsimNumber = state => state.accountCreation.travelsimNumber;
export const selectError = state => state.accountCreation.error;
export const selectVerifyCode = state => state.accountCreation.verify_code;
export const selectAccountCreationSuccessful = state => state.accountCreation.accountCreationSuccessful;
export const selectShouldShowError = state => {
  // we should only show the error message if it's newer than the last shown one
  const timestamp = state.accountCreation.error ? state.accountCreation.error.timestamp : null;
  return state.accountCreation.errorShown !== timestamp;
}

export default accountCreationSlice.reducer;
