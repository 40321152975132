import useFetchData from "../../hooks/useFetchData";
import config from "react-global-configuration";
import {useDispatch} from "react-redux";
import {fetchCancel, fetchFail, fetchStart, fetchSuccess} from "./regionsSlice";

// this component fetches countries into our redux store
export default () => {
  const dispatch = useDispatch();
  useFetchData({
    endpoint: config.get().apiEndpoints.regions,
    pollInterval: config.get().regionsPollInterval,
    enabled: true,
  }, {
    start: () => {
      dispatch(fetchStart());
    },
    success: result => {
      dispatch(fetchSuccess(result));
    },
    fail: (error, message) => {
      dispatch(fetchFail({error: message}))
    },
    cancel: () => {
      dispatch(fetchCancel())
    }
  });
};
