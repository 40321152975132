import React from 'react';
import {getProductTypeById, productTypes} from "../../util/productType";
import formatTravelsimNumber from "../../util/formatTravelsimNumber";
import {useTranslation} from "../i18n/i18n";

const CartItemTitleContent = props => {
  const {productId, phoneNumber, render, breakElement = <br/>} = props;
  const {t} = useTranslation();
  let productType;
  if (productId) {
    productType = getProductTypeById(productId);
  }
  let titleContent;
  if (productType === productTypes.newEsim) {
    titleContent = t('order.new_esim_item_title');
  } else if (productType === productTypes.newTravelsim) {
    titleContent = t('order.new_travelsim_item_title');
  } else if (productType === productTypes.rechargeTravelsim || productType === productTypes.rechargeESim) {
    titleContent = (
      <>
        {t('order.recharge_item_title')} {breakElement}
        {formatTravelsimNumber(phoneNumber)}
      </>
    );
  }

  return (
    <>
      {render(titleContent)}
    </>
  );
};

export default CartItemTitleContent;
