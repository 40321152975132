import React from 'react';
import useRoutes from "../routing/useRoutes";
import Button from "../../components/UI/Button/Button";
import {useTranslation} from "../i18n/i18n";

const LinkEsimFaq = (props) => {
  const {t} = useTranslation();
  const routes = useRoutes();
  const {onClick} = props;
  return (
    <Button
      alignLeft
      noMargins
      variation="tert"
      icon="MessageBubbles"
      fullWidth={false}
      to={routes.linkTo.ESIM_FAQ}
      onClick={onClick}
    >
      {t('link_esim_faq')}
    </Button>
  );
};

export default LinkEsimFaq;
