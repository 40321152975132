import React, {Component} from 'react';
import config from 'react-global-configuration';
import * as Sentry from '@sentry/browser';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorInfo: null,
    };
  }

  componentDidCatch(error, errorInfo) {
    const {shouldPurge} = this.props;
    // Catch errors in any components below and re-render with error message

    // purge persisted state to avoid repeating the same errors due to corrupted state
    if (shouldPurge && config.get('purgeStateOnError') && this.props.persistor) {
      setTimeout(() => this.props.persistor.purge(), 200)
    }
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
    // We'll send the error to a error logging service called Sentry
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({eventId});
    });
  }

  render() {
    if (this.state.errorInfo && config.get('showErrors')) {
      // Error path
      return (
        <div>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    if (this.state.errorInfo) {
      return (
        <div>
          <h2>Something went wrong.</h2>
          <div>You can try refreshing the app by clicking this button:</div>
          <button onClick={() => window.location.reload()}>Refresh the page</button>
        </div>
      )
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
