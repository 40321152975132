import React from 'react';
import {addTestItemsToCart} from "../cart/cartSlice";
import {persistor} from "../../app/store";
import {useDispatch} from "react-redux";
import {useTranslation} from "../i18n/i18n";
import config from "react-global-configuration";
import styled from "styled-components/macro";

const DevUtilBar = () => {
  const dispatch = useDispatch();
  const {i18n} = useTranslation();

  if (!config.get().showDevUtils) {
    return null;
  }

  return (
    <Wrapper>
      <button
        onClick={() => dispatch(addTestItemsToCart())}
      >
        Add test items to cart
      </button>
      <button onClick={() => {
        setTimeout(() => persistor.purge(), 200)
      }}>Purge
      </button>
      <button onClick={() => i18n.reloadResources()}>Reload i18n resources</button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
  padding-left: 100px;
  text-align: right;
`

export default DevUtilBar;
