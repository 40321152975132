import React from 'react';
import styled from 'styled-components/macro';
import {ReactComponent as TravelsimLogoSim} from "../../../assets/svg/travelsim-logo-sim.svg";
import {ReactComponent as TravelsimEsimBg} from "../../../assets/svg/travelsim-esim-bg.svg";
import {ReactComponent as TravelsimLogoSimInactive} from "../../../assets/svg/travelsim-logo-sim-inactive.svg";
import config from "react-global-configuration";

const SimCard = props => {
  let CardSvg = TravelsimLogoSim;
  const {type, phoneNumber, className, light, cardName} = props;

  if (type === 'loadingMock') {
    CardSvg = TravelsimLogoSimInactive;
  } else if (type === config.get().simTypes.esim) {
    CardSvg = TravelsimEsimBg;
  }

  return (
    <Wrapper {...{className}}>
      <Card>
        <CardBg>
          <CardSvg/>
        </CardBg>
        <CardNumber light={light} cardHasName={!!cardName}>{phoneNumber}</CardNumber>
        {cardName && (
          <Name>{cardName}</Name>
        )}
      </Card>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  max-width: 16.3125rem;
  margin: 0 auto;
  font-size: 1rem;
`

const Card = styled.div`
  position: relative;
  padding-top: 77%;
`

const CardBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const CardNumber = styled.div`
  font-size: 1.375em;
  color: ${props => props.theme.textColorOnPrimary};
  position: absolute;
  right: 16%;
  top: 27%;
  font-weight: ${props => props.light ? props.theme.fontWeight.normal : props.theme.fontWeight.semiBold};
  ${props => props.cardHasName && `
    top: 21%;
  `}
`

const Name = styled.div`
  font-size: 1em;
  color: ${props => props.theme.textColorOnPrimary};
  position: absolute;
  right: 16%;
  top: 35%;
  font-weight: ${props =>  props.theme.fontWeight.normal};
  text-align: right;
  white-space: nowrap;
  max-width: 11.875em;
  overflow: hidden;
  text-overflow: ellipsis;
`

export default SimCard;
