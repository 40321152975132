import React, {Suspense} from 'react';
// https://stackoverflow.com/a/44163399/4291189
// https://codesandbox.io/s/owQ8Wrk3
// we're using history from a separate import because we wan't to use it outside of the router component too
// e.g. in the ServiceWorkerWrapper
import history from "./history";
import {
  Router,
  Switch,
  Route,
  Redirect,
  matchPath,
} from "react-router-dom";
import Page from "../templating/Page";
import {useSelector} from "react-redux";
import {selectToken} from '../auth/authSlice';
import {
  selectStatus as selectOnboardingStatus,
  STATUS as ONBOARDING_STATUS,
} from "../onboarding/onboardingSlice";
import {LastLocationProvider} from 'react-router-last-location';
import useRoutes from './useRoutes';
import ModalsController from "../modal/ModalsController";
import ScrollToTop from "./ScrollToTop";
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";
import TrackPageview from "../analytics/TrackPageview";


const PrivateRoute = ({component: Component, loginPage, pageData, ...rest}) => {
  const authToken = useSelector(selectToken);

  return (
    <Route {...rest} render={(props) => (
      authToken
        ? <Component {...props} {...{pageData}}/>
        : <div>{loginPage}</div>
    )}/>)
}

function routeFactory(route, loginPageRoute, settings) {

  const {path, customId: key} = route;

  const match = matchPath(settings.redirectAllTo, {path: path});
  let currentPathIsThePathWeRedirectTo = false;
  if (match && match.isExact) {
    currentPathIsThePathWeRedirectTo = true;
  }
  // redirect all except for the page we redirect to
  if (settings.redirectAllTo && !currentPathIsThePathWeRedirectTo) {
    return (
      <Route
        {...{path}}
        {...{key}}
      >
        <Redirect to={settings.redirectAllTo}/>
      </Route>
    )
  } else if (route.private) {
    const loginPage = <Page pageData={loginPageRoute}/>;
    return (
      <PrivateRoute
        {...{path}}
        {...{key}}
        component={Page}
        pageData={route}
        {...{loginPage}}
      />
    )
  } else {
    return (
      <Route
        {...{path}}
        {...{key}}
      >
        <Page pageData={route}/>
      </Route>
    )
  }

}

const Routes = props => {
  const onboardingStatus = useSelector(selectOnboardingStatus);
  const routes = useRoutes();
  const authToken = useSelector(selectToken);

  const loginPageRoute = routes.getRoute(routes.customIds.LOG_IN);
  const homePageLinkTo = routes.linkTo.MY_CARDS;
  let redirectAllTo;
  if (onboardingStatus === ONBOARDING_STATUS.start) {
    redirectAllTo = routes.linkTo.LANG_AND_CURRENCY;
  }

  return (
    <Router history={history}>
      <ScrollToTop/>
      <TrackPageview/>
      <LastLocationProvider>
        <Suspense fallback={<LoadingOverlay variation="solidBody"/>}>
          <Switch>
            {authToken && (
              <Redirect exact from={loginPageRoute.path} to={homePageLinkTo}/>
            )}

            {routes.routes.map(route => routeFactory(route, loginPageRoute, {
              redirectAllTo,
            }))}
            <Route path="/">
              <Redirect to={homePageLinkTo}/>
            </Route>
          </Switch>
        </Suspense>
        <ModalsController/>
      </LastLocationProvider>
    </Router>
  )
};

export default Routes;
