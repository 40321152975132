import {createMiddleware} from 'redux-beacon';
import GoogleTagManager from '@redux-beacon/google-tag-manager';
import {cartSlice} from "../cart/cartSlice";

const gtm = GoogleTagManager();

const getProductTotalPrice = product => {
  const {data_package, airtime, simPrice} = product;
  let price = 0;
  if (simPrice) {
    price += simPrice;
  }

  if (data_package && data_package.item && data_package.item.price) {
    price += data_package.item.price;
  }

  if (airtime && airtime.price) {
    price += airtime.price;
  }

  return price;
}

const getProductName = product => {
  const {data_package, airtime, simType} = product;
  let namePieces = [];
  if (simType) {
    namePieces.push(simType);
  }

  if (data_package && data_package.name) {
    namePieces.push('data_packaga: ' + data_package.name);
  }

  if (airtime && airtime.name) {
    namePieces.push('airtime: ' + airtime.name);
  }

  return namePieces.join(' / ');
}

const formatProductsForGtm = products => products.map(product => ({
  name: getProductName(product),
  currencyCode: product.currency,
  id: product.product_id,
  airtime: product.airtime,
  data_package: product.data_package,
  price: getProductTotalPrice(product),
  quantity: 1
}));

const eventsMap = {
  [cartSlice.actions.addToCart.type]: action => {
    if (!action.payload.items) {
      return;
    }
    return {
      hitType: 'addToCart',
      ecommerce: {
        'add': {
          'products': formatProductsForGtm(action.payload.items),
        }
      }
    }
  },
  [cartSlice.actions.removeItem.type]: (action, prevState) => {
    let items = [];
    if (prevState.cart.items && action.payload.index !== undefined) {
      items.push(prevState.cart.items[action.payload.index]);
    }
    return {
      hitType: 'removeFromCart',
      ecommerce: {
        'remove': {
          'products': formatProductsForGtm(items),
        }
      }
    }
  }
};

export default createMiddleware(eventsMap, gtm);
