import React from 'react';
import {useTranslation} from "../i18n/i18n";
import useFormatPrice, {INPUT_FORMAT} from "../../hooks/useFormatPrice";

const DataPackagesTable = props => {
  const {className, dataPackages} = props;
  const {t} = useTranslation();
  const formattedPrice = useFormatPrice({
    inputFormat: INPUT_FORMAT.float,
  });

  let tableRows = [];
  if (dataPackages && Array.isArray(dataPackages)) {
    dataPackages.forEach(dataPackage => {
      if (dataPackage.items && Array.isArray(dataPackage.items)) {
        dataPackage.items.forEach(item => {
          tableRows.push((<tr>
            <td>{`${item.name} (${t('rates.valid_for_x_days', {x: item.validity})})`}</td>
            <td>{formattedPrice(item.price)}</td>
          </tr>))
        })
      }
    })
  }

  let table;
  if (tableRows.length) {
    table = (
      <table {...{className}}>
        <tbody>
        {tableRows}
        </tbody>
      </table>
    )
  }

  return (
    <>
      {table}
    </>
  );
};

export default DataPackagesTable;
