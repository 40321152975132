import useFetchData from "../../hooks/useFetchData";
import config from "react-global-configuration";
import {useDispatch, useSelector} from "react-redux";
import {
  tokenRefreshStart,
  tokenRefreshSuccess,
  tokenRefreshFail,
  tokenRefreshCancel,
  selectTokenExpiry,
  selectToken
} from "./authSlice";
import {useEffect} from "react";

export default () => {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const tokenExpiry = useSelector(selectTokenExpiry);
  const tokenRefreshTimeWindow = config.get().tokenRefreshTimeWindow;

  const {setConfig: setFetchConfig} = useFetchData({
    endpoint: config.get().apiEndpoints.authTokenRefresh,
    method: 'post',
    useLanguage: false,
    useCurrency: false,
    enabled: false,
    withCredentials: true,
    useAuthToken: false,
    rerenderOnAuthTokenChange: false,
  }, {
    start: () => {
      dispatch(tokenRefreshStart());
    },
    success: result => {
      dispatch(tokenRefreshSuccess(result));
    },
    fail: (error, message) => {
      dispatch(tokenRefreshFail({error: message}))
    },
    cancel: () => {
      dispatch(tokenRefreshCancel())
    }
  });

  useEffect(() => {

    let timeoutId = null;

    const refresh = () => {
      setFetchConfig(prevConfig => ({
        ...prevConfig,
        enabled: true,
      }));
    }

    const clearCurrentTimeout = () => {
      if (timeoutId) {
        window.clearTimeout(timeoutId);
        timeoutId = null;
      }
    }
    if (token && tokenExpiry !== null) {
      const currentTimestamp = Date.now();
      const tokenExpiryMs = tokenExpiry * 1000;

      if (tokenExpiryMs - currentTimestamp < tokenRefreshTimeWindow) {
        refresh();
      } else {
        // refresh tokenRefreshTimeWindow milliseconds before expire
        let timeOutLength = tokenExpiryMs - currentTimestamp - tokenRefreshTimeWindow;
        if (timeOutLength < 0) {
          timeOutLength = 1
        }
        clearCurrentTimeout();
        timeoutId = window.setTimeout(() => {
          refresh();
        }, timeOutLength);
      }
    }

    return () => {
      clearCurrentTimeout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFetchConfig, tokenExpiry, tokenRefreshTimeWindow])

};
