import {useEffect, useRef} from "react";
import {useLocation} from "react-router-dom";
import {useGa} from "./useGa";

export default () => {
  const {pathname} = useLocation();
  const ReactGA = useGa();
  /*
    We don't want to fire pageviews on the first render, because ga fires pageviews atuomatically on page load.
   */
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    console.log('track pageview');

    ReactGA.set({page: window.location.pathname + window.location.search})
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [pathname, ReactGA]);

  return null;
};
