import React from 'react';
import GridContainer from "../../components/GridContainer/GridContainer";
import SystemMessage from "../systemMessages/SystemMessage";
import {useSelector} from "react-redux";
import {selectError, selectShouldShowCountriesError} from "./countriesSlice";
import {useSystemMessages} from "../systemMessages/useSystemMessages";

const CountriesFetchError = () => {
  const countriesError = useSelector(selectError);
  const shouldShowCountriesError = useSelector(selectShouldShowCountriesError);
  const systemMessages = useSystemMessages();

  let message = {
    ...systemMessages.messages.sm_could_not_load_countries,
    details: [countriesError?.text],
  }
  
  return (
    <>
      {shouldShowCountriesError && (
        <GridContainer>
          <SystemMessage
            message={message}
            tryAgain={() => {
              window.location.reload();
            }}
          />
        </GridContainer>
      )}
    </>
  );
};

export default CountriesFetchError;
