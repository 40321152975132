import { createSlice } from '@reduxjs/toolkit';

export const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    active: null,
    data: null,
  },
  reducers: {
    setActive: (state, action) => {
      const {payload} = action;
      state.active = payload.modal;
      if (payload.data) {
        state.data = payload.data;
      }
    },
    setData: (state, action) => {
      state.data = action.payload.data;
    }
  },
});

export const { setActive } = modalSlice.actions;
export const { setData } = modalSlice.actions;

export const selectActive = state => state.modal.active;
export const selectData = state => state.modal.data;

export default modalSlice.reducer;
