import {createSlice} from '@reduxjs/toolkit';
import {createSelector} from 'reselect'
import config from 'react-global-configuration';

export const addToHomeScreenSlice = createSlice({
  name: 'addToHomeScreen',
  initialState: {
    iOSDismissedAt: 0,
    iOSShowRequestedAt: 0,
  },
  reducers: {
    iOSDismiss: state => {
      state.iOSDismissedAt = Date.now();
    },
    iOSMaybeShow: (state) => {
      state.iOSShowRequestedAt = Date.now();
    }
  },
});

export const {iOSDismiss, iOSMaybeShow} = addToHomeScreenSlice.actions;
export const selectIOSDismissedAt = state => state.addToHomeScreen.iOSDismissedAt;
export const selectIOSShowRequestedAt = state => state.addToHomeScreen.iOSShowRequestedAt;

export const selectIOSShouldShow = createSelector(
  [selectIOSShowRequestedAt, selectIOSDismissedAt],
  (requestedAt, dismissedAt) => {
    let shouldShow = false;
    if (requestedAt > dismissedAt + config.get().iOSAddToHomeScreenSnoozeTime) {
      shouldShow = true;
    }
    return shouldShow;
  }
);

export default addToHomeScreenSlice.reducer;
