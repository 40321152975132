import React from 'react';
import LoadingScreen from "./components/LoadingScreen/LoadingScreen";
import {SystemMessagesProvider} from "./features/systemMessages/useSystemMessages";
import systemMessagesConfig from "./features/systemMessages/systemMessagesConf";
import LoadingOverlay from "./components/LoadingOverlay/LoadingOverlay";
import {ProvideRoutes} from "./features/routing/useRoutes";
import Routes from "./features/routing/Routes";
import {useTranslation} from "./features/i18n/i18n";
import axios from "axios";
import config from "react-global-configuration";
import useFetchCountries from "./features/countries/useFetchCountries";
import useFetchRegions from "./features/regions/useFetchRegions";
import {useSelector} from "react-redux";
import {selectIsLoading} from "./features/auth/authSlice";
import useFetchApiManifest from "./features/apiManifest/useFetchApiManifest";
import useI18nUpdater from "./features/i18n/useI18nUpdater";
import useTokenRefresher from "./features/auth/useTokenRefresher";
import IOSAddToHomeScreen from "./features/addToHomeScreen/IOSAddToHomeScreen";
import Olark from "./features/olark/Olark";
import {GaProvider} from "./features/analytics/useGa";

const AppInner = () => {
  const {i18n} = useTranslation();
  axios.defaults.baseURL = config.get('apiUrl');
  axios.defaults.headers.common['Accept-Language'] = i18n.language;
  // refresh auth token when required
  useTokenRefresher();
  // fetch api manifest into the redux store
  useFetchApiManifest();
  // fetch countries into the redux store
  useFetchCountries();
  // fetch regions into the redux store
  useFetchRegions();
  // update i18n resources when translations have been updated
  useI18nUpdater();
  const authIsLoading = useSelector(selectIsLoading);
  return (
    <React.Suspense fallback={<LoadingScreen/>}>
      <SystemMessagesProvider systemMessagesConfig={systemMessagesConfig}>
        <GaProvider>
          {authIsLoading && <LoadingOverlay/>}
          <IOSAddToHomeScreen/>
          <ProvideRoutes>
            <Routes/>
          </ProvideRoutes>
          <Olark/>
        </GaProvider>
      </SystemMessagesProvider>
    </React.Suspense>
  );
};

export default AppInner;
